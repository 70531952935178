import React, { useState, useMemo, useEffect } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import axios from "axios";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "./Grouptags.css";
import Select from "react-select";
import { MdModeEditOutline } from "react-icons/md";
import { AiFillDelete } from "react-icons/ai";
import { AiFillEye } from "react-icons/ai";
import Footer from "../Footer/footer";
import Sidebar from "../Sidebar/sidebar";
import Header from "../Header/header";
import Updategroup from "./UpdateGrouptags";
import Modal_Popup from "../../components/modal_renderer/Modal_Popup";

function Grouptags() {
  const [UpdateGroupData, setUpdateGroupData] = useState();
  const [DeleteGroupId, setDeleteGroupId] = useState("");
  const [GroupView, setGroupView] = useState([]);
  const [groupName, setGroups] = useState("");
  const [rowData, setRowData] = useState([]);
  const [options, setoptions] = useState([]);
  const [selectedOptionone, setselectedOptionone] = useState([]);
  const rowHeight = 55;

  const ChildMessageRenderer = (props) => {
    return (
      <div className="iconActionList">
        <div
          className="editIcon"
          onClick={() => {
            setUpdateGroupData(props.data);
          }}
          data-bs-toggle="modal"
          data-bs-target="#UpdateGroupData"
        >
          <MdModeEditOutline className="ActionIcon" />
        </div>
        <div
          className="ViewIcon"
          onClick={() => {
            setGroupView(props.data);
          }}
          data-bs-toggle="modal"
          data-bs-target="#DetailGroup"
        >
          <AiFillEye className="ActionIcon" />
        </div>
        <div
          className="DeleteIcon"
          onClick={() => {
            setDeleteGroupId(props.data._id);
          }}
          data-bs-toggle="modal"
          data-bs-target="#DeleteGroupData"
        >
          <AiFillDelete className="ActionIcon" />
        </div>
      </div>
    );
  };

  useEffect(() => {
    getnewtag();
    getgrouptags();
  }, []);

  //--- Add grouptag API---//
  const resetForm = () => {
    setGroups("");
    setselectedOptionone([]);
    setoptions([]);
  };

  function addgrouptag() {
    let data = JSON.stringify({
      groupName: groupName,
      tags: selectedOptionone.toString(),
    });
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/addgrouptag`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        resetForm();
        getgrouptags();
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  function changeGroupTagStatus(tagId) {
    let data = JSON.stringify({
      tagId: tagId,
    });

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/changetagsgrouptatus`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        getgrouptags();
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  function getgrouptags() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getgrouptags`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        let group = [];
        const groupdata = response.data.taggroupData;
        groupdata.map((groupCat, index) => {
          group.push({ grpId: groupCat._id, groupyName: groupCat.groupName });
        });
        setRowData(response.data.taggroupData);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  // ---- Get tag API ---//

  function getnewtag() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/gettag`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        response.data.tagData.forEach((tag, index) => {
          options.push({ value: tag._id, label: tag.tags });
        });
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  const handlechangetag = (event) => {
    let array = [];
    event.forEach((value) => {
      array.push(value.value);
    });
    setselectedOptionone(array);
  };

  const TagsRenderer = (props) => {
    const Tags = [];
    props.data?.tags?.map((item) => {
      Tags.push(item.tags);
    });
    return <span>{Tags.join(",")}</span>;
  };

  let viewTags = GroupView?.tags?.map((tag) => {
    return tag?.tags.trim();
  });

  function deleteGroup(index) {
    let data = JSON.stringify({
      grouptagId: index,
    });
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/deletegrouptags`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        getgrouptags();
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  const SrNoRenderer = (props) => {
    return <span className="profle_img_box">{props.rowIndex + 1}</span>;
  };

  const GroupRenderer = (props) => {
    return (
      <span className="profle_img_box">
        <span>{props.data.groupName}</span>
      </span>
    );
  };

  const StatusRenderer = (props) => {
    let status = props?.data?.status ? "deactivate" : "activate";
    let message = "Are you sure you want to " + status + " this tag-group?";
    return (
      <Modal_Popup
        status={props?.data?.status}
        onClick={() => {
          changeGroupTagStatus(props?.data?._id);
        }}
        message={message}
      />
    );
  };

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      sortable: true,
      resizable: true,
      filter: true,
      getQuickFilterText: (params) => {
        return params.value.name;
      },
      editable: true,
    };
  }, []);

  const editdataReloadFunc = () => {
    getgrouptags();
  };

  const tagValueGetter = (params) => {
    return params.data?.tags?.map((item) => {
      return item.tags;
    });
  };

  const customLowerCaseComparator = (valueA, valueB) => {
    if (typeof valueA === "string") {
      return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
    }
    return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="container-fluid ">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">
                Tag-Group
                <div className="float-end btns_head">
                  <button
                    className="btn btn-theme btn-sm"
                    data-bs-toggle="modal"
                    data-bs-target="#createGroup"
                  >
                    Add New Tag-Group
                  </button>
                </div>
              </h4>
              <div>
                <div
                  className="modal fade"
                  id="createGroup"
                  tabIndex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                          Add Tag-Group
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          onClick={resetForm}
                        ></button>
                      </div>
                      <div className="modal-body">
                        <div className="row">
                          <div className="col-md-12 mb-3">
                            <label className="form-label"> Tag-Group </label>
                            <div className="position-relative">
                              <input
                                type="text"
                                value={groupName}
                                className="form-control"
                                onChange={(e) => {
                                  setGroups(e.target.value);
                                }}
                              />
                              <div
                                className="hint_box"
                                style={{ display: "block" }}
                              ></div>
                            </div>
                          </div>
                          <div className="col-md-12 mb-3">
                            <label className="form-label">Select Tags</label>
                            <Select
                              isMulti
                              options={options}
                              onChange={(e) => handlechangetag(e)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-danger CancelBtn"
                          data-bs-dismiss="modal"
                          onClick={resetForm}
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          onClick={addgrouptag}
                          className="btn submitBtn"
                          data-bs-dismiss="modal"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="modal fade"
                id="UpdateCategoriesData"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              ></div>
              <div
                className="modal fade DeletePopup"
                id="DeleteGroupData"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog  modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="">
                            <p>
                              Are you sure you want to delete this Tag-Group
                            </p>
                            <button
                              type="button"
                              className="btn btn-danger CancelBtn"
                              data-bs-dismiss="modal"
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              onClick={() => {
                                deleteGroup(DeleteGroupId);
                              }}
                              className="btn submitBtn"
                              data-bs-dismiss="modal"
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="modal fade"
                id="UpdateGroupData"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-scrollable">
                  <div className="modal-content">
                    <Updategroup
                      updatedData={UpdateGroupData}
                      onEditDataFunction={editdataReloadFunc}
                    />
                  </div>
                </div>
              </div>

              <div
                className="modal fade"
                id="DetailGroup"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-scrollable modal-lg">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">
                        Group Tag Details
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-12">
                          <div className="card">
                            <div className="card-body border">
                              <div className="profile_box">
                                <div className="profile_box_body">
                                  <tr>
                                    <th>Group Tag :</th>
                                    <td> {GroupView.groupName}</td>
                                  </tr>
                                  <tr>
                                    <th>Tags :</th>
                                    <td>{viewTags?.join(", ")}</td>
                                  </tr>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-danger CancelBtn"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{ height: 440, width: "100%" }}
                className="ag-theme-alpine tableFix"
              >
                <AgGridReact
                  rowHeight={rowHeight}
                  rowData={rowData}
                  defaultColDef={defaultColDef}
                  frameworkComponents={{
                    childMessageRenderer: ChildMessageRenderer,
                    groupRenderer: GroupRenderer,
                    tagsRenderer: TagsRenderer,
                    statusRenderer: StatusRenderer,
                    srNoRenderer: SrNoRenderer,
                  }}
                >
                  <AgGridColumn
                    width={90}
                    field="SrNo"
                    Srno={true}
                    sortable={false}
                    filter={false}
                    cellRenderer="srNoRenderer"
                    pinned="left"
                  ></AgGridColumn>

                  <AgGridColumn
                    width={120}
                    field="Action"
                    cellRenderer="childMessageRenderer"
                    colId="params"
                    pinned="left"
                    sortable={true}
                    filter={true}
                  ></AgGridColumn>

                  <AgGridColumn
                    width={120}
                    cellRenderer="groupRenderer"
                    headerName="Tag-Group"
                    field="groupName"
                    sortable={true}
                    comparator={customLowerCaseComparator}
                    filter={true}
                    pinned="left"
                  ></AgGridColumn>

                  <AgGridColumn
                    width={300}
                    field="tags"
                    cellRenderer="tagsRenderer"
                    colId="params"
                    valueGetter={tagValueGetter}
                    sortable={true}
                    comparator={customLowerCaseComparator}
                    filter={true}
                  ></AgGridColumn>

                  <AgGridColumn
                    width={300}
                    field="status"
                    cellRenderer="statusRenderer"
                    colId="params"
                    sortable={true}
                    comparator={customLowerCaseComparator}
                    filter={true}
                  ></AgGridColumn>
                </AgGridReact>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Grouptags;
