import { useState, useMemo, useEffect } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import axios from "axios";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "./categories.css";
import Updatecategories from "./Updatecategories";
import { MdModeEditOutline } from "react-icons/md";
import { AiFillDelete } from "react-icons/ai";
import Footer from "../Footer/footer";
import Sidebar from "../Sidebar/sidebar";
import Header from "../Header/header";
import { useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";

/**
 *
 * Category is replaced with Subject Group [Category => Subject_Group]
 */

const CategoryNameRenderer = (props) => {
  return (
    <span className="profle_img_box">
      <span>{props.data.category}</span>
    </span>
  );
};

const IconImageRenderer = (props, ref) => {
  return (
    <span className="profle_img_box">
      <img className="profile_img_table" src={props.data.image} alt="icon" />
    </span>
  );
};

const SrNoRenderer = (props, ref) => {
  return <span className="profle_img_box">{props.rowIndex + 1}</span>;
};

const StatusRenderer = (props) => {
  return (
    <span className="status">
      <label className="switch">
        <input className="switch-input" type="checkbox" />
        <span
          className="switch-label"
          data-on="Active"
          data-off="Deactive"
        ></span>
        <span className="switch-handle"></span>
      </label>
    </span>
  );
};

function Categories() {
  const [UpdateCategoriesData, setUpdateCategoriesData] = useState();
  const [DeleteCategoriesId, setDeleteCategoriesId] = useState("");
  const [rowData, setRowData] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [isOpen, setIsOpen] = useState(false);

  function somefun() {
    setIsOpen(false);
    cancelForm();
  }
  function fun() {
    setIsOpen(false);
    cross();
  }
  function cancelForm() {
    reset();
  }
  function cross() {
    reset();
  }
  // {
  //   validationSchema : Yup.object({
  //     category : Yup.string().
  //   })
  // });

  // const [DetailCategoriesId, setDetailCategoriesId] = useState([]);
  // const [imageCategoriesId, setImageCategoriesId] = useState([]);

  const ChildMessageRenderer = (props) => {
    return (
      <div
        className="iconActionList"
        onClick={() => {
          setUpdateCategoriesData(props.data);
        }}
        data-bs-toggle="modal"
        data-bs-target="#UpdateCategoriesData"
      >
        <div className="editIcon">
          <MdModeEditOutline className="ActionIcon" />
        </div>
        <div
          className="DeleteIcon"
          onClick={() => {
            setDeleteCategoriesId(props.data._id);
          }}
          data-bs-toggle="modal"
          data-bs-target="#DeleteCategoriesData"
        >
          <AiFillDelete
            className="ActionIcon"
            alt="delete"
            src="../../images/delete.jpg"
          />
        </div>
      </div>
    );
  };

  const pagination = true;
  const paginationPageSize = 100;
  const rowHeight = 55;
  const [category, setCategory] = useState("");

  // --Add category API--//

  function addnew(postData) {
    setIsOpen(false);
    let data = new FormData();
    data.append("category", postData.category);
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/createCategory`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        getCategory();
        reset();
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  const resetForm = () => {
    setCategory("");
  };
  // --get category API--//

  useEffect(() => {
    getCategory();
  }, []);

  function getCategory() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getCategory`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        setRowData(response.data.CategoryData);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  // --delete category API--//

  function deleteData(index, e) {
    // e.preventDefault();
    let data = JSON.stringify({
      categoryId: index,
    });
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/deleteCategory`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        // window.location.reload(false);
        getCategory();
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      sortable: true,
      resizable: true,
      filter: true,
      getQuickFilterText: (params) => {
        return params.value.name;
      },
      editable: true,
    };
  }, []);

  const editdataReloadFunc = () => {
    getCategory();
  };

  const customLowerCaseComparator = (valueA, valueB) => {
    if (typeof valueA === "string") {
      return valueA?.toLowerCase().localeCompare(valueB?.toLowerCase());
    }

    return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
  };
  return (
    <>
      <div className="page-wrapper">
        <div className="container-fluid ">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">
                Subject Group
                <div className="float-end btns_head">
                  <button
                    className="btn btn-theme btn-sm"
                    // data-bs-toggle="modal"
                    // data-bs-target="#createGroup"
                    onClick={() => setIsOpen(true)}
                  >
                    Add Subject Group
                  </button>
                </div>
              </h4>

              <div>
                <Modal show={isOpen} onHide>
                  <Modal.Body>
                    <div
                      //className="modal fade"
                      // id="createGroup"
                      tabIndex="-1"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-dialog-scrollable">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                              Add Subject Group
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                              onClick={() => fun()}
                            ></button>
                          </div>
                          <form
                            onSubmit={handleSubmit(addnew)}
                            autoComplete="new-password"
                          >
                            <div className="modal-body">
                              <div className="row">
                                <div className="col-md-12 mb-3">
                                  <label className="form-label">
                                    {" "}
                                    Add Subject Group
                                  </label>
                                  <div className="position-relative">
                                    {/* <input
                                  type="text"
                                  value={category}
                                  className="form-control"
                                  onChange={(e) => {
                                    setCategory(
                                      e.target.value.replace(/[^A-Za-z ]/ig, "")
                                    );
                                  }}
                                /> */}
                                    <input
                                      className="form-control"
                                      {...register("category", {
                                        required: true,
                                      })}
                                    />

                                    {errors?.category?.type === "required" && (
                                      <p>This field is required</p>
                                    )}

                                    <div
                                      className="hint_box"
                                      style={{ display: "block" }}
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="modal-footer">
                              <button
                                onClick={() => somefun()}
                                type="button"
                                className="btn btn-danger CancelBtn"
                                data-bs-dismiss="modal"
                              >
                                Cancel
                              </button>
                              <button type="submit" className="btn submitBtn">
                                Submit
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
              </div>
              <div
                className="modal fade"
                id="UpdateCategoriesData"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <Updatecategories
                  updatedData={UpdateCategoriesData}
                  onEditDataFunction={editdataReloadFunc}
                />
              </div>

              <div
                className="modal fade DeletePopup"
                id="DeleteCategoriesData"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="">
                            <p>
                              Are you sure you want to delete this Subject
                              Group?
                            </p>
                            <button
                              type="button"
                              className="btn btn-danger CancelBtn"
                              data-bs-dismiss="modal"
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              onClick={() => {
                                deleteData(DeleteCategoriesId);
                              }}
                              className="btn submitBtn"
                              data-bs-dismiss="modal"
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                style={{ width: "100%" }}
                className="ag-theme-alpine tableFix"
              >
                <AgGridReact
                  rowHeight={rowHeight}
                  defaultColDef={defaultColDef}
                  rowData={rowData}
                  frameworkComponents={{
                    childMessageRenderer: ChildMessageRenderer,
                    categoryNameRenderer: CategoryNameRenderer,
                    srNoRenderer: SrNoRenderer,
                    statusRenderer: StatusRenderer,
                  }}
                >
                  <AgGridColumn
                    width={90}
                    field="SrNo"
                    Srno={true}
                    sortable={false}
                    filter={false}
                    cellRenderer="srNoRenderer"
                  ></AgGridColumn>
                  <AgGridColumn
                    width={300}
                    field="Action"
                    cellRenderer="childMessageRenderer"
                    colId="params"
                    sortable={false}
                    filter={false}
                  ></AgGridColumn>
                  <AgGridColumn
                    width={200}
                    cellRenderer="categoryNameRenderer"
                    headerName="Subject"
                    field="category"
                    sortable={true}
                    comparator={customLowerCaseComparator}
                    filter={true}
                  ></AgGridColumn>
                  <AgGridColumn
                    width={160}
                    field="Status"
                    cellRenderer="statusRenderer"
                    colId="params"
                    sortable={true}
                    comparator={customLowerCaseComparator}
                    filter={false}
                  ></AgGridColumn>
                </AgGridReact>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Categories;
