import React, { useState, useEffect, useMemo } from "react";
import Header from "../Header/header";
import Sidebar from "../Sidebar/sidebar";
import Footer from "../Footer/footer";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "../Students/Students.css";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import { MdModeEditOutline } from "react-icons/md";
import axios from "axios";
import Updateimage from "./Updateimage";
import Modal_Popup from "../../components/modal_renderer/Modal_Popup";

function Image() {
  const [IndexValue, setIndexValue] = useState(0);
  const rowHeight = 55;
  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      getQuickFilterText: (params) => {
        return params.value.name;
      },
      editable: true,
      filter: true,
      sortable: true,
    };
  }, []);
  const [bannerdata, setbannerdata] = useState("");
  const [updateData, setUpdateData] = useState([]);

  const SrNoRenderer = (props) => {
    return <span>{props.rowIndex + 1}</span>;
  };

  const PageRenderer = (props) => {
    return <span>{props.data.page}</span>;
  };

  const SectionRenderer = (props) => {
    return <span>{props.data.section}</span>;
  };

  const ImageboxRenderer = (props) => {
    return (
      <span>
        {" "}
        <img className="profile_img_table" src={props.data.image} alt="icon" />
      </span>
    );
  };

  const ApplicationRenderer = (props) => {
    return <span>{props.data.application}</span>;
  };

  const ObjectTypeRenderer = (props) => {
    return <span>{props.data.objtype}</span>;
  };

  const ObjectRenderer = (props) => {
    if (props.data?.Obj?.title) {
      return <span>{props.data?.Obj?.title}</span>;
    } else if (props.data?.Obj?.businessName) {
      return <span>{props.data?.Obj?.businessName}</span>;
    } else if (props.data?.Obj?.groups) {
      return <span>{props.data?.Obj?.groups}</span>;
    } else {
      return <></>;
    }
  };

  const StatusRenderer = (props) => {
    let status = props?.data?.status ? "deactivate" : "activate";
    let message = "Are you sure you want to " + status + " this ?";
    return (
      <Modal_Popup
        status={props?.data?.status}
        onClick={() => {
          homepagestatus(props?.data?.bannerdataId);
        }}
        message={message}
      />
    );
  };
  const ChildMessageRenderer = (props) => {
    return (
      <div className="iconActionList">
        <div
          className="editIcon"
          onClick={() => {
            setUpdateData(props.data);
            setIndexValue(props.rowIndex);
          }}
          data-bs-toggle="modal"
          data-bs-target="#Updateinfo"
        >
          <MdModeEditOutline
            className="ActionIcon "
            onClick={() => {
              setUpdateData(props.data);
              setIndexValue(props.rowIndex);
            }}
            data-bs-toggle="modal"
            data-bs-target="#Updateinfo"
          />
        </div>
      </div>
    );
  };
  useEffect(() => {
    homepagebanner();
  }, []);

  function homepagestatus(homeid) {
    let data = JSON.stringify({
      HomepageBannerId: homeid,
    });

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/changehomepagebannerstatus`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        homepagebanner();
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  function homepagebanner() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getHomepagebanner`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        let bannerData = [];
        response.data?.result?.map((resultdata, index) => {
          bannerData.push({
            page: resultdata.page,
            section: resultdata.section,
            image: resultdata.image,
            application: resultdata.type,
            bannerdataId: resultdata._id,
            objtype: resultdata.objtype,
            Obj: resultdata.Obj,
            status: resultdata.status,
          });
        });
        setbannerdata(bannerData);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const ObjectValueGetter = (params) => {
    if (params.data?.Obj?.title) {
      return params.data?.Obj?.title;
    } else if (params.data?.Obj?.businessName) {
      return params.data?.Obj?.businessName;
    } else if (params.data?.Obj?.groups) {
      return params.data?.Obj?.groups;
    } else {
      return "";
    }
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Image</h4>
              <div
                className="modal fade"
                id="Updateinfo"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <Updateimage
                    updatedData={updateData}
                    homepagebanner={homepagebanner}
                    rowIndex={IndexValue}
                  />
                </div>
              </div>
              <div
                className="modal fade DeletePopup"
                id="BusinessDeleteId"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              ></div>

              <div
                style={{ width: "100%" }}
                className="ag-theme-alpine tableFix"
              >
                <AgGridReact
                  rowHeight={rowHeight}
                  rowData={bannerdata}
                  defaultColDef={defaultColDef}
                  frameworkComponents={{
                    srNoRenderer: SrNoRenderer,
                    pageRenderer: PageRenderer,
                    sectionRenderer: SectionRenderer,
                    imageboxRenderer: ImageboxRenderer,
                    childMessageRenderer: ChildMessageRenderer,
                    applicationRenderer: ApplicationRenderer,
                    objecttypeRenderer: ObjectTypeRenderer,
                    objectRenderer: ObjectRenderer,
                    statusRenderer: StatusRenderer,
                  }}
                >
                  <AgGridColumn
                    width={100}
                    field="SrNo"
                    cellRenderer="srNoRenderer"
                    sortable={false}
                    filter={false}
                    pinned="left"
                  ></AgGridColumn>

                  <AgGridColumn
                    width={150}
                    field="Actions"
                    cellRenderer="childMessageRenderer"
                    sortable={true}
                    filter={true}
                    pinned="left"
                  ></AgGridColumn>

                  <AgGridColumn
                    width={150}
                    field="page"
                    cellRenderer="pageRenderer"
                    sortable={true}
                    filter={true}
                    pinned="left"
                  ></AgGridColumn>

                  <AgGridColumn
                    width={150}
                    field="section"
                    cellRenderer="sectionRenderer"
                    sortable={true}
                    filter={true}
                    pinned="left"
                  ></AgGridColumn>

                  <AgGridColumn
                    width={180}
                    field="Imagebox"
                    cellRenderer="imageboxRenderer"
                    sortable={true}
                    filter={true}
                  ></AgGridColumn>
                  <AgGridColumn
                    width={200}
                    field="application"
                    headerName="Application"
                    cellRenderer="applicationRenderer"
                    sortable={true}
                    filter={true}
                  ></AgGridColumn>

                  <AgGridColumn
                    width={200}
                    headerName="Object Type"
                    field="objtype"
                    cellRenderer="objecttypeRenderer"
                    sortable={true}
                    filter={true}
                  ></AgGridColumn>
                  <AgGridColumn
                    width={200}
                    field="Object"
                    cellRenderer="objectRenderer"
                    sortable={true}
                    valueGetter={ObjectValueGetter}
                    filter={true}
                  ></AgGridColumn>
                  <AgGridColumn
                    cellRenderer="statusRenderer"
                    width={90}
                    field="status"
                    sortable={true}
                    filter={true}
                  ></AgGridColumn>
                </AgGridReact>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Image;
