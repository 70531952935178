import React, { useState, useMemo, useEffect, useRef } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import Footer from "../Footer/footer";
import Sidebar from "../Sidebar/sidebar";
import Header from "../Header/header";
import axios from "axios";
import Modal_Popup from "../../components/modal_renderer/Modal_Popup";
function Product() {
  const rowHeight = 55;
  const [productData, setproductData] = useState("");
  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      resizable: true,
      filter: true,
      getQuickFilterText: (params) => {
        return params.value.name;
      },
      editable: true,
    };
  }, []);

  const SrNoRenderer = (props) => {
    return <span>{props.rowIndex + 1}</span>;
  };

  const ProductRenderer = (props) => {
    return <span>{props.data.product}</span>;
  };
  const CategoryRenderer = (props) => {
    return <span>{props.data.category}</span>;
  };

  const GroupRenderer = (props) => {
    return <span>{props.data.group}</span>;
  };
  const DateRenderer = (props) => {
    return <span>{props.data.date.split("T")[0]}</span>;
  };

  const StatusRenderer = (props) => {
    let status = props?.data?.ProductData?.status ? "deactivate" : "activate";
    let message = "Are you sure you want to " + status + "?";
    return (
      <Modal_Popup
        status={props?.data?.ProductData?.status}
        onClick={() => {
          changestatus(props?.data?.productId);
        }}
        message={message}
      />
    );
  };

  const ConditionRenderer = (props) => {
    return <span>{props.data.condition}</span>;
  };

  const PriceRenderer = (props) => {
    return <span>{props.data.price}</span>;
  };

  const AddressRenderer = (props) => {
    return (
      <span className="profle_img_box">
        <span>{props.data.address}</span>
      </span>
    );
  };

  const DescriptionRenderer = (props) => {
    return <span>{props.data.description}</span>;
  };

  const CreatedbyRenderer = (props) => {
    return <span>{props.data.createdby}</span>;
  };
  const ImageRenderer = (props) => {
    return (
      <span className="profle_img_box">
        <img
          className="profile_img_table"
          src={props.data.productImage[0]}
          alt="renderImages"
        />
      </span>
    );
  };

  const productValueGetter = (params) => {
    return params.data.product;
  };
  const groupValueGetter = (params) => {
    return params.data.group;
  };
  const dateValueGetter = (params) => {
    return params.data.date;
  };
  const conditionValueGetter = (params) => {
    return params.data.condition;
  };
  const priceValueGetter = (params) => {
    return params.data.price;
  };
  const addressValueGetter = (params) => {
    return params.data.address;
  };
  const descriptionValueGetter = (params) => {
    return params.data.description;
  };
  const createdbyValueGetter = (params) => {
    return params.data.createdby;
  };
  const categoryValueGetter = (params) => {
    return params.data.category;
  };
  const statusValueGetter = (params) => {
    return params?.data?.ProductData?.status;
  };
  function getproduct() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getproducts`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        let productsData = [];
        response.data?.productsData?.map((pdata, index) => {
          productsData.push({
            product: pdata.title,
            condition: pdata.condition,
            price: pdata.price,
            date: pdata.createdAt,
            description: pdata.description,
            address: JSON.stringify(pdata.location.address1),
            createdby: pdata.userId?.name,
            ProductData: pdata,
            productId: pdata?._id,
            category: pdata.categoryId
              .map((item) => {
                return item.categoryName;
              })
              .join(","),
            group: pdata.groupId
              .map((item) => {
                return item.groups;
              })
              .join(","),
            productImage: pdata.image,
          });
        });
        setproductData(productsData);
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  useEffect(() => {
    getproduct();
  }, []);

  function changestatus(productId) {
    let data = JSON.stringify({
      productsId: productId,
    });

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/changestatus`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        getproduct();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  return (
    <>
      <div className="page-wrapper">
        <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Products</h4>
              <div
                style={{ width: "100%" }}
                className="ag-theme-alpine tableFix"
              >
                <AgGridReact
                  rowHeight={rowHeight}
                  rowData={productData}
                  defaultColDef={defaultColDef}
                  frameworkComponents={{
                    srNoRenderer: SrNoRenderer,
                    productRenderer: ProductRenderer,
                    categoryRenderer: CategoryRenderer,
                    groupRenderer: GroupRenderer,
                    dateRenderer: DateRenderer,
                    statusRenderer: StatusRenderer,
                    conditionRenderer: ConditionRenderer,
                    priceRenderer: PriceRenderer,
                    addressRenderer: AddressRenderer,
                    descriptionRenderer: DescriptionRenderer,
                    imageRenderer: ImageRenderer,
                    createdbyRenderer: CreatedbyRenderer,
                  }}
                >
                  <AgGridColumn
                    width={100}
                    field="SrNo"
                    cellRenderer="srNoRenderer"
                    sortable={true}
                    filter={true}
                    pinned="left"
                  ></AgGridColumn>
                  <AgGridColumn
                    width={100}
                    field="product"
                    cellRenderer="productRenderer"
                    sortable={true}
                    filter={true}
                    valueGetter={productValueGetter}
                    pinned="left"
                  ></AgGridColumn>
                  <AgGridColumn
                    width={100}
                    field="category"
                    cellRenderer="categoryRenderer"
                    sortable={true}
                    filter={true}
                    valueGetter={categoryValueGetter}
                    pinned="left"
                  ></AgGridColumn>
                  <AgGridColumn
                    width={100}
                    field="group"
                    cellRenderer="groupRenderer"
                    sortable={true}
                    filter={true}
                    valueGetter={groupValueGetter}
                    pinned="left"
                  ></AgGridColumn>
                  <AgGridColumn
                    width={100}
                    field="createddate"
                    cellRenderer="dateRenderer"
                    sortable={true}
                    filter={true}
                    valueGetter={dateValueGetter}
                  ></AgGridColumn>
                  <AgGridColumn
                    width={100}
                    field="status"
                    cellRenderer="statusRenderer"
                    sortable={true}
                    filter={true}
                    valueGetter={statusValueGetter}
                  ></AgGridColumn>
                  <AgGridColumn
                    width={100}
                    field="condition"
                    cellRenderer="conditionRenderer"
                    sortable={true}
                    filter={true}
                    valueGetter={conditionValueGetter}
                  ></AgGridColumn>
                  <AgGridColumn
                    width={100}
                    field="price"
                    cellRenderer="priceRenderer"
                    sortable={true}
                    filter={true}
                    valueGetter={priceValueGetter}
                  ></AgGridColumn>
                  <AgGridColumn
                    width={150}
                    field="address"
                    cellRenderer="addressRenderer"
                    sortable={true}
                    filter={true}
                    valueGetter={addressValueGetter}
                  ></AgGridColumn>
                  <AgGridColumn
                    width={150}
                    field="description"
                    cellRenderer="descriptionRenderer"
                    sortable={true}
                    filter={true}
                    valueGetter={descriptionValueGetter}
                  ></AgGridColumn>
                  <AgGridColumn
                    width={90}
                    cellRenderer="imageRenderer"
                    field="Image"
                    sortable={false}
                    filter={false}
                  ></AgGridColumn>
                  <AgGridColumn
                    width={150}
                    field="createdby"
                    cellRenderer="createdbyRenderer"
                    sortable={true}
                    filter={true}
                    valueGetter={createdbyValueGetter}
                  ></AgGridColumn>
                </AgGridReact>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Product;
