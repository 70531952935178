import React, { useState, useEffect } from "react";
import "./OtpVerification.css";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import { useForm } from "react-hook-form";

function OtpVerification() {
  const [coord, setCoord] = useState("");
  sessionStorage.removeItem("_expiredTime");

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (position) {
      setCoord(position.coords);
    });
  }, []);
  const location = useLocation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  let history = useHistory();

  const [message, setmessage] = useState("");
  const [locaitonData, setIP] = useState({});

  //creating function to load ip address from the API
  const getData = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    setIP(res.data);
  };

  useEffect(() => {
    getData();
  }, []);

  function OtpVerification(postdata) {
    let data = JSON.stringify({
      emailOTP: postdata?.emailOTP,
      mobileOTP: postdata?.mobileOTP,
      ipAddress: locaitonData?.IPv4,
      location: `${locaitonData?.city}, ${locaitonData?.state}, ${locaitonData?.country_name}`,
      latitude: coord === "" ? 0 : coord?.latitude,
      longitude: coord === "" ? 0 : coord.longitude,
      date: new Date(),
    });

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/AdminVerifyOTP/${location?.state?._id}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        if (response.data.status === 200) {
          sessionStorage.setItem("token", response.data.token);
          sessionStorage.setItem(
            "userData",
            JSON.stringify(response.data.userData)
          );
          setTimeout(() => {
            history.replace("/dashboard");
          }, 1000);
        } else {
          if (response.data.status === 201) {
            setmessage(response.data.message);
          } else {
            setmessage("Something went wrong");
          }
        }
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  return (
    <section className="login_section">
      <div className="form_box">
        <form onSubmit={handleSubmit(OtpVerification)}>
          <div className="col-md-12 text-center">
            <img
              className="logoImg"
              src="../../images/logo.png"
              alt="renderImages"
            />
          </div>
          <h4>OTP Verification</h4>
          <input
            type="tel"
            className="form-control"
            {...register("emailOTP", {
              required: true,
              minLength: 4,
              maxLength: 4,
              min: 0,
            })}
            placeholder="Primary Email OTP"
          />
          {errors?.emailOTP?.type === "minLength" && (
            <p className="error">Only 4 digit number</p>
          )}
          {errors?.emailOTP?.type === "maxLength" && (
            <p className="error">Only 4 digit number</p>
          )}
          {errors?.emailOTP?.type === "min" && (
            <p className="error">Only positive number</p>
          )}
          <input
            type="tel"
            {...register("mobileOTP", {
              required: true,
              minLength: 4,
              maxLength: 4,
              min: 0,
            })}
            className="form-control"
            placeholder="Secondary Email OTP"
          />
          {errors?.mobileOTP?.type === "minLength" && (
            <p className="error">Only 4 digit number</p>
          )}
          {errors?.mobileOTP?.type === "maxLength" && (
            <p className="error">Only 4 digit number</p>
          )}
          {errors?.mobileOTP?.type === "min" && (
            <p className="error">Only positive number</p>
          )}
          <button type="submit" className="form-control btn">
            Verify
          </button>
          {message}
        </form>
      </div>
    </section>
  );
}

export default OtpVerification;
