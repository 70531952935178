import React, { useState, useMemo, useEffect } from "react";
import Footer from "../Footer/footer";
import Sidebar from "../Sidebar/sidebar";
import Header from "../Header/header";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { AiFillDelete } from "react-icons/ai";
import axios from "axios";

/**
 *
 * Level Group is replaced with category [level_group => category]
 */

const levelgroupRenderer = (props) => {
  return <span>{props.data.level}</span>;
};

const SrNoRenderer = (props) => {
  return <span>{props.rowIndex + 1}</span>;
};

function Levelgroup() {
  const [rowData, setRowData] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [isOpen, setIsOpen] = useState(false);
  const [DeleteCategoriesId, setDeleteCategoriesId] = useState("");

  function somefun() {
    setIsOpen(false);
    cancelForm();
  }

  function fun() {
    setIsOpen(false);
    cross();
  }

  function cancelForm() {
    reset();
  }

  function cross() {
    reset();
  }

  const ChildMessageRenderer = (props) => {
    return (
      <div className="iconActionList">
        <div
          className="DeleteIcon"
          onClick={() => {
            setDeleteCategoriesId(props.data._id);
          }}
          data-bs-toggle="modal"
          data-bs-target="#DeleteCategoriesData"
        >
          <AiFillDelete
            className="ActionIcon"
            alt="delete"
            src="../../images/delete.jpg"
          />
        </div>
      </div>
    );
  };

  const rowHeight = 55;
  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      sortable: true,
      resizable: true,
      filter: true,
      getQuickFilterText: (params) => {
        return params.value.name;
      },
      editable: true,
    };
  }, []);

  function deleteData(index) {
    let data = JSON.stringify({
      grouplevelId: index,
    });
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/deletelevelgroup`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        getlevelgroup();
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  function addnew(postdata) {
    setIsOpen(false);
    let data = JSON.stringify({
      level: postdata.levelgroup,
    });

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/createlevel?level=primary`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        getlevelgroup();
        reset();
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  useEffect(() => {
    getlevelgroup();
  }, []);

  function getlevelgroup() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getlevel`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        setRowData(response.data.levelData);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  return (
    <>
      <div className="page-wrapper">
        <div className="container-fluid ">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">
                Category
                <div className="float-end btns_head">
                  <button
                    className="btn btn-theme btn-sm"
                    onClick={() => setIsOpen(true)}
                  >
                    Add Category
                  </button>
                </div>
              </h4>

              <div>
                <Modal show={isOpen} onHide>
                  <Modal.Body>
                    <div
                      tabIndex="-1"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-dialog-scrollable">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                              Add Category
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                              onClick={() => fun()}
                            ></button>
                          </div>
                          <form
                            onSubmit={handleSubmit(addnew)}
                            autoComplete="new-password"
                          >
                            <div className="modal-body">
                              <div className="row">
                                <div className="col-md-12 mb-3">
                                  <label className="form-label">
                                    Add Category
                                  </label>
                                  <div className="position-relative">
                                    <input
                                      className="form-control"
                                      {...register("levelgroup")}
                                    />

                                    <div
                                      className="hint_box"
                                      style={{ display: "block" }}
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="modal-footer">
                              <button
                                onClick={() => somefun()}
                                type="button"
                                className="btn btn-danger CancelBtn"
                                data-bs-dismiss="modal"
                              >
                                Cancel
                              </button>
                              <button type="submit" className="btn submitBtn">
                                Submit
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
              </div>
              <div
                className="modal fade"
                id="Updatelevelgroup"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              ></div>

              <div
                className="modal fade DeletePopup"
                id="DeleteCategoriesData"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="">
                            <p>Are you sure you want to delete this User?</p>
                            <button
                              type="button"
                              className="btn btn-danger CancelBtn"
                              data-bs-dismiss="modal"
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              onClick={() => {
                                deleteData(DeleteCategoriesId);
                              }}
                              className="btn submitBtn"
                              data-bs-dismiss="modal"
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                style={{ width: "100%" }}
                className="ag-theme-alpine tableFix"
              >
                <AgGridReact
                  rowHeight={rowHeight}
                  defaultColDef={defaultColDef}
                  rowData={rowData}
                  frameworkComponents={{
                    childMessageRenderer: ChildMessageRenderer,
                    levelgroupRenderer: levelgroupRenderer,
                    srNoRenderer: SrNoRenderer,
                  }}
                >
                  <AgGridColumn
                    width={90}
                    field="SrNo"
                    Srno={true}
                    sortable={false}
                    filter={false}
                    cellRenderer="srNoRenderer"
                  ></AgGridColumn>
                  <AgGridColumn
                    width={300}
                    field="Action"
                    cellRenderer="childMessageRenderer"
                    colId="params"
                    sortable={false}
                    filter={false}
                  ></AgGridColumn>
                  <AgGridColumn
                    width={200}
                    cellRenderer="levelgroupRenderer"
                    headerName="Category"
                    field="level"
                    sortable={true}
                    filter={true}
                  ></AgGridColumn>
                </AgGridReact>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Levelgroup;
