import React, { useState, useMemo, useEffect } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import axios from "axios";
import { MdModeEditOutline } from "react-icons/md";
import { AiFillDelete } from "react-icons/ai";
import { AiFillEye } from "react-icons/ai";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "./Articles.css";
import "../Students/Students.css";
import Footer from "../Footer/footer";
import Sidebar from "../Sidebar/sidebar";
import Header from "../Header/header";
import { useHistory } from "react-router-dom";
import ToggleButton from "../../components/Toggle_Button/ToggleButton";
import Modal_Popup from "../../components/modal_renderer/Modal_Popup";
import isdownloadUtils from "../../components/utilDownloadRoles";

const SrNoRenderer = (props) => {
  return (
    <>
      <span>{props.node.rowIndex + 1}</span>
    </>
  );
};

const ImageRenderer = (props, ref) => {
  return (
    <span className="profle_img_box">
      <img
        className="profile_img_table"
        src={props.data.authorimage}
        alt="icon"
      />
    </span>
  );
};

const TitleRenderer = (props) => {
  return (
    <>
      <span>{props.data.title}</span>
    </>
  );
};

const CreatedByRenderer = (props) => {
  return (
    <>
      <span>{props.data.createdBy}</span>
    </>
  );
};

const DescriptionRenderer = (props) => {
  return (
    <>
      <span>{props.data.description}</span>
    </>
  );
};

const TagsRenderer = (props) => {
  return (
    <span className="profle_img_box">
      <span>{props?.data?.tags?.join(", ")}</span>
    </span>
  );
};

const GroupRender = (props) => {
  return (
    <span className="profle_img_box">
      <span>
        {props?.data?.groups_Id.map((item) => item.grpName)?.join(", ")}
      </span>
    </span>
  );
};

const CreatedDateRenderer = (props) => {
  const date = new Date(+props.data.createdDate);
  return (
    <span className="profle_img_box">
      <span>{date.toLocaleDateString("en-US")}</span>
    </span>
  );
};

const ArticleimageRenderer = (props) => {
  return (
    <span>
      <img className="profile_img_table" src={props.data.banner} alt="icon" />
    </span>
  );
};

function Articles() {
  const [rowData, setRowData] = useState([]);
  const [DeleteBusinessId, setDeleteBusinessId] = useState("");
  const rowHeight = 55;
  let history = useHistory();
  const token = `${sessionStorage.getItem("token")}`;
  let encoded = encodeURIComponent(token);

  useEffect(() => {
    getarticles();
  }, []);

  // ----Delete article API ---//

  function articlesdeleteData(id) {
    let data = JSON.stringify({
      articleId: id,
    });
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/deletearticles`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        getarticles();
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  // ---- Get article API ---//

  function getarticles() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getarticles`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        setRowData(response.data.articleData);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  // ---- Update article status API ---//

  function changeArticleStatus(articleId) {
    let data = JSON.stringify({
      articleId: articleId,
    });

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/changearticleStatus`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        getarticles();
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  // ---- Update article Trending status API ---//

  function changeArticleTrendingStatus(articleId) {
    let data = JSON.stringify({
      articleId: articleId,
    });
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/changearticletrendingstatus`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        getarticles();
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  const StatusRenderer = (props) => {
    let status = props?.data?.status ? "deactivate" : "activate";
    let message = "Are you sure you want to " + status + " this article?";
    return (
      <Modal_Popup
        status={props?.data?.status}
        onClick={() => {
          changeArticleStatus(props?.data?._id);
        }}
        message={message}
      />
    );
  };

  const TrendingRender = (props) => {
    return (
      <ToggleButton
        handleToggle={() => {
          changeArticleTrendingStatus(props?.data?._id);
        }}
        status={props?.data?.trending}
      />
    );
  };

  const ChildMessageRenderer = (props) => {
    const localId = JSON.parse(sessionStorage.getItem("userData"))?._id;
    return (
      <div className="iconActionList">
        <div
          className="ViewIcon"
          onClick={(e) => {
            e.preventDefault();
            window.open(
              `${process.env.REACT_APP_REDIRECTION_URL_ARTICLES}/${props.data._id}/${encoded}/${localId}`,
              "_blank",
              "noopener,noreferrer"
            );
          }}
        >
          <AiFillEye />
        </div>
        <div
          className="editIcon"
          onClick={() =>
            history.push({
              pathname: "/UpdateArticles",
              state: { details: props.data },
            })
          }
        >
          <MdModeEditOutline className="ActionIcon" />
        </div>
        <div
          className="DeleteIcon"
          onClick={() => {
            setDeleteBusinessId(props.data._id);
          }}
          data-bs-toggle="modal"
          data-bs-target="#BusinessDeleteId"
        >
          <AiFillDelete className="ActionIcon" />
        </div>
      </div>
    );
  };

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      getQuickFilterText: (params) => {
        return params.value.name;
      },
      editable: true,
      filter: true,
      sortable: true,
    };
  }, []);

  const createdValueGetter = (params) => {
    const date = new Date(+params.data.createdDate);
    const newdate = date.toLocaleDateString("en-US");
    return newdate;
  };

  const customLowerCaseComparator = (valueA, valueB) => {
    if (typeof valueA === "string") {
      return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
    }
    return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
  };

  const groupValueGetter = (params) => {
    return params.data.groups_Id.map((item) => item.grpName).join(",");
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">
                Articles
                <div className="float-end btns_head">
                  <button
                    className="btn btn-theme btn-sm"
                    onClick={() => history.push("./AddArticles")}
                  >
                    Add New Article
                  </button>
                  {/* {isdownloadUtils("uploadArticles") && (
                    <button
                      className="btn btn-theme btn-sm"
                      onClick={(e) => {
                        e.preventDefault();
                        // window.location.href = "http://192.168.1.22:4200/#/";
                      }}
                    >
                      Upload Articles
                    </button>
                  )} */}
                </div>
              </h4>
              <div
                className="modal fade DeletePopup"
                id="BusinessDeleteId"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="">
                            {" "}
                            <p>Are you sure you want to delete this article?</p>
                            <button
                              type="button"
                              className="btn btn-danger CancelBtn"
                              data-bs-dismiss="modal"
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              onClick={() => {
                                articlesdeleteData(DeleteBusinessId);
                              }}
                              className="btn submitBtn"
                              data-bs-dismiss="modal"
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div
                  style={{ width: "100%" }}
                  className="ag-theme-alpine tableFix"
                >
                  <AgGridReact
                    // style={{ width: "100%", height: "100%;" }}
                    rowHeight={rowHeight}
                    rowData={rowData}
                    defaultColDef={defaultColDef}
                    frameworkComponents={{
                      childMessageRenderer: ChildMessageRenderer,
                      srNoRenderer: SrNoRenderer,
                      titleRenderer: TitleRenderer,
                      createdByRenderer: CreatedByRenderer,
                      descriptionRenderer: DescriptionRenderer,
                      imageRenderer: ImageRenderer,
                      tagRenderer: TagsRenderer,
                      statusRenderer: StatusRenderer,
                      TrendingRender: TrendingRender,
                      GroupRender: GroupRender,
                      createdDateRenderer: CreatedDateRenderer,
                      articleimageRenderer: ArticleimageRenderer,
                    }}
                  >
                    <AgGridColumn
                      width={90}
                      field="SrNo"
                      cellRenderer="srNoRenderer"
                      sortable={false}
                      filter={false}
                      pinned="left"
                    ></AgGridColumn>
                    <AgGridColumn
                      width={120}
                      field="Actions"
                      cellRenderer="childMessageRenderer"
                      colId="params"
                      sortable={false}
                      filter={false}
                      pinned="left"
                    ></AgGridColumn>
                    <AgGridColumn
                      width={140}
                      field="title"
                      cellRenderer="titleRenderer"
                      sortable={true}
                      filter={true}
                      comparator={customLowerCaseComparator}
                      pinned="left"
                    ></AgGridColumn>
                    <AgGridColumn
                      width={150}
                      field="authorName"
                      sortable={true}
                      filter={true}
                      comparator={customLowerCaseComparator}
                      pinned="left"
                    ></AgGridColumn>
                    <AgGridColumn
                      width={200}
                      field="tags"
                      cellRenderer="tagRenderer"
                      // valueGetter={tagValueGetter}
                      sortable={true}
                      filter={true}
                      comparator={customLowerCaseComparator}
                    ></AgGridColumn>
                    <AgGridColumn
                      width={250}
                      field="groups"
                      cellRenderer="GroupRender"
                      valueGetter={groupValueGetter}
                      sortable={true}
                      filter={true}
                      comparator={customLowerCaseComparator}
                    ></AgGridColumn>
                    <AgGridColumn
                      width={125}
                      field="author image"
                      cellRenderer="imageRenderer"
                      sortable={false}
                      filter={false}
                    ></AgGridColumn>
                    <AgGridColumn
                      width={125}
                      field="article image"
                      cellRenderer="articleimageRenderer"
                      sortable={false}
                      filter={false}
                    ></AgGridColumn>
                    <AgGridColumn
                      cellRenderer="statusRenderer"
                      width={110}
                      field="status"
                      sortable={true}
                      filter={true}
                      comparator={customLowerCaseComparator}
                    ></AgGridColumn>
                    <AgGridColumn
                      cellRenderer="TrendingRender"
                      width={110}
                      field="trending"
                      sortable={true}
                      filter={true}
                      comparator={customLowerCaseComparator}
                    ></AgGridColumn>
                    <AgGridColumn
                      width={95}
                      field="subject"
                      sortable={true}
                      filter={true}
                      comparator={customLowerCaseComparator}
                      // valueGetter={subjectValueGetter}
                    ></AgGridColumn>
                    <AgGridColumn
                      width={95}
                      field="priority"
                      sortable={true}
                      filter={true}
                      comparator={customLowerCaseComparator}
                      //  valueGetter={priorityValueGetter}
                    ></AgGridColumn>
                    <AgGridColumn
                      cellRenderer="createdDateRenderer"
                      width={90}
                      field="Created Date"
                      sortable={true}
                      filter={true}
                      valueGetter={createdValueGetter}
                    ></AgGridColumn>
                  </AgGridReact>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Articles;
