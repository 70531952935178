import { useState, useEffect, forwardRef } from "react";
import axios from "axios";
import "./UserRoleManagement.css";
import Select from "react-select";
import { isDownload, modules, subModules } from "./roles";

const roles = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
];

const UpdateUserRoleManagement = forwardRef((props, ref) => {
  const [pvisibility, setpvisibility] = useState(false);
  const [password, setPassword] = useState("");
  const [ipaddress, setIpAddress] = useState("");
  const [secondaryEmail, setsecondaryEmail] = useState("");
  const [mobile, setMobileNumber] = useState("");
  const updatedroles = props?.updatedData?.roles?.map((item) => {
    const mapdata = [];
    modules.forEach((module) => {
      if (item === module.value) {
        mapdata.push(module);
      }
    });
    return mapdata;
  });

  const updatedroleIdentifiers = props?.updatedData?.roleIdentifier?.map(
    (item) => {
      const mapdata = [];
      roles.forEach((module) => {
        if (item === module.value) {
          mapdata.push(module);
        }
      });
      return mapdata;
    }
  );

  const flattened = updatedroles?.flatMap((num) => num);
  const flattenedroleIdentifiers = updatedroleIdentifiers?.flatMap(
    (num) => num
  );
  const [selectedOptiongroup, setselectedOptiongroup] = useState([]);
  const [selectedOptionroleIdentifier, setselectedOptionroleIdentifier] =
    useState([]);
  const [showRoles, setShowRoles] = useState(false);
  const [data] = useState(modules);
  const [roleErrorStatus, setRoleErrorStatus] = useState(null);

  // ------------------------------  Sub Modules  ---------------------------------------------- //
  const subupdatedroles = props?.updatedData?.subroles?.map((item) => {
    const mapdata = [];
    subModules.forEach((submodule, index) => {
      if (item === submodule.value) {
        mapdata.push(submodule);
      }
    });
    return mapdata;
  });

  const subflattened = subupdatedroles?.flatMap((num) => num);
  const [selectedsubModulesOptiongroup, setSelectedsubModulesOptiongroup] =
    useState([]);
  const [subModulesdata] = useState(subModules);
  const [subroleErrorStatus, setsubRoleErrorStatus] = useState(null);

  // ------------------------------  Download Update Upload Modules  ---------------------------------------------- //
  const isdownloadmoduleupdated = props?.updatedData?.isDownload?.map(
    (item) => {
      const mapdata = [];
      isDownload.forEach((downloadItem, index) => {
        if (item === downloadItem.value) {
          mapdata.push(downloadItem);
        }
      });
      return mapdata;
    }
  );

  const isDownloadflattened = isdownloadmoduleupdated?.flatMap((num) => num);
  const [
    selectedisDownloadModulesOptiongroup,
    setSelectedisDownloadModulesOptiongroup,
  ] = useState([]);
  const [isDownloadModulesdata] = useState(isDownload);
  const [isDownloadroleErrorStatus, setisDownloadRoleErrorStatus] =
    useState(null);

  useEffect(() => {
    updateStudentData(); //children function of update roles
  }, [props.refresh]);

  useEffect(() => {
    if (selectedOptiongroup.length > 0) {
      setRoleErrorStatus(false);
    }
  }, [selectedOptiongroup]);

  useEffect(() => {
    if (props?.updatedData?.roles.includes("business")) {
      setShowRoles(true);
      setselectedOptionroleIdentifier(props?.updatedData?.roleIdentifier);
    }
  }, []);

  useEffect(() => {
    if (selectedsubModulesOptiongroup.length > 0) {
      setsubRoleErrorStatus(false);
    }
  }, [selectedsubModulesOptiongroup]);

  useEffect(() => {
    if (selectedisDownloadModulesOptiongroup.length > 0) {
      setisDownloadRoleErrorStatus(false);
    }
  }, [selectedisDownloadModulesOptiongroup]);

  function updateStudentData() {
    if (props.refresh != 0) {
      if (
        props?.updatedData?.roles.length <= 0 &&
        selectedOptiongroup.length === 0
      ) {
        setRoleErrorStatus(true);
        return;
      }

      if (
        props?.updatedData?.subroles.length <= 0 &&
        selectedsubModulesOptiongroup.length === 0
      ) {
        setsubRoleErrorStatus(true);
        return;
      }
      if (
        props?.updatedData?.isDownload.length <= 0 &&
        selectedisDownloadModulesOptiongroup.length === 0
      ) {
        setisDownloadRoleErrorStatus(true);
        return;
      }
      let data = JSON.stringify({
        password,
        ipaddress: ipaddress ? ipaddress : props.updatedData?.ipaddress,
        mobile: mobile ? "+65" + mobile : "+" + props.updatedData?.mobile,
        secondaryEmail: secondaryEmail
          ? secondaryEmail
          : props?.updatedData?.secondaryEmail,
        adminId: props.updatedData?._id,
        roleIdentifier: selectedOptionroleIdentifier.join(","),
        roles:
          selectedOptiongroup.length === 0
            ? props?.updatedData?.roles.join(",")
            : selectedOptiongroup.join(","),
        subroles:
          selectedsubModulesOptiongroup.length === 0
            ? props?.updatedData?.subroles.join(",")
            : selectedsubModulesOptiongroup.join(","),
        isDownload:
          selectedisDownloadModulesOptiongroup.length === 0
            ? props?.updatedData?.isDownload.join(",")
            : selectedisDownloadModulesOptiongroup.join(","),
      });

      let config = {
        method: "post",
        url: `${process.env.REACT_APP_BASEURL}/editadmin`,
        headers: {
          Authorization: sessionStorage.getItem("token"),
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          props.onEditDataFunction();
          props.handleClose();
          setRoleErrorStatus(false);
          setsubRoleErrorStatus(false);
          setisDownloadRoleErrorStatus(false);
        })
        .catch(function (error) {
          // console.log(error);
        });
    }
  }

  const handlechange = (event) => {
    if (event.length > 0) {
      setRoleErrorStatus(false);
    } else {
      setRoleErrorStatus(true);
    }
    let array = [];
    event.forEach((option) => {
      array.push(option.value);
    });
    setselectedOptiongroup(array);
  };

  const roleIdentifierHandleChange = (event) => {
    let array = [];
    event.forEach((option) => {
      array.push(option.value);
    });
    setselectedOptionroleIdentifier(array);
  };

  const subModulehandlechange = (event) => {
    if (event.length > 0) {
      setsubRoleErrorStatus(false);
    } else {
      setsubRoleErrorStatus(true);
    }
    let array = [];
    event.forEach((option) => {
      array.push(option.value);
    });
    setSelectedsubModulesOptiongroup(array);
  };

  const isDownloadModulehandlechange = (event) => {
    if (event.length > 0) {
      setisDownloadRoleErrorStatus(false);
    } else {
      setisDownloadRoleErrorStatus(true);
    }
    let array = [];
    event.forEach((option) => {
      array.push(option.value);
    });
    setSelectedisDownloadModulesOptiongroup(array);
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12 mb-3">
          <label className="form-label">Edit Admin User Name </label>
          <div className="position-relative">
            <input
              disabled
              type="text"
              defaultValue={props.updatedData?.username}
              key={props.updatedData?.username}
              className="form-control"
            />
            <div className="hint_box" style={{ display: "block" }}></div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 mb-3">
          <label className="form-label">Edit Email Address </label>
          <div className="position-relative">
            <input
              disabled
              type="text"
              defaultValue={props.updatedData?.email}
              key={props.updatedData?.email}
              className="form-control"
            />
            <div className="hint_box" style={{ display: "block" }}></div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 mb-3">
          <label className="form-label">Edit Secondary Email Address </label>
          <div className="position-relative">
            <input
              type="email"
              defaultValue={props.updatedData?.secondaryEmail}
              key={props.updatedData?.secondaryEmail}
              onChange={(e) => setsecondaryEmail(e.target.value)}
              className="form-control"
              placeholder="Secondary Email"
            />
            <div className="hint_box" style={{ display: "block" }}></div>
          </div>
        </div>
      </div>

      {props.updatedData.type !== "master admin" && (
        <>
          <div className="row">
            <div className="col-md-12 mb-3">
              <label className="form-label">Edit Password</label>
              <div className="position-relative">
                <input
                  className="form-control"
                  type={pvisibility ? "text" : "password"}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  autoComplete="new-password"
                />
                {pvisibility ? (
                  <div>
                    <img
                      style={{
                        width: "23px",
                        position: "absolute",
                        top: "9px",
                        right: "12px",
                      }}
                      src="images/eye.png"
                      onClick={() => {
                        setpvisibility(false);
                      }}
                      alt="renderImages"
                    />
                  </div>
                ) : (
                  <div>
                    <img
                      alt="renderImages"
                      style={{
                        width: "23px",
                        position: "absolute",
                        top: "9px",
                        right: "12px",
                      }}
                      src="images/invisible.png"
                      onClick={() => {
                        setpvisibility(true);
                      }}
                    />
                  </div>
                )}
                <div style={{ display: "block" }}></div>
              </div>
            </div>
            <div className="col-md-12 mb-3">
              <label className="form-label">Edit Admin Roles</label>
              <div className="position-relative">
                <Select
                  isMulti
                  defaultValue={flattened}
                  key={flattened?.map((item, index) => {
                    return index;
                  })}
                  options={data}
                  onChange={(e) => {
                    handlechange(e);
                    if (e.length > 0) {
                      e.some((item) => item.value === "business")
                        ? setShowRoles(true)
                        : setShowRoles(false);
                    } else {
                      setShowRoles(false);
                    }
                  }}
                />
                {roleErrorStatus && (
                  <p className="error">This field is required</p>
                )}
              </div>
            </div>
            {showRoles && (
              <div className="col-md-12 mb-3">
                <label className="form-label">Role Identifier</label>
                <Select
                  isMulti
                  options={roles}
                  defaultValue={flattenedroleIdentifiers}
                  key={flattenedroleIdentifiers?.map((item, index) => {
                    return index;
                  })}
                  onChange={(e) => {
                    // console.log(e, "Roles Identifier");
                    roleIdentifierHandleChange(e);
                  }}
                />
              </div>
            )}
            <div className="col-md-12 mb-3">
              <label className="form-label">Edit Admin Sub Roles</label>
              <div className="position-relative">
                <Select
                  isMulti
                  defaultValue={[...subflattened]}
                  key={subflattened?.map((item, index) => {
                    return index;
                  })}
                  options={subModulesdata}
                  onChange={(e) => subModulehandlechange(e)}
                />
                {subroleErrorStatus && (
                  <p className="error">This field is required</p>
                )}
              </div>
            </div>
            <div className="col-md-12 mb-3">
              <label className="form-label">Edit Admin Download Roles</label>
              <div className="position-relative">
                <Select
                  isMulti
                  defaultValue={isDownloadflattened}
                  key={isDownloadflattened?.map((item, index) => {
                    return index;
                  })}
                  options={isDownloadModulesdata}
                  onChange={(e) => isDownloadModulehandlechange(e)}
                />
                {isDownloadroleErrorStatus && (
                  <p className="error">This field is required</p>
                )}
              </div>
            </div>
            <div className="col-md-12 mb-3">
              <label className="form-label">Edit Mobile Number</label>
              <div className="position-relative">
                <input
                  onChange={(e) => {
                    setMobileNumber(e.target.value);
                  }}
                  type="number"
                  defaultValue={props.updatedData?.mobile?.toString()?.slice(2)}
                  key={props.updatedData?.mobile}
                  className="form-control"
                />
                <div className="hint_box" style={{ display: "block" }}></div>
              </div>
            </div>
            <div className="col-md-12 mb-3">
              <label className="form-label">Edit Ip Address </label>
              <div className="position-relative">
                <input
                  onChange={(e) => {
                    setIpAddress(e.target.value);
                  }}
                  type="text"
                  defaultValue={props.updatedData?.ipaddress}
                  key={props.updatedData?.ipaddress}
                  className="form-control"
                />
                <div className="hint_box" style={{ display: "block" }}></div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
});

export default UpdateUserRoleManagement;
