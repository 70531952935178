import React, { useState, useMemo, useEffect } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import axios from "axios";
import { MdModeEditOutline } from "react-icons/md";
import { AiFillEye } from "react-icons/ai";
import { AiFillDelete } from "react-icons/ai";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "./Faqs.css";
import "../Students/Students.css";
import Footer from "../Footer/footer";
import Sidebar from "../Sidebar/sidebar";
import Header from "../Header/header";
import { useHistory } from "react-router-dom";
import JsonDownload from "../../components/downloadjson";
import isdownloadUtils from "../../components/utilDownloadRoles";

const SrNoRenderer = (props) => {
  return <span>{props.node.rowIndex + 1}</span>;
};

const GroupRenderer = (props, groupId) => {
  return (
    <span className="profle_img_box">
      <span>{props.data?.groupId?.groups}</span>
    </span>
  );
};

const TypeRenderer = (props) => {
  return <span>Group</span>;
};

function Faqs() {
  const [rowData, setRowData] = useState([]);
  const [DeleteFaq, setDeleteFaq] = useState("");
  const [viewFaq, setViewFaq] = useState("");
  const [faqList, setFaqList] = useState([]);
  const rowHeight = 55;
  let history = useHistory();

  useEffect(() => {
    getFaq();
  }, []);

  const [downloaddata, setDownloadData] = useState([]);

  function getFaq() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getfaq`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        let download = [];
        response.data?.groupData?.map((element, index) => {
          download.push({
            tags: element.tags,
          });
        });
        setDownloadData(download);
        setRowData(response.data.groupData);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  function deleteFaq(id) {
    let data = JSON.stringify({
      FAQId: id,
    });
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/deletefaq`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        getFaq();
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  const ChildMessageRenderer = (props) => {
    return (
      <div className="iconActionList">
        <div
          className="ViewIcon"
          onClick={() => {
            setFaqList(props.data.faqData);
            setViewFaq(props.data);
          }}
          data-bs-toggle="modal"
          data-bs-target="#FaqViewId"
        >
          <AiFillEye className="ActionIcon" />
        </div>

        <div
          className="editIcon"
          onClick={() => {
            history.push({
              pathname: "UpdateFaqs",
              state: { details: props.data },
            });
          }}
        >
          <MdModeEditOutline className="ActionIcon" />
        </div>

        <div
          className="DeleteIcon"
          onClick={() => {
            setDeleteFaq(props.data._id);
          }}
          data-bs-toggle="modal"
          data-bs-target="#FaqDeleteId"
        >
          <AiFillDelete className="ActionIcon" />
        </div>
      </div>
    );
  };

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      sortable: true,
      resizable: true,
      filter: true,
      getQuickFilterText: (params) => {
        return params.value.name;
      },
      editable: true,
    };
  }, []);

  const typeValueGetter = (params) => {
    if (params?.data?.classId !== undefined) {
      return "Class";
    } else if (params?.data?.groupId !== undefined) {
      return "Group";
    }
  };

  const groupValueGetter = (params) => {
    if (params?.data?.classId !== undefined) {
      return params?.data?.classId?.businessName;
    } else if (params?.data?.groupId !== undefined) {
      return params?.data?.groupId?.groups;
    }
  };

  const customLowerCaseComparator = (valueA, valueB) => {
    if (typeof valueA === "string") {
      return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
    }

    return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">
                FAQ's
                <div className="float-end btns_head d-flex">
                  <button
                    className="btn btn-theme btn-sm"
                    onClick={() => {
                      history.push("/AddFaqs");
                    }}
                  >
                    Add New FAQ
                  </button>
                  {/* <button className="btn btn-theme btn-sm">Upload CSV</button> */}
                  {isdownloadUtils("downloadFAQs") && (
                    <JsonDownload
                      title="Download FAQs"
                      fileName="tags-excel"
                      downloadfile={downloaddata}
                      btnClassName={"btn btn-theme btn-sm"}
                      btnColor={"#f9560f"}
                    />
                  )}
                </div>
              </h4>

              <div
                className="modal fade DeletePopup"
                id="FaqDeleteId"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="">
                            {" "}
                            <p>Are you sure you want to delete this faq?</p>
                            <button
                              type="button"
                              className="btn btn-danger CancelBtn"
                              data-bs-dismiss="modal"
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              onClick={() => {
                                deleteFaq(DeleteFaq);
                              }}
                              className="btn submitBtn"
                              data-bs-dismiss="modal"
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="modal fade"
                id="FaqViewId"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-scrollable">
                  <div className="modal-content">
                    <div className="modal-body" style={{ minHeight: "470px" }}>
                      <div className="row">
                        <div className="col-md-12 mb-3">
                          <label className="form-label"> FAQ Detail </label>
                          <div className="position-relative">
                            <table className="table table-bordered table_fix_width">
                              <tbody>
                                <tr>
                                  <th>Name</th>
                                  <td>
                                    {viewFaq.classId?.businessName}
                                    {viewFaq.groupId?.groups}
                                  </td>
                                </tr>
                              </tbody>
                            </table>

                            {faqList?.map((faqService, index) => (
                              <div>
                                <div>
                                  <span>Question &nbsp;{index + 1}</span>
                                  <textarea disabled className="form-control">
                                    {faqService.question}
                                  </textarea>
                                </div>
                                <div>
                                  <span>Answer &nbsp;{index + 1}</span>
                                  <textarea disabled className="form-control">
                                    {faqService.answer}
                                  </textarea>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className="cancleButton">
                        <button
                          type="button"
                          className="btn btn-danger CancelBtn"
                          data-bs-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                style={{ width: "100%" }}
                className="ag-theme-alpine tableFix"
              >
                <AgGridReact
                  // style={{ width: "100%", height: "100%;" }}
                  rowHeight={rowHeight}
                  // pagination={pagination}
                  // paginationPageSize={paginationPageSize}
                  rowData={rowData}
                  // domLayout="autoHeight"
                  defaultColDef={defaultColDef}
                  frameworkComponents={{
                    childMessageRenderer: ChildMessageRenderer,
                    srNoRenderer: SrNoRenderer,
                    groupRenderer: GroupRenderer,
                    typeRenderer: TypeRenderer,
                  }}
                >
                  <AgGridColumn
                    width={90}
                    field="SrNo"
                    cellRenderer="srNoRenderer"
                    sortable={false}
                    filter={false}
                  ></AgGridColumn>
                  <AgGridColumn
                    width={140}
                    field="Actions"
                    cellRenderer="childMessageRenderer"
                    colId="params"
                    sortable={true}
                    filter={true}
                  ></AgGridColumn>
                  <AgGridColumn
                    width={90}
                    field="Type"
                    cellRenderer="typeRenderer"
                    valueGetter={typeValueGetter}
                    sortable={true}
                    comparator={customLowerCaseComparator}
                    // floatingFilter={true}
                    filter={true}
                  ></AgGridColumn>

                  <AgGridColumn
                    width={300}
                    field="groupName"
                    cellRenderer="groupRenderer"
                    valueGetter={groupValueGetter}
                    sortable={true}
                    comparator={customLowerCaseComparator}
                    // floatingFilter={true}
                    filter={true}
                  ></AgGridColumn>
                </AgGridReact>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Faqs;
