import React, { useState, useMemo, useEffect, useRef } from "react";
import axios from "axios";
import Footer from "../Footer/footer";
import Sidebar from "../Sidebar/sidebar";
import Header from "../Header/header";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import JoditEditor from "jodit-react";
//import "./AddEvents.css";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File

function AddFaqs() {
  const defaultFonts = [
    "Arial",
    "Comic Sans MS",
    "Courier New",
    "Impact",
    "Georgia",
    "Tahoma",
    "Trebuchet MS",
    "Verdana",
  ];
  const sortedFontOptions = [
    "Logical",
    "Salesforce Sans",
    "Garamond",
    "Sans-Serif",
    "Serif",
    "Times New Roman",
    "Helvetica",
    ...defaultFonts,
  ].sort();
  const [serviceList, setServiceList] = useState([
    { sequenceNumber: "", question: "", answer: "" },
  ]);
  const [optionsgroup, setoptionsgroup] = useState([]);
  const [groupData, setGroupData] = useState("");
  const [groupId, setGroupId] = useState("");
  const [groupSelectedStatus, setGroupSelectedStatus] = useState(false);
  const [faqStatus, setFaqStatus] = useState(false);
  let history = useHistory();

  const handleServiceRemove = (index) => {
    const list = [...serviceList];
    list.splice(index, 1);
    setServiceList(list);
  };

  const handleServiceAdd = () => {
    setServiceList([
      ...serviceList,
      { sequenceNumber: "", question: "", answer: "" },
    ]);
  };

  function addFaqApi() {
    if (groupId == "") {
      setGroupSelectedStatus(true);
      return;
    }
    if (
      serviceList.length > 0 &&
      serviceList.some((item, index) => {
        return (
          item.sequenceNumber == "" || item.question == "" || item.answer == ""
        );
      })
    ) {
      setFaqStatus(true);
      return;
    }

    let data = JSON.stringify({
      type: "group",
      groupId: groupId,
      faqData: serviceList,
    });

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/addFAQ`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        history.push("./Faqs");
      })
      .catch(function (error) {
        // console.log(error);
      });
  }
  useEffect(() => {
    getnewgroup();
  }, []);
  // ----multi group selete get--//

  function getnewgroup() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getgroups`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        let optionsgroup = [];
        response.data.data.forEach((grup, index) => {
          optionsgroup.push({ label: grup.groups, value: grup._id });
        });
        setoptionsgroup(optionsgroup);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  function selectID(event) {
    setGroupId(event);
    setGroupSelectedStatus(true);
  }

  const editor = useRef(null);
  const [content, setContent] = useState("");
  const config = {
    readonly: false,
    height: 400,
  };

  console.log(serviceList, "serviceListList");

  return (
    <>
      <div className="page-wrapper">
        <div className="container-fluid min_height">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Add FAQ</h4>
              <div className="branchData">
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <label className="form-label">Type</label>
                    <input
                      // value={singleService.sequence}
                      type="text"
                      value="Group"
                      className="form-control"
                      disabled
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="form-label">Select Group</label>
                    <Select
                      options={optionsgroup}
                      //  onChange={(event)=> setGroupData(event.value)}
                      onChange={(event) => selectID(event.value)}
                    />
                    {groupSelectedStatus && groupId == "" && (
                      <p style={{ color: "red" }}>This field is required</p>
                    )}
                  </div>
                  {serviceList?.map((singleService, index) => (
                    <div className="col-md-12">
                      <div className="faqButton">
                        <div className="actionBtn">
                          {serviceList.length - 1 === index &&
                            serviceList.length > 0 && (
                              <button
                                type="button"
                                onClick={handleServiceAdd}
                                className="btn btn-success"
                              >
                                <span>+</span>
                              </button>
                            )}

                          {serviceList.length !== 1 && (
                            <button
                              type="button"
                              onClick={() => handleServiceRemove(index)}
                              className="btn btn-danger"
                            >
                              <span>-</span>
                            </button>
                          )}
                        </div>
                        <div className="col-md-12 mt-3">
                          <label className="form-label">Sequence Number</label>
                          <input
                            // value={singleService.sequence}
                            type="text"
                            className="form-control"
                            onChange={(e) => {
                              singleService.sequenceNumber = e.target.value;
                            }}
                          />
                        </div>
                        <div className="col-md-12 mt-3">
                          <label className="form-label">Question</label>
                          <textarea
                            type="textarea"
                            //value={singleService.question}
                            className="form-control"
                            onChange={(e) => {
                              singleService.question = e.target.value;
                            }}
                          />
                        </div>
                        <div className="col-md-12">
                          <label className="form-label">Answer</label>
                          <div className="App">
                            <SunEditor
                              width="100%"
                              plugin=""
                              height="100%"
                              onChange={(event) => {
                                singleService.answer = event;
                                setContent(event);
                              }}
                              setOptions={{
                                buttonList: [
                                  ["undo", "redo"],
                                  ["font", "fontSize", "formatBlock"],
                                  ["paragraphStyle", "blockquote"],
                                  [
                                    "bold",
                                    "underline",
                                    "italic",
                                    "strike",
                                    "subscript",
                                    "superscript",
                                  ],
                                  ["fontColor", "hiliteColor"],
                                  ["align", "list", "lineHeight"],
                                  ["outdent", "indent"],
                                  ["fullScreen", "showBlocks", "codeView"],
                                  ["preview", "print"],
                                  ["removeFormat"],

                                  [
                                    ":i-More Misc-default.more_vertical",
                                    "image",
                                    "video",
                                    "audio",
                                    "link",
                                    "fullScreen",
                                    "showBlocks",
                                    "codeView",
                                    "preview",
                                    "print",
                                    "save",
                                    "template",
                                  ],
                                  [
                                    ":r-More Rich-default.more_plus",
                                    "table",
                                    "horizontalRule",
                                    "imageGallery",
                                  ],
                                ],
                                defaultTag: "div",
                                minHeight: "300px",
                                showPathLabel: false,
                                font: sortedFontOptions,
                              }}
                            />
                          </div>
                        </div>
                        <span>
                          {faqStatus &&
                            serviceList[index].sequenceNumber == "" && (
                              <span style={{ color: "red" }}>
                                Sequence Number,{" "}
                              </span>
                            )}
                        </span>
                        <span>
                          {faqStatus && serviceList[index].question == "" && (
                            <span style={{ color: "red" }}>Question</span>
                          )}
                        </span>

                        {faqStatus &&
                          (serviceList[index].sequenceNumber == "" ||
                            serviceList[index].question == "") && (
                            <span style={{ color: "red" }}> is required</span>
                          )}
                      </div>
                    </div>
                  ))}
                  <div className="col-md-12 mt-3">
                    <button
                      type="button"
                      className="btn CancelBtn me-3"
                      onClick={() => {
                        history.push("./Faqs");
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn submitBtn me-3"
                      onClick={addFaqApi}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddFaqs;
