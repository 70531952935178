import React, { useState, useMemo, useEffect } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import axios from "axios";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "./tags.css";
import Updatetags from "./Updatetags";
import { MdModeEditOutline } from "react-icons/md";
import { AiFillDelete } from "react-icons/ai";
import Footer from "../Footer/footer";
import Sidebar from "../Sidebar/sidebar";
import Header from "../Header/header";
import Modal_Popup from "../../components/modal_renderer/Modal_Popup";
import JsonDownload from "../../components/downloadjson";
import isdownloadUtils from "../../components/utilDownloadRoles";
import { useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import LoadingSpinner from "../../components/loader";

function Tags() {
  const {
    register: uploadRegister,
    unregister,
    handleSubmit: handlesubmitUpload,
    reset,
    formState: { errors: uploaderrors },
  } = useForm({ mode: "onBlur" });

  const [UpdateTagsData, setUpdateTagsData] = useState();
  const [DeleteCategoriesId, setDeleteCategoriesId] = useState("");
  const [DetailTagsId, setDetailTagsId] = useState([]);
  const [tags, setTags] = useState("");
  const [rowData, setRowData] = useState([]);
  const [downloaddata, setDownloadData] = useState([]);

  const pagination = true;
  const paginationPageSize = 10;
  const rowHeight = 55;

  const ChildMessageRenderer = (props) => {
    return (
      <div
        className="iconActionList"
        onClick={() => {
          setUpdateTagsData(props.data);
        }}
        data-bs-toggle="modal"
        data-bs-target="#UpdateTagsData"
      >
        <div className="editIcon">
          <MdModeEditOutline className="ActionIcon" />
        </div>
        <div
          className="DeleteIcon"
          onClick={() => {
            setDeleteCategoriesId(props.data._id);
          }}
          data-bs-toggle="modal"
          data-bs-target="#DeleteCategoriesData"
        >
          <AiFillDelete className="ActionIcon" />
        </div>
      </div>
    );
  };

  function addnewtag() {
    let data = JSON.stringify({
      tags: tags,
    });

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/addtag`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        resetForm();
        getnewtag();
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  function changeTagStatus(tagId) {
    let data = JSON.stringify({
      tagId: tagId,
    });

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/changetagstatus`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        getnewtag();
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  const resetForm = () => {
    setTags("");
  };

  function fun() {
    setUploadIsOpen(false);
    setLoader(false);
  }

  useEffect(() => {
    getnewtag();
  }, []);

  function getnewtag() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/gettag`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        let download = [];
        response.data?.tagData?.map((element, index) => {
          download.push({
            _id: element._id,
            tags: element.tags,
            category: element.level?.map((item) => item?.level)?.join(", "),
            subject: element.subcategoryId
              ?.map((item) => item?.subCategory)
              ?.join(", "),
            searchTerms: element.searchTerms
              ?.filter((tags, index, dTag) => {
                return dTag.indexOf(tags) === index;
              })
              ?.join(", "),
            tagStatus: element.tagStatus,
            displayTag: element.displayTag
              ?.filter((tags, index, dTag) => {
                return dTag.indexOf(tags) === index;
              })
              ?.join(", "),
            extraone: element.extraone,
            extratwo: element.extratwo,
            status: element.status,
          });
        });
        setDownloadData(download);
        setRowData(response.data.tagData);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  function deleteDatatag(index) {
    let data = JSON.stringify({
      tagId: index,
    });

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/deletetag`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        getnewtag();
      })
      .catch(function (error) {
        // console.log(error);
      });
  }
  const SrNoRenderer = (props) => {
    return <span className="profle_img_box">{props.rowIndex + 1}</span>;
  };

  const TagsRenderer = (props) => {
    return (
      <span className="profle_img_box">
        <span>{props.data.tags}</span>
      </span>
    );
  };

  const level_tagRenderer = (props) => {
    return (
      <span>{props.data.level?.map((item) => item?.level)?.join(", ")}</span>
    );
  };

  const subcategoryId_tagRenderer = (props) => {
    return (
      <span>
        {props.data.subcategoryId?.map((item) => item?.subCategory)?.join(", ")}
      </span>
    );
  };

  const displayTag_tagRenderer = (props) => {
    return (
      <span>
        {props.data.displayTag
          ?.filter((tags, index, dTag) => {
            return dTag.indexOf(tags) === index;
          })
          ?.join(", ")}
      </span>
    );
  };

  const searchTerms_tagRenderer = (props) => {
    return (
      <span>
        {props.data.searchTerms
          ?.filter((tags, index, dTag) => {
            return dTag.indexOf(tags) === index;
          })
          ?.join(", ")}
      </span>
    );
  };

  const tagIdRenderer = (props) => {
    return <span>{props.data?._id}</span>;
  };

  const extraoneRenderer = (props) => {
    return <span>{props.data?.extraone}</span>;
  };

  const extratwoRenderer = (props) => {
    return <span>{props.data?.extratwo}</span>;
  };

  const StatusRenderer = (props) => {
    let status = props?.data?.status ? "deactivate" : "activate";
    let message = "Are you sure you want to " + status + " this tag?";
    return (
      <Modal_Popup
        status={props?.data?.status}
        onClick={() => {
          changeTagStatus(props?.data?._id);
        }}
        message={message}
      />
    );
  };

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 100,
      sortable: true,
      resizable: true,
      filter: true,
      getQuickFilterText: (params) => {
        return params.value.name;
      },
      editable: true,
    };
  }, []);

  const editdataReloadFunc = () => {
    getnewtag();
  };

  const customLowerCaseComparator = (valueA, valueB) => {
    if (typeof valueA === "string") {
      return valueA?.toLowerCase().localeCompare(valueB?.toLowerCase());
    }
    return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
  };
  const statusValueGetter = (params) => {
    return params.data.status;
  };
  const tagIdValueGetter = (params) => {
    return params.data._id;
  };
  const subjectValueGetter = (params) => {
    return params.data.subcategoryId
      ?.map((item) => item?.subCategory)
      ?.join(", ");
  };
  const cartegoryValueGetter = (params) => {
    return params.data.level?.map((item) => item?.level)?.join(", ");
  };
  // ---------------------  Duplicate Data  ---------------

  const [duplicatedatastatus, setduplicatedatastatus] = useState(false);
  const [duplicateData, setDuplicatedata] = useState([]);
  // Bulk Upload of Tags and Update Tags Functionality
  const [uplaodStatus, setUplaodStatus] = useState({
    status: false,
    value: "",
  });
  const [uploadIsOpen, setUploadIsOpen] = useState(false);
  const [updateuploadStatus, setUpdateUploadStatus] = useState("");
  const [uploadIsOpenStatusModal, setUploadIsOpenStatusModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [SuccessfullUpdateStatus, setSuccessfullUpdateStatus] = useState(true);

  function uploadBusinessFiles(postdata) {
    setLoader(true);
    let data = new FormData();
    data.append("attachment3", postdata.uploadFile[0]);
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/${
        updateuploadStatus == "Upload" ? "tagsupload" : "tagsupdate"
      }`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        // ---------- Upload & Update Business bulk upload response ------------ //
        setUploadIsOpen(false);
        setUploadIsOpenStatusModal(true);
        setUplaodStatus({
          status: response.data.status,
          value: response.data.message,
        });
        setTimeout(() => {
          if (response.data.status) {
            setduplicatedatastatus(true);
            reset();
            getnewtag();
            let Duplicatetagss = [];
            let alreaydExistingTag = [];
            let blanklevel = [];
            let blanksubcategoryId = [];
            let blanktagStatus = [];
            let blankdisplayTag = [];
            let blanktags = [];
            let noExistingCateogry = [];
            let noExistingsubject = [];
            let blankStatus = [];
            let wrongTagStatus = [];

            let update = [];

            response.data.Duplicatetagss &&
              response.data.Duplicatetagss.forEach((element) => {
                Duplicatetagss.push({
                  errorName: "Duplicate Tags",
                  ...element,
                });
              });
            response.data.alreaydExistingTag &&
              response.data.alreaydExistingTag.forEach((element) => {
                alreaydExistingTag.push({
                  errorName: "Already Existing Tag",
                  ...element,
                });
              });
            response.data.blanklevel &&
              response.data.blanklevel.forEach((element) => {
                blanklevel.push({
                  errorName: "Blank Category data",
                  ...element,
                });
              });
            response.data.blanksubcategoryId &&
              response.data.blanksubcategoryId.forEach((element) => {
                blanksubcategoryId.push({
                  errorName: "Blank Subject data",
                  ...element,
                });
              });
            response.data.blanktagStatus &&
              response.data.blanktagStatus.forEach((element) => {
                blanktagStatus.push({
                  errorName: "Blank Tag Status",
                  ...element,
                });
              });
            response.data.blankdisplayTag &&
              response.data.blankdisplayTag.forEach((element) => {
                blankdisplayTag.push({
                  errorName: "Blank Display tag",
                  ...element,
                });
              });
            response.data.blanktags &&
              response.data.blanktags.forEach((element) => {
                blanktags.push({ errorName: "Blank Tags", ...element });
              });

            response.data.noExistingCateogry &&
              response.data.noExistingCateogry.forEach((element) => {
                noExistingCateogry.push({
                  errorName: "No Existing Cateogry",
                  ...element,
                });
              });

            response.data.noExistingsubject &&
              response.data.noExistingsubject.forEach((element) => {
                noExistingsubject.push({
                  errorName: "No Existing Subject",
                  ...element,
                });
              });
            response.data.blankStatus &&
              response.data.blankStatus.forEach((element) => {
                blankStatus.push({
                  errorName: "Blank Status",
                  ...element,
                });
              });
            response.data.wrongTagStatus &&
              response.data.wrongTagStatus.forEach((element) => {
                wrongTagStatus.push({
                  errorName: "Wrong Tag Status",
                  ...element,
                });
              });
            // response.data.update &&
            //   response.data.update.forEach((element) => {
            //     update.push({ errorName: "Updated Bussiness", ...element });
            //   });
            const updateBusinessStatus =
              !Duplicatetagss.length &&
              !alreaydExistingTag.length &&
              !blanklevel.length &&
              !blanksubcategoryId.length &&
              !blanktagStatus.length &&
              !blankdisplayTag.length &&
              !blanktags.length &&
              !noExistingCateogry.length &&
              !noExistingsubject.length &&
              !blankStatus.length &&
              !wrongTagStatus.length;

            if (updateBusinessStatus) {
              setSuccessfullUpdateStatus(false);
            }

            setDuplicatedata([
              ...Duplicatetagss,
              ...alreaydExistingTag,
              ...blanklevel,
              ...blanksubcategoryId,
              ...blanktagStatus,
              ...blankdisplayTag,
              ...blanktags,
              ...noExistingCateogry,
              ...noExistingsubject,
              ...blankStatus,
              ...wrongTagStatus,
              // ...update,
            ]);
          }
        }, 1000);
      })
      .catch(function (error) {
        fun();
        setUploadIsOpenStatusModal(true);
        setUplaodStatus({
          status: false,
          value: "Something went wrong",
        });
      })
      .finally(() => {
        setTimeout(() => {
          setUploadIsOpenStatusModal(false);
        }, 1000);
      });
  }

  return (
    <>
      {!duplicatedatastatus && (
        <div className="page-wrapper">
          <div className="container-fluid ">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">
                  Tags
                  <div className="float-end btns_head d-flex">
                    <button
                      className="btn btn-theme btn-sm"
                      data-bs-toggle="modal"
                      data-bs-target="#createGroup"
                    >
                      Add New Tag
                    </button>

                    {isdownloadUtils("uploadTags") && (
                      <button
                        className="btn btn-theme btn-sm"
                        onClick={() => {
                          setUploadIsOpen(true);
                          setUpdateUploadStatus("Upload");
                        }}
                      >
                        Upload Tags
                      </button>
                    )}
                    {isdownloadUtils("updateBusinesses") && (
                      <button
                        className="btn btn-theme btn-sm"
                        onClick={() => {
                          setUploadIsOpen(true);
                          setUpdateUploadStatus("Update");
                        }}
                      >
                        Update Tags
                      </button>
                    )}
                    {isdownloadUtils("downloadTags") && (
                      <JsonDownload
                        title="Download Tags"
                        fileName="tags-excel"
                        downloadfile={downloaddata}
                        btnClassName={"btn btn-theme btn-sm"}
                        btnColor={"#f9560f"}
                      />
                    )}
                  </div>
                </h4>
                <div>
                  <Modal show={uploadIsOpen} onHide>
                    <Modal.Body>
                      <div
                        tabIndex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <form
                          onSubmit={handlesubmitUpload(uploadBusinessFiles)}
                        >
                          <div className="modal-dialog modal-dialog-scrollable">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5
                                  className="modal-title"
                                  id="exampleModalLabel"
                                >
                                  {updateuploadStatus} File
                                </h5>
                                <button
                                  type="button"
                                  className="btn-close"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                  onClick={() => {
                                    fun();
                                  }}
                                  // onClick={resetForm}
                                ></button>
                              </div>
                              <div className="modal-body">
                                <div className="row">
                                  <div className="col-md-12 mb-4">
                                    <label className="form-label">
                                      {" "}
                                      Upload File
                                    </label>
                                    <div className="position-relative">
                                      <input
                                        className="form-control"
                                        type="file"
                                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                        {...uploadRegister("uploadFile", {
                                          required: true,
                                        })}
                                        onChange={(e) => {
                                          unregister("uploadFile");
                                        }}
                                      />
                                      {uploaderrors?.uploadFile?.type ===
                                        "required" && (
                                        <p className="error">
                                          This field is required
                                        </p>
                                      )}
                                      <div
                                        className="hint_box"
                                        style={{ display: "block" }}
                                      ></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {loader && <LoadingSpinner />}
                              <div className="modal-footer">
                                <button
                                  type="button"
                                  className="btn CancelBtn"
                                  data-bs-dismiss="modal"
                                  onClick={fun}
                                >
                                  Cancel
                                </button>
                                <button
                                  type="submit"
                                  className="btn submitBtn"
                                  disabled={loader}
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </Modal.Body>
                  </Modal>
                </div>
                <div>
                  <div
                    className="modal fade"
                    id="createGroup"
                    tabIndex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title" id="exampleModalLabel">
                            Add Tag
                          </h5>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={resetForm}
                          ></button>
                        </div>
                        <div className="modal-body">
                          <div className="row">
                            <div className="col-md-12 mb-3">
                              <label className="form-label">Add Tag </label>
                              <div className="position-relative">
                                <input
                                  type="text"
                                  value={tags}
                                  className="form-control"
                                  onChange={(e) => {
                                    setTags(e.target.value);
                                  }}
                                />
                                <div
                                  className="hint_box"
                                  style={{ display: "block" }}
                                ></div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-danger CancelBtn"
                            data-bs-dismiss="modal"
                            onClick={resetForm}
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            onClick={addnewtag}
                            className="btn submitBtn"
                            data-bs-dismiss="modal"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="modal fade"
                  id="UpdateCategoriesData"
                  tabIndex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                ></div>
                <div
                  className="modal fade DeletePopup"
                  id="DeleteCategoriesData"
                  tabIndex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog  modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-body">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="">
                              <p>Are you sure you want to delete this tag</p>
                              <button
                                type="button"
                                className="btn btn-danger CancelBtn"
                                data-bs-dismiss="modal"
                              >
                                Cancel
                              </button>
                              <button
                                type="submit"
                                onClick={() => {
                                  deleteDatatag(DeleteCategoriesId);
                                }}
                                className="btn submitBtn"
                                data-bs-dismiss="modal"
                              >
                                Delete
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <Modal show={uploadIsOpenStatusModal}>
                    <Modal.Body>
                      <div
                        tabIndex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog modal-dialog-scrollable">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5
                                className="modal-title"
                                id="exampleModalLabel"
                              >
                                <p>
                                  <span
                                    style={{
                                      color: uplaodStatus.status
                                        ? "green"
                                        : "red",
                                    }}
                                  >
                                    {uplaodStatus.value}
                                  </span>
                                </p>
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Modal.Body>
                  </Modal>
                </div>
                <div
                  className="modal fade"
                  id="UpdateTagsData"
                  tabIndex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-scrollable">
                    <div className="modal-content">
                      <Updatetags
                        updatedData={UpdateTagsData}
                        onEditDataFunction={editdataReloadFunc}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="modal fade"
                  id="DetailTagsData"
                  tabIndex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-scrollable">
                    <div className="modal-content">
                      <div
                        className="modal-body"
                        style={{ minHeight: "470px" }}
                      >
                        <div className="row">
                          <div className="col-md-12 mb-3">
                            <label className="form-label"> Tags Name </label>
                            <div className="position-relative">
                              <input
                                type="text"
                                defaultValue={DetailTagsId}
                                className="form-control"
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  style={{ width: "100%" }}
                  className="ag-theme-alpine tableFix"
                >
                  <AgGridReact
                    rowHeight={rowHeight}
                    rowData={rowData}
                    defaultColDef={defaultColDef}
                    frameworkComponents={{
                      childMessageRenderer: ChildMessageRenderer,
                      tagsRenderer: TagsRenderer,
                      extraoneRenderer: extraoneRenderer,
                      extratwoRenderer: extratwoRenderer,
                      tagIdRenderer: tagIdRenderer,
                      statusRenderer: StatusRenderer,
                      srNoRenderer: SrNoRenderer,
                      level_tagRenderer: level_tagRenderer,
                      subcategoryId_tagRenderer: subcategoryId_tagRenderer,
                      displayTag_tagRenderer: displayTag_tagRenderer,
                      searchTerms_tagRenderer: searchTerms_tagRenderer,
                    }}
                  >
                    <AgGridColumn
                      width={90}
                      field="SrNo"
                      Srno={true}
                      sortable={false}
                      filter={false}
                      cellRenderer="srNoRenderer"
                    ></AgGridColumn>
                    <AgGridColumn
                      width={300}
                      field="Action"
                      cellRenderer="childMessageRenderer"
                      sortable={false}
                      filter={false}
                    ></AgGridColumn>
                    <AgGridColumn
                      width={260}
                      cellRenderer="tagIdRenderer"
                      field="tagId"
                      sortable={true}
                      valueGetter={tagIdValueGetter}
                    ></AgGridColumn>
                    <AgGridColumn
                      width={260}
                      cellRenderer="tagsRenderer"
                      field="tags"
                      sortable={true}
                      comparator={customLowerCaseComparator}
                    ></AgGridColumn>
                    <AgGridColumn
                      width={300}
                      field="Status"
                      cellRenderer="statusRenderer"
                      colId="params"
                      sortable={true}
                      filter={true}
                      valueGetter={statusValueGetter}
                    ></AgGridColumn>
                    <AgGridColumn
                      width={300}
                      field="Category"
                      sortable={false}
                      filter={true}
                      cellRenderer="level_tagRenderer"
                      valueGetter={cartegoryValueGetter}
                    ></AgGridColumn>
                    <AgGridColumn
                      width={260}
                      field="Subject"
                      sortable={true}
                      valueGetter={subjectValueGetter}
                      cellRenderer="subcategoryId_tagRenderer"
                    ></AgGridColumn>
                    <AgGridColumn
                      width={260}
                      field="tagStatus"
                      sortable={true}
                    ></AgGridColumn>
                    <AgGridColumn
                      width={260}
                      field="displayTag"
                      sortable={true}
                      cellRenderer="displayTag_tagRenderer"
                    ></AgGridColumn>
                    <AgGridColumn
                      width={260}
                      field="searchTerms"
                      sortable={true}
                      cellRenderer="searchTerms_tagRenderer"
                    ></AgGridColumn>
                    <AgGridColumn
                      width={260}
                      field="extraone"
                      sortable={true}
                      cellRenderer="extraoneRenderer"
                    ></AgGridColumn>
                    <AgGridColumn
                      width={260}
                      field="extratwo"
                      sortable={true}
                      cellRenderer="extratwoRenderer"
                    ></AgGridColumn>
                  </AgGridReact>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {duplicatedatastatus && (
        <div className="page-wrapper">
          <div className="container-fluid ">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">
                  {updateuploadStatus == "Update"
                    ? "Updated Tags Error Log"
                    : "Tags Data Upload Error Log "}
                  <div className="float-end btns_head d-flex">
                    <button
                      className="btn btn-theme btn-sm"
                      onClick={() => {
                        setduplicatedatastatus(false);
                        setLoader(false);
                        setUploadIsOpenStatusModal(false);
                      }}
                    >
                      Back
                    </button>
                    <JsonDownload
                      title="Download Excel"
                      fileName="errorlog-tags-excel"
                      downloadfile={duplicateData}
                      btnClassName={"btn btn-theme btn-sm"}
                      btnColor={"#f9560f"}
                    />
                  </div>
                </h4>
                <div
                  style={{ width: "100%" }}
                  className="ag-theme-alpine tableFix"
                >
                  <AgGridReact
                    rowHeight={rowHeight}
                    rowData={duplicateData}
                    defaultColDef={defaultColDef}
                    frameworkComponents={{
                      srNoRenderer: SrNoRenderer,
                    }}
                  >
                    <AgGridColumn
                      width={90}
                      field="SrNo"
                      Srno={true}
                      sortable={false}
                      filter={false}
                      cellRenderer="srNoRenderer"
                    ></AgGridColumn>
                    {updateuploadStatus == "Update" && (
                      <AgGridColumn
                        width={90}
                        headerName="Tag Id"
                        field="_id"
                        sortable={false}
                        filter={false}
                      ></AgGridColumn>
                    )}
                    {SuccessfullUpdateStatus && (
                      <AgGridColumn
                        width={150}
                        field="errorName"
                        sortable={false}
                        filter={false}
                      ></AgGridColumn>
                    )}
                    <AgGridColumn
                      width={300}
                      field="level"
                      headerName="Category"
                      sortable={false}
                      filter={false}
                    ></AgGridColumn>
                    <AgGridColumn
                      width={260}
                      field="subcategoryId"
                      headerName="Subject"
                      sortable={true}
                    ></AgGridColumn>
                    <AgGridColumn
                      width={260}
                      field="tagStatus"
                      sortable={true}
                    ></AgGridColumn>
                    <AgGridColumn
                      width={260}
                      field="displayTag"
                      sortable={true}
                    ></AgGridColumn>
                    <AgGridColumn
                      width={260}
                      field="tags"
                      sortable={true}
                    ></AgGridColumn>
                    <AgGridColumn
                      width={300}
                      field="status"
                      sortable={true}
                      filter={true}
                    ></AgGridColumn>
                  </AgGridReact>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Tags;
