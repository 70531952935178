import { useCallback, useState, useMemo, useEffect, useRef } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import axios from "axios";
import UpdateBusiness from "./updateBusiness";
import { AiFillEye, AiFillDelete, AiFillSetting } from "react-icons/ai";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "./Business.css";
import "../Students/Students.css";
import Footer from "../Footer/footer";
import Sidebar from "../Sidebar/sidebar";
import Header from "../Header/header";
import Modal_Popup from "../../components/modal_renderer/Modal_Popup";
import { useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import LoadingSpinner from "../../components/loader";
import JsonDownload from "../../components/downloadjson";
import Form from "react-bootstrap/Form";
import isdownloadUtils from "../../components/utilDownloadRoles";

const SrNoRenderer = (props) => {
  return (
    <>
      <span>{props.node.rowIndex + 1}</span>
    </>
  );
};

const ProfileStatusRenderer = (props) => {
  return (
    <>
      <span>{props.data?.profilestatus}</span>
    </>
  );
};

const NameRenderer = (props) => {
  return (
    <>
      <span>{props.data?.admin_id?.name}</span>
    </>
  );
};
const ImageRenderer = (props) => {
  return (
    <>
      {" "}
      <span className="profle_img_box">
        {props.data?.image.length > 0 && (
          <img
            alt="icon"
            className="profile_img_table"
            src={props.data?.image[0]}
          />
        )}
      </span>
      <span></span>
    </>
  );
};
const BusinessIdRenderer = (props) => {
  return (
    <>
      <span>{props.data?._id}</span>
    </>
  );
};
const AdminidRenderer = (props) => {
  return (
    <>
      <span>{props.data?.admin_id?._id}</span>
    </>
  );
};
const AboutBusinessRenderer = (props) => {
  return (
    <>
      <div dangerouslySetInnerHTML={{ __html: props?.data?.aboutBusiness }} />
    </>
  );
};

const UniqueIdRenderer = (props) => {
  return (
    <>
      <span>{props?.data?.admin_id?.uniqueId}</span>
    </>
  );
};
const EmailVerifyRenderer = (props) => {
  return (
    <>
      <span>
        {props?.data?.admin_id && props?.data?.admin_id?.email_verified
          ? "true"
          : "false"}
      </span>
    </>
  );
};
const MobileVerifyRenderer = (props) => {
  return (
    <>
      <span>
        {props?.data?.admin_id && props?.data?.admin_id?.mobile_verified
          ? "true"
          : "false"}
      </span>
    </>
  );
};

const BusinessEmailRenderer = (props) => {
  if (props.data?.admin_id?.email) {
    return (
      <>
        <span>{props.data?.admin_id?.email}</span>
      </>
    );
  } else {
    return (
      <>
        <span></span>
      </>
    );
  }
};

const MobileNoRenderer = (props) => {
  if (props.data?.admin_id?.mobileNo?.includes("NaN")) {
    return <span></span>;
  } else {
    return <span>{props.data?.admin_id?.mobileNo}</span>;
  }
};

const BusinessNameRenderer = (props) => {
  return <span>{props.data?.businessName}</span>;
};
const PriorityRenderer = (props) => {
  return <span>{props.data?.priority}</span>;
};

const nameValueGetter = (params) => {
  return params?.data?.admin_id?.name;
};

const emailValueGetter = (params) => {
  return params?.data?.admin_id?.email;
};

const DropDownValueGetter = (props) => {
  return props?.data?.admin_id?.claimStatus;
};

const mobileNoValueGetter = (params) => {
  return params?.data?.admin_id?.mobileNo;
};

const duplicateNameValueGetter = (params) => {
  return params?.data?.name;
};

const duplicateEmailValueGetter = (params) => {
  return params?.data?.email;
};

const duplicateMobileNoValueGetter = (params) => {
  return params?.data?.mobileNo;
};

const TagsRenderer = (props) => {
  return (
    <span>{props?.data?.tags?.map((item) => item?.tags)?.join(", ")}</span>
  );
};

// const DisplayTagRenderer = (props) => {
//   return (
//     <span>{props?.data?.tags?.map((item) => item?.tags)?.join(", ")}</span>
//   );
// };

const LevelRenderer = (props) => {
  return (
    <span>{props?.data.level?.map((item) => item?.level)?.join(", ")}</span>
  );
};

const GroupsRenderer = (props) => {
  return (
    <span>{props?.data.groupId?.map((item) => item?.groups)?.join(", ")}</span>
  );
};

const SiteTypeRenderer = (props) => {
  return <span>{props?.data?.sitetype?.join(", ")}</span>;
};

const uniqueValueGetter = (params) => params?.data?.admin_id?.uniqueId;
const perActiveDeactiveValueGetter = (params) => params?.data?.admin_id?.status;
const classTrendingValueGetter = (params) => params?.data?.trending;
const emailVerifyValueGetter = (params) =>
  params?.data?.admin_id?.email_verified;
const mobileVerifyValueGetter = (params) =>
  params?.data?.admin_id?.mobile_verified;
const classStatusValueGetter = (params) => params?.data?.status;
const profileValueGetter = (params) => params.data?.profilestatus;
const createdbyValueGetter = (params) => params?.data?.admin_id?.createdBy;
const updatedbyValueGetter = (params) => params.data?.admin_id?.email;
const adminIdValueGetter = (params) => params.data?.admin_id?._id;
const businessIdValueGetter = (params) => params.data?._id;
const tagsValueGetter = (params) =>
  params?.data?.tags?.map((item) => item?.tags)?.join(", ");
const levelValueGetter = (params) =>
  params?.data.level?.map((item) => item?.level)?.join(", ");
const groupValueGetter = (params) =>
  params?.data.groupId?.map((item) => item?.groups)?.join(", ");
const siteTypeValueGetter = (params) => params?.data?.sitetype?.join(", ");
const createdOnValueGetter = (params) =>
  params.data?.admin_id?.createdAt.split("T")[0];
const updatedOnValueGetter = (params) =>
  params.data?.admin_id?.updatedAt.split("T")[0];

const categoryValueGetter = (params) =>
  params.data.businesssubCategory
    .map((item) => {
      return item.subCategory;
    })
    .join(",");

const CategoryRenderer = (props) =>
  props.data.businesssubCategory
    .map((item) => {
      return item.subCategory;
    })
    .join(",");

const CreationRenderer = (props) => {
  let display = props.data?.admin_id?.createdAt.split("T")[0];
  return <span>{display}</span>;
};

const UpdationRenderer = (props) => {
  let display = props.data?.admin_id?.updatedAt.split("T")[0];
  return <span>{display}</span>;
};

const CreatedByRenderer = (props) => {
  return <span>{props?.data?.admin_id?.createdBy}</span>;
};

const UpdatedByRenderer = (props) => {
  let display = props.data?.admin_id?.email;
  return <span>{display}</span>;
};

function Business() {
  const [uniqueId, setuniqueId] = useState("");
  const gridRef = useRef();
  const [address1, setaddress1] = useState("");
  const [address2, setaddress2] = useState("");
  const [country, setcountry] = useState("");
  const [postalCode, setpostalCode] = useState("");
  const [rowData, setRowData] = useState([]);
  const [businessBulkUploadData, setBusinessBulkUploadData] = useState([]);
  const [UpdateBusinessData, setUpdateBusinessData] = useState({});
  const [Businessview, setBusinessview] = useState([]);
  const [DeleteBusinessId, setDeleteBusinessId] = useState("");
  const [DeleteClassId, setDeleteClassId] = useState("");
  const rowHeight = 55;
  const [isOpen, setIsOpen] = useState(false);
  const [pvisibility, setpvisibility] = useState(false);
  const [cpvisibility, setcpvisibility] = useState(false);
  const [uploadIsOpen, setUploadIsOpen] = useState(false);
  const [email_alreay_exist, setEmail_alreay_exist] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm({ mode: "onBlur" });
  const {
    register: uploadRegister,
    unregister,
    handleSubmit: handlesubmitUpload,
    formState: { errors: uploaderrors },
  } = useForm({ mode: "onBlur" });

  function somefun() {
    setIsOpen(false);
    setUploadIsOpen(false);
    setUploadIsOpenStatusModal(false);
    setEmail_alreay_exist([]);
    cross();
  }

  function fun() {
    setIsOpen(false);
    setUploadIsOpen(false);
    setLoader(false);
    setEmail_alreay_exist([]);
    cross();
  }

  function cross() {
    setLoader(false);
    setEmail_alreay_exist([]);
    reset();
  }

  const [duplicatedatastatus, setduplicatedatastatus] = useState(false);
  const [duplicateData, setDuplicatedata] = useState([]);

  // --------- business bulk upload ------------ //
  const [uplaodStatus, setUplaodStatus] = useState({
    status: false,
    value: "",
  });
  const [uploadIsOpenStatusModal, setUploadIsOpenStatusModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [updateuploadStatus, setUpdateUploadStatus] = useState("");
  const [SuccessfullUpdateStatus, setSuccessfullUpdateStatus] = useState(true);

  function uploadBusinessFiles(postdata) {
    setLoader(true);
    let data = new FormData();
    data.append("attachment", postdata.uploadFile[0]);
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/${
        updateuploadStatus == "Upload" ? "studentbulkUpload" : "userUpdate"
      }`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        // ---------- Upload & Update Business bulk upload response ------------ //
        setUploadIsOpen(false);
        setUploadIsOpenStatusModal(true);
        setUplaodStatus({
          status: response.data.status,
          value: response.data.message,
        });
        setTimeout(() => {
          if (response.data.status) {
            setduplicatedatastatus(true);
            reset();
            getbusinessnew();
            let Categorydata = [];
            let DuplicateBussinesname = [];
            let DuplicateEmails = [];
            let Groupsdata = [];
            let blank_bussinesname = [];
            let checkNumber = [];
            let email_alreay_exist = [];
            let leveldata = [];
            let tagdata = [];
            let existing_bussiness = [];
            let existingunique_id = [];
            let Duplicateunique_id_or_blank_uniqueid = [];
            let blank_uniqueid = [];
            let blanksitetype = [];
            let blankageto = [];
            let wrongAgeto = [];
            let wrongAgeFrom = [];
            let blacnkagefrom = [];
            let wrongsitetype = [];
            let invalidCLassORadminID = [];
            let update = [];
            let invalid_roleIdentifier_or_blank = [];
            let invalid_Permanent_activation_or_Blank = [];
            let invalid_classstatus_or_Blank = [];
            let blankpassword = [];

            response.data.Categorydata &&
              response.data.Categorydata.forEach((element) => {
                Categorydata.push({ ...element, errorName: "Category data" });
              });
            response.data.DuplicateBussinesname &&
              response.data.DuplicateBussinesname.forEach((element) => {
                DuplicateBussinesname.push({
                  ...element,
                  errorName: "Duplicate Bussines Name",
                });
              });
            response.data.blank_bussinesname &&
              response.data.blank_bussinesname.forEach((element) => {
                blank_bussinesname.push({
                  ...element,
                  errorName: "Blank Bussinesname",
                });
              });
            response.data.DuplicateEmails &&
              response.data.DuplicateEmails.forEach((element) => {
                DuplicateEmails.push({
                  ...element,
                  errorName: "Duplicate Emails",
                });
              });
            response.data.Groupsdata &&
              response.data.Groupsdata.forEach((element) => {
                Groupsdata.push({ ...element, errorName: "Groups data" });
              });
            response.data.checkNumber &&
              response.data.checkNumber.forEach((element) => {
                checkNumber.push({ ...element, errorName: "Check Number" });
              });
            response.data.email_alreay_exist &&
              response.data.email_alreay_exist.forEach((element) => {
                email_alreay_exist.push({
                  ...element,
                  errorName: "Email Already Exist",
                });
              });
            response.data.leveldata &&
              response.data.leveldata.forEach((element) => {
                leveldata.push({ ...element, errorName: "Level data" });
              });
            response.data.tagdata &&
              response.data.tagdata.forEach((element) => {
                tagdata.push({ ...element, errorName: "Tags data" });
              });
            response.data.existing_bussiness &&
              response.data.existing_bussiness.forEach((element) => {
                existing_bussiness.push({
                  ...element,
                  errorName: "Existing Bussiness",
                });
              });
            response.data.existingunique_id &&
              response.data.existingunique_id.forEach((element) => {
                existingunique_id.push({
                  ...element,
                  errorName: "Existing Unique Id",
                });
              });
            response.data.Duplicateunique_id_or_blank_uniqueid &&
              response.data.Duplicateunique_id_or_blank_uniqueid.forEach(
                (element) => {
                  Duplicateunique_id_or_blank_uniqueid.push({
                    ...element,
                    errorName: "Duplicate or blank unique_id",
                  });
                }
              );
            response.data.blanksitetype &&
              response.data.blanksitetype.forEach((element) => {
                blanksitetype.push({
                  ...element,
                  errorName: "Blank Site Type",
                });
              });
            response.data.blankageto &&
              response.data.blankageto.forEach((element) => {
                blankageto.push({
                  ...element,
                  errorName: "Blank Age To",
                });
              });
            response.data.wrongAgeto &&
              response.data.wrongAgeto.forEach((element) => {
                wrongAgeto.push({
                  ...element,
                  errorName: "Wrong Age To",
                });
              });
            response.data.wrongAgeFrom &&
              response.data.wrongAgeFrom.forEach((element) => {
                wrongAgeFrom.push({
                  ...element,
                  errorName: "Wrong Age From",
                });
              });
            response.data.blacnkagefrom &&
              response.data.blacnkagefrom.forEach((element) => {
                blacnkagefrom.push({
                  ...element,
                  errorName: "Blank Age From",
                });
              });
            response.data.wrongsitetype &&
              response.data.wrongsitetype.forEach((element) => {
                wrongsitetype.push({
                  ...element,
                  errorName: "Wrong Site Type",
                });
              });
            response.data.invalidCLassORadminID &&
              response.data.invalidCLassORadminID.forEach((element) => {
                invalidCLassORadminID.push({
                  ...element,
                  errorName: "Invalid Class OR adminID",
                });
              });
            response.data.blank_uniqueid &&
              response.data.blank_uniqueid.forEach((element) => {
                blank_uniqueid.push({
                  ...element,
                  errorName: "Blank uniqueid",
                });
              });
            response.data.update &&
              response.data.update.forEach((element) => {
                update.push({ ...element, errorName: "Updated Bussiness" });
              });

            response.data.invalid_roleIdentifier_or_blank &&
              response.data.invalid_roleIdentifier_or_blank.forEach(
                (element) => {
                  invalid_roleIdentifier_or_blank.push({
                    ...element,
                    errorName: "Invalid or Blank RoleIdentifier",
                  });
                }
              );
            response.data.invalid_Permanent_activation_or_Blank &&
              response.data.invalid_Permanent_activation_or_Blank.forEach(
                (element) => {
                  invalid_Permanent_activation_or_Blank.push({
                    ...element,
                    errorName: "Invalid or Blank Permanent Activation",
                  });
                }
              );
            response.data.invalid_classstatus_or_Blank &&
              response.data.invalid_classstatus_or_Blank.forEach((element) => {
                invalid_classstatus_or_Blank.push({
                  ...element,
                  errorName: "Invalid or Blank Classstatus",
                });
              });
            response.data.blankpassword &&
              response.data.blankpassword.forEach((element) => {
                blankpassword.push({
                  ...element,
                  errorName: "Blank Password",
                });
              });

            const updateBusinessStatus =
              !Categorydata.length &&
              !DuplicateBussinesname.length &&
              !DuplicateEmails.length &&
              !Groupsdata.length &&
              !email_alreay_exist.length &&
              !leveldata.length &&
              !tagdata.length &&
              !existing_bussiness.length &&
              !blank_bussinesname.length &&
              !checkNumber.length &&
              !existingunique_id.length &&
              !Duplicateunique_id_or_blank_uniqueid.length &&
              !blank_uniqueid.length &&
              !blanksitetype &&
              !blankageto &&
              !wrongAgeto &&
              !wrongAgeFrom &&
              !blacnkagefrom &&
              !invalidCLassORadminID &&
              !invalid_roleIdentifier_or_blank &&
              !invalid_Permanent_activation_or_Blank &&
              !invalid_classstatus_or_Blank &&
              !blankpassword &&
              !wrongsitetype;

            if (updateBusinessStatus) {
              setSuccessfullUpdateStatus(false);
            }
            setDuplicatedata([
              ...Categorydata,
              ...DuplicateBussinesname,
              ...DuplicateEmails,
              ...Groupsdata,
              ...email_alreay_exist,
              ...leveldata,
              ...tagdata,
              ...existing_bussiness,
              ...blank_bussinesname,
              ...checkNumber,
              ...existingunique_id,
              ...Duplicateunique_id_or_blank_uniqueid,
              ...blank_uniqueid,
              ...blanksitetype,
              ...blankageto,
              ...wrongAgeto,
              ...wrongAgeFrom,
              ...blacnkagefrom,
              ...wrongsitetype,
              ...invalidCLassORadminID,
              ...update,
              ...invalid_roleIdentifier_or_blank,
              ...invalid_Permanent_activation_or_Blank,
              ...invalid_classstatus_or_Blank,
              ...blankpassword,
            ]);
          }
        }, 1000);
      })
      .catch(function (error) {
        fun();
        setUploadIsOpenStatusModal(true);
        setUplaodStatus({
          status: false,
          value: "Something went wrong",
        });
      })
      .finally(() => {
        setTimeout(() => {
          setUploadIsOpenStatusModal(false);
        }, 1000);
      });
  }

  // ----Delete API ---//

  function businessdeleteData(businessId, classId) {
    let data = JSON.stringify({
      businessId: businessId,
      classId: classId,
    });

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/deletebusiness`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        getbusinessnew();
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  // ----Create business API ---////

  function Addbusiness(postdata) {
    setIsOpen(false);
    let data = JSON.stringify({
      uniqueId: uniqueId,
      name: postdata.name,
      email: postdata.businessEmail ? postdata.businessEmail : "",
      businessName: postdata.businessName,
      password: postdata.businessPassword,
      lat: 0,
      long: 0,
      mobileNo: postdata.mobileNo !== "" ? "+65" + postdata.mobileNo : "",
      type: "business",
      address1: address1,
      address2: address2,
      country: country,
      postalCode: postalCode,
      createdBy: "Admin",
    });

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/register`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        reset();
        getbusinessnew();
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  useEffect(() => {
    getbusinessnew();
  }, []);

  // ---- Get business API ---//

  function getbusinessnew() {
    gridRef?.current?.api?.showLoadingOverlay();
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getbusiness`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        let roleidentifierStoredData = JSON.parse(
          sessionStorage.getItem("userData")
        );
        let roleIdentifierBasedBusinessData = [];
        let businessdata = [];
        response.data.data.forEach((element, index) => {
          //Start pushing data based on role indentifier
          if (
            roleidentifierStoredData?.roleIdentifier.includes(
              element?.roleIdentifier
            )
          ) {
            roleIdentifierBasedBusinessData.push(element);
          }
          //End pushing data based on role indentifier

          businessdata.push({
            SrNo: index,
            businessName: element?.businessName,
            UniqueId: element.admin_id?.uniqueId,
            permanentActivationStatus: element.admin_id?.status,
            classStatus: element?.status,
            userStatus: element.admin_id?.claimStatus,
            classTrendingStatus: element?.trending,
            priority: element.priority,
            email: element.admin_id?.email,
            mobileNo: element.admin_id?.mobileNo,
            contactPerson: element.admin_id?.name,
            email_verified: element?.admin_id?.email_verified,
            mobile_verified: element?.admin_id?.mobile_verified,
            profilestatus: element?.profilestatus,
            image: element.image.length > 0 ? "yes" : "no",
            tags: element?.tags?.map((item) => item?.tags)?.join(", "),
            category: element.level?.map((item) => item?.level)?.join(", "),
            groups: element.groupId?.map((item) => item?.groups)?.join(", "),
            searchGroups: element?.groupsName
              ?.filter((tags, index, dTag) => {
                return dTag.indexOf(tags) === index;
              })
              ?.join(", "),
            displayTags:
              element.tags?.length > 0
                ? element?.tags
                    ?.map((item) => item.displayTag)
                    ?.flatMap((num) => num)
                    ?.filter((tags, index, dTag) => {
                      return dTag.indexOf(tags) === index;
                    })
                    .join(", ")
                : [],
            classtype: element?.sitetype?.join(", "),
            ageto: element?.ageto,
            agefrom: element?.agefrom,
            aboutBusiness: element?.aboutBusiness,
            createdOn: element?.admin_id?.createdAt.split("T")[0],
            updatedOn: element?.admin_id?.updatedAt.split("T")[0],
            createdBy: element?.admin_id?.createdBy,
            updatedBy: element?.admin_id?.email,
            webAddress: element?.webAddress,
            admin_id: element.admin_id?._id,
            _id: element._id,
            subject: element.businesssubCategory
              .map((item) => {
                return item.subCategory;
              })
              .join(","),
            longitute: element.admin_id?.loc?.coordinates[0],
            latitute: element.admin_id?.loc?.coordinates[1],
          });
        });

        if (roleidentifierStoredData?.type === "subadmisn") {
          setRowData(roleIdentifierBasedBusinessData);
          // setRoleIdentifierStatus("");
        }

        if (roleidentifierStoredData?.type === "master admin") {
          setRowData(response.data.data);
        }
        setBusinessBulkUploadData(businessdata);
        gridRef?.current?.api?.hideOverlay();
      })
      .catch(function (error) {
        // console.log(error);
        gridRef?.current?.api?.hideOverlay();
      });
  }

  const token = `${sessionStorage.getItem("token")}`;
  let encoded = encodeURIComponent(token);

  const sendToClipboard = useCallback((params) => {
    // console.log(params.data);
  }, []);

  const ChildMessageRenderer = (props) => {
    const localId = JSON.parse(sessionStorage.getItem("userData"))?._id;
    return (
      <div className="iconActionList" style={{ top: "0" }}>
        <div
          className="editIcon"
          onClick={(e) => {
            e.preventDefault();
            window.open(
              `${process.env.REACT_APP_REDIRECTION_URL_BUSINESS}/${props?.data?.admin_id?._id}/${encoded}/${localId}`,
              "_blank",
              "noopener,noreferrer"
            );
          }}
        >
          {/* //http://angularklassbook.s3-website.us-east-2.amazonaws.com */}
          <AiFillSetting className="ActionIcon" />
        </div>
        <div
          className="ViewIcon"
          onClick={(e) => {
            e.preventDefault();
            window.open(
              `${process.env.REACT_APP_REDIRECTION_URL_CLASS}/${props?.data?._id}/${props?.data?.admin_id?._id}/${encoded}/${localId}`,
              "_blank",
              "noopener,noreferrer"
            );
          }}
        >
          <AiFillEye className="ActionIcon" />
        </div>
        <div
          className="DeleteIcon"
          onClick={() => {
            setDeleteBusinessId(props.data?.admin_id?._id);
            setDeleteClassId(props.data?._id);
          }}
          data-bs-toggle="modal"
          data-bs-target="#BusinessDeleteId"
        >
          <AiFillDelete className="ActionIcon" />
        </div>
      </div>
    );
  };

  const StatusRenderer = (props) => {
    let status = props?.data?.admin_id?.status ? "deactivate" : "activate";
    let message = "Are you sure you want to " + status + " this business?";
    return (
      <>
        <Modal_Popup
          status={props?.data?.admin_id?.status}
          onClick={() => {
            changeBusinessStatus(props?.data?.admin_id?._id);
          }}
          message={message}
        />
      </>
    );
  };

  const ClassStatusRenderer = (props) => {
    let disabledStatus = false;
    let status = props?.data?.status ? "deactivate" : "activate";
    // if (props?.data?.admin_id?.status === props?.data?.status) {
    //   disabledStatus = true;
    // }
    let message = "Are you sure you want to " + status + " this class?";
    return (
      <>
        <Modal_Popup
          status={props?.data?.status}
          onClick={() => {
            changeClassStatus(props?.data?._id);
          }}
          message={message}
          isDisabled={disabledStatus}
        />
      </>
    );
  };

  const ClassTrendingRender = (props) => {
    let status = props?.data?.trending ? "non-trending" : "trending";
    let message = "Are you sure you want to set this class to " + status + "?";
    return (
      <>
        <Modal_Popup
          status={props?.data?.trending}
          onClick={() => {
            changeClassTrendingStatus(props?.data?._id);
          }}
          message={message}
        />
      </>
    );
  };

  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      resizable: true,
      filter: true,
      getQuickFilterText: (params) => {
        return params.value.name;
      },
      editable: true,
    };
  }, []);

  // -------------------------------- / Table DropDown Status Change Api / -----------------------//
  const DropDownRenderer = (props) => {
    return (
      <Form.Select
        size="sm"
        aria-label=" Dropdown Select"
        onChange={(e) => {
          changeBusinessStatus(props?.data?.admin_id?._id, e.target.value);
        }}
        value={
          props?.data?.admin_id?.claimStatus
            ? props?.data?.admin_id?.claimStatus
            : 0
        }
      >
        <option value={0}>0</option>
        <option value={1}>1</option>
        <option value={2}>2</option>
      </Form.Select>
    );
  };

  function changeBusinessStatus(userId, claimStatus) {
    let data = JSON.stringify({
      userId: userId,
      claimStatus: +claimStatus,
    });
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/changeprofilestatus`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        getbusinessnew();
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  function changeClassTrendingStatus(classId) {
    let data = JSON.stringify({
      classId: classId,
    });
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/changeClasstrendingstatus`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        getbusinessnew();
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  function changeClassStatus(classId) {
    let data = JSON.stringify({
      classId: classId,
    });
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/changeClassStatus`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        getbusinessnew();
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  const editdataReloadFunc = () => {
    getbusinessnew();
  };

  const customLowerCaseComparator = (valueA, valueB) => {
    if (typeof valueA === "string") {
      return valueA?.toLowerCase().localeCompare(valueB?.toLowerCase());
    }
    return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
  };

  return (
    <>
      {!duplicatedatastatus && (
        <div className="page-wrapper">
          <div className="container-fluid ">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">
                  Business
                  <div className="float-end btns_head d-flex">
                    {isdownloadUtils("addNewBusiness") && (
                      <button
                        className="btn btn-theme btn-sm"
                        onClick={() => {
                          setIsOpen(true);
                        }}
                      >
                        Add New Business
                      </button>
                    )}
                    {isdownloadUtils("uploadBusinesses") && (
                      <button
                        className="btn btn-theme btn-sm"
                        onClick={() => {
                          setUploadIsOpen(true);
                          setUpdateUploadStatus("Upload");
                        }}
                      >
                        Upload Businesses
                      </button>
                    )}
                    {isdownloadUtils("updateBusinesses") && (
                      <button
                        className="btn btn-theme btn-sm"
                        onClick={() => {
                          setUploadIsOpen(true);
                          setUpdateUploadStatus("Update");
                        }}
                      >
                        Update Businesses
                      </button>
                    )}

                    {isdownloadUtils("downloadBusinesses") && (
                      <JsonDownload
                        title="Download Businesses"
                        fileName="business-excel"
                        downloadfile={businessBulkUploadData}
                        btnClassName={"btn btn-theme btn-sm"}
                        btnColor={"#f9560f"}
                      />
                    )}
                    {/* <button className="btn btn-theme btn-sm">Download Excel</button> */}
                  </div>
                </h4>
                <div>
                  <Modal show={uploadIsOpenStatusModal}>
                    <Modal.Body>
                      <div
                        tabIndex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog modal-dialog-scrollable">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5
                                className="modal-title"
                                id="exampleModalLabel"
                              >
                                <p>
                                  <span
                                    style={{
                                      color: uplaodStatus.status
                                        ? "green"
                                        : "red",
                                    }}
                                  >
                                    {uplaodStatus.value}
                                  </span>
                                </p>
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Modal.Body>
                  </Modal>
                </div>
                <div>
                  <Modal show={uploadIsOpen} onHide>
                    <Modal.Body>
                      <div
                        tabIndex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <form
                          onSubmit={handlesubmitUpload(uploadBusinessFiles)}
                        >
                          <div className="modal-dialog modal-dialog-scrollable">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5
                                  className="modal-title"
                                  id="exampleModalLabel"
                                >
                                  {updateuploadStatus} File
                                </h5>
                                <button
                                  type="button"
                                  className="btn-close"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                  onClick={() => {
                                    fun();
                                  }}
                                  // onClick={resetForm}
                                ></button>
                              </div>
                              <div className="modal-body">
                                <div className="row">
                                  <div className="col-md-12 mb-4">
                                    <label className="form-label">
                                      {" "}
                                      Upload File
                                    </label>
                                    <div className="position-relative">
                                      <input
                                        className="form-control"
                                        type="file"
                                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                        {...uploadRegister("uploadFile", {
                                          required: true,
                                        })}
                                        onChange={(e) => {
                                          unregister("uploadFile");
                                        }}
                                      />
                                      {uploaderrors?.uploadFile?.type ===
                                        "required" && (
                                        <p className="error">
                                          This field is required
                                        </p>
                                      )}
                                      <div
                                        className="hint_box"
                                        style={{ display: "block" }}
                                      ></div>
                                    </div>
                                  </div>

                                  {email_alreay_exist.length > 0 && (
                                    <div className="col-md-12 mb-4">
                                      <label className="form-label">
                                        Email Already Exists
                                      </label>
                                      <ul>
                                        {email_alreay_exist?.map((item) => {
                                          return <li>{item}</li>;
                                        })}
                                      </ul>
                                    </div>
                                  )}
                                </div>
                              </div>
                              {loader && <LoadingSpinner />}
                              <div className="modal-footer">
                                <button
                                  type="button"
                                  className="btn CancelBtn"
                                  data-bs-dismiss="modal"
                                  onClick={() => {
                                    somefun();
                                  }}
                                >
                                  Cancel
                                </button>
                                <button
                                  type="submit"
                                  className="btn submitBtn"
                                  disabled={loader}
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </Modal.Body>
                  </Modal>
                </div>
                <div>
                  <Modal show={isOpen} onHide>
                    <Modal.Body>
                      <div
                        tabIndex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <form onSubmit={handleSubmit(Addbusiness)}>
                          <div className="modal-dialog modal-dialog-scrollable">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5
                                  className="modal-title"
                                  id="exampleModalLabel"
                                >
                                  Add Business
                                </h5>
                                <button
                                  type="button"
                                  className="btn-close"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                  onClick={() => {
                                    fun();
                                  }}
                                  // onClick={resetForm}
                                ></button>
                              </div>
                              <div className="modal-body">
                                <div className="row">
                                  <div className="col-md-12 mb-4">
                                    <label className="form-label">
                                      {" "}
                                      Class/ Business/ Institute Name
                                    </label>
                                    <div className="position-relative">
                                      <input
                                        className="form-control"
                                        type="text"
                                        {...register("businessName", {
                                          required: true,
                                          min: 1,
                                        })}
                                      />
                                      {errors?.businessName?.type ===
                                        "required" && (
                                        <p className="error">
                                          This field is required
                                        </p>
                                      )}
                                      {errors?.businessName?.type === "min" && (
                                        <p className="error">
                                          This field is required
                                        </p>
                                      )}

                                      <div
                                        className="hint_box"
                                        style={{ display: "block" }}
                                      ></div>
                                    </div>
                                  </div>

                                  <div className="col-md-12 mb-4">
                                    <label className="form-label">
                                      {" "}
                                      Contact Person Name
                                    </label>
                                    <div className="position-relative">
                                      <input
                                        className="form-control"
                                        type="text"
                                        {...register("name", {
                                          pattern: /^[A-Za-z ]+$/i,
                                        })}
                                      />
                                      {errors?.name?.type === "pattern" && (
                                        <p className="error">Alphabet only</p>
                                      )}
                                      <div
                                        className="hint_box"
                                        style={{ display: "block" }}
                                      ></div>
                                    </div>
                                  </div>
                                  <div className="col-md-12 mb-4">
                                    <label className="form-label">
                                      {" "}
                                      Mobile No
                                    </label>
                                    <div className="position-relative">
                                      <input
                                        className="form-control"
                                        type="number"
                                        {...register("mobileNo", {
                                          minLength: 8,
                                          maxLength: 8,
                                          min: 0,
                                        })}
                                      />
                                      {errors?.mobileNo?.type ===
                                        "minLength" && (
                                        <p className="error">
                                          Only 8 digit number
                                        </p>
                                      )}
                                      {errors?.mobileNo?.type ===
                                        "maxLength" && (
                                        <p className="error">
                                          Only 8 digit number
                                        </p>
                                      )}
                                      {errors?.mobileNo?.type === "min" && (
                                        <p className="error">
                                          Only positive number
                                        </p>
                                      )}
                                      <div
                                        className="hint_box"
                                        style={{ display: "block" }}
                                      ></div>
                                    </div>
                                  </div>

                                  <div className="col-md-12 mb-4">
                                    <label className="form-label">
                                      {" "}
                                      Business Email
                                    </label>
                                    <div className="position-relative">
                                      <input
                                        className="form-control"
                                        type="email"
                                        {...register("businessEmail", {
                                          pattern:
                                            /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        })}
                                      />
                                      {errors?.businessEmail?.type ===
                                        "pattern" && (
                                        <p className="error">
                                          Invalid email address
                                        </p>
                                      )}
                                      <div
                                        className="hint_box"
                                        style={{ display: "block" }}
                                      ></div>
                                    </div>
                                  </div>

                                  <div className="col-md-12 mb-4">
                                    <label className="form-label">
                                      Password
                                    </label>
                                    <div className="position-relative">
                                      <input
                                        className="form-control"
                                        type={pvisibility ? "text" : "password"}
                                        autoComplete="new-password"
                                        {...register("businessPassword", {
                                          required: "This field is required",
                                          minLength: {
                                            value: 6,
                                            message:
                                              "Please enter minimum 6 characters",
                                          },
                                        })}
                                      />
                                      {pvisibility ? (
                                        <div>
                                          <img
                                            style={{
                                              width: "23px",
                                              position: "absolute",
                                              top: "9px",
                                              right: "12px",
                                            }}
                                            src="images/eye.png"
                                            onClick={() => {
                                              setpvisibility(false);
                                            }}
                                            alt="renderImages"
                                          />
                                        </div>
                                      ) : (
                                        <div>
                                          <img
                                            alt="renderImages"
                                            style={{
                                              width: "23px",
                                              position: "absolute",
                                              top: "9px",
                                              right: "12px",
                                            }}
                                            src="images/invisible.png"
                                            onClick={() => {
                                              setpvisibility(true);
                                            }}
                                          />
                                        </div>
                                      )}
                                      {errors.businessPassword && (
                                        <p role="alert" className="error">
                                          {errors.businessPassword.message}
                                        </p>
                                      )}
                                      <div style={{ display: "block" }}></div>
                                    </div>
                                  </div>
                                  <div className="col-md-12 mb-4">
                                    <label className="form-label">
                                      Confirm Password
                                    </label>
                                    <div className="position-relative">
                                      <input
                                        className="form-control"
                                        type={
                                          cpvisibility ? "text" : "password"
                                        }
                                        name="businessconfirmpassword"
                                        autoComplete="new-password"
                                        {...register(
                                          "businessconfirmPassword",
                                          {
                                            required: true,
                                            validate: (value) =>
                                              value ===
                                                watch("businessPassword") ||
                                              "wrong",
                                          }
                                        )}
                                      />
                                      {cpvisibility ? (
                                        <div>
                                          <img
                                            alt="renderImages"
                                            style={{
                                              width: "23px",
                                              position: "absolute",
                                              top: "9px",
                                              right: "12px",
                                            }}
                                            src="images/eye.png"
                                            onClick={() => {
                                              setcpvisibility(false);
                                            }}
                                          />
                                        </div>
                                      ) : (
                                        <div>
                                          <img
                                            alt="renderImages"
                                            style={{
                                              width: "23px",
                                              position: "absolute",
                                              top: "9px",
                                              right: "12px",
                                            }}
                                            src="images/invisible.png"
                                            onClick={() => {
                                              setcpvisibility(true);
                                            }}
                                          />
                                        </div>
                                      )}
                                      {errors?.businessconfirmPassword?.type ===
                                        "required" && (
                                        <p className="error">
                                          This field is required
                                        </p>
                                      )}
                                      {errors?.businessconfirmPassword?.type ===
                                        "validate" && (
                                        <p className="error">
                                          Password do not match
                                        </p>
                                      )}
                                      <div style={{ display: "block" }}></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="modal-footer">
                                <button
                                  type="button"
                                  className="btn CancelBtn"
                                  data-bs-dismiss="modal"
                                  onClick={() => {
                                    somefun();
                                  }}
                                >
                                  Cancel
                                </button>
                                <button type="submit" className="btn submitBtn">
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </Modal.Body>
                  </Modal>
                  <div
                    className="modal fade"
                    id="UpdateBusiness"
                    tabIndex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-dialog-scrollable">
                      <UpdateBusiness
                        updatedData={UpdateBusinessData}
                        onEditDataFunction={editdataReloadFunc}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="modal fade DeletePopup"
                  id="BusinessDeleteId"
                  tabIndex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-body">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="">
                              {" "}
                              <p>
                                Are you sure you want to delete this business?
                              </p>
                              <button
                                type="button"
                                className="btn btn-danger CancelBtn"
                                data-bs-dismiss="modal"
                              >
                                Cancel
                              </button>
                              <button
                                type="submit"
                                onClick={() => {
                                  businessdeleteData(
                                    DeleteBusinessId,
                                    DeleteClassId
                                  );
                                }}
                                data-bs-dismiss="modal"
                                className="btn submitBtn"
                              >
                                Delete
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="modal fade"
                  id="BusinessViewId"
                  tabIndex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-scrollable">
                    <div className="modal-content">
                      <div className="modal-body">
                        <div className="row">
                          <div className="col-md-12 mb-3">
                            <label className="form-label">
                              {" "}
                              Business Detail{" "}
                            </label>
                            <div className="position-relative"></div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12 mb-3">
                            <label className="form-label"> Name </label>
                            <div className="position-relative">
                              <input
                                type="text"
                                defaultValue={Businessview.name}
                                className="form-control"
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12 mb-3">
                            <label className="form-label"> Email</label>
                            <div className="position-relative">
                              <input
                                type="text"
                                defaultValue={Businessview.email}
                                className="form-control"
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12 mb-3">
                            <label className="form-label">Mobile No</label>
                            <div className="position-relative">
                              <input
                                type="text"
                                defaultValue={Businessview.mobileNo}
                                className="form-control"
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12 mb-3">
                            <label className="form-label">Postal Code</label>
                            <div className="position-relative">
                              <input
                                type="text"
                                defaultValue={Businessview.postalCode}
                                className="form-control"
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12 mb-3">
                            <label className="form-label">Address1</label>
                            <div className="position-relative">
                              <input
                                type="text"
                                defaultValue={Businessview.address1}
                                className="form-control"
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12 mb-3">
                            <label className="form-label">Address2</label>
                            <div className="position-relative">
                              <input
                                type="text"
                                defaultValue={Businessview.address2}
                                className="form-control"
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12 mb-3">
                            <label className="form-label"> Country </label>
                            <div className="position-relative">
                              <input
                                type="text"
                                defaultValue={Businessview.country}
                                className="form-control"
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{ width: "100%" }}
                  className="ag-theme-alpine tableFix"
                >
                  <AgGridReact
                    style={{ width: "100%", height: "100%;" }}
                    rowHeight={rowHeight}
                    rowData={rowData}
                    ref={gridRef}
                    defaultColDef={defaultColDef}
                    enableRangeSelection={true}
                    rowSelection={"multiple"}
                    enableCellTextSelection={true}
                    ensureDomOrder={true}
                    copySelectedRowsToClipboard={true}
                    copyHeadersToClipboard={true}
                    copySelectedRangeToClipboard={true}
                    sendToClipboard={sendToClipboard}
                    frameworkComponents={{
                      childMessageRenderer: ChildMessageRenderer,
                      srNoRenderer: SrNoRenderer,
                      uniqueIdRenderer: UniqueIdRenderer,
                      profilestatus: ProfileStatusRenderer,
                      nameRenderer: NameRenderer,
                      imageRenderer: ImageRenderer,
                      businessIdRenderer: BusinessIdRenderer,
                      adminidRenderer: AdminidRenderer,
                      aboutRenderer: AboutBusinessRenderer,
                      businessEmailRenderer: BusinessEmailRenderer,
                      mobileNoRenderer: MobileNoRenderer,
                      businessNameRenderer: BusinessNameRenderer,
                      priorityRenderer: PriorityRenderer,
                      statusRenderer: StatusRenderer,
                      classStatusRenderer: ClassStatusRenderer,
                      classTrendingRenderer: ClassTrendingRender,
                      tagsRenderer: TagsRenderer,
                      levelRenderer: LevelRenderer,
                      groupsRenderer: GroupsRenderer,
                      siteTypeRenderer: SiteTypeRenderer,
                      categoryRenderer: CategoryRenderer,
                      creationRenderer: CreationRenderer,
                      updationRenderer: UpdationRenderer,
                      createdByRenderer: CreatedByRenderer,
                      updatedByRenderer: UpdatedByRenderer,
                      emailVerifyRenderer: EmailVerifyRenderer,
                      mobileVerifyRenderer: MobileVerifyRenderer,
                      dropDownRenderer: DropDownRenderer,
                    }}
                    overlayLoadingTemplate={
                      '<span class="ag-overlay-loading-center" style="margin: auto">Please wait while your rows are loading</span>'
                    }
                    overlayNoRowsTemplate={
                      "<span style=\"padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow\">This is a custom 'no rows' overlay</span>"
                    }
                    onGridReady={getbusinessnew}
                  >
                    <AgGridColumn
                      width={90}
                      field="SrNo"
                      cellRenderer="srNoRenderer"
                      sortable={false}
                      filter={false}
                      pinned="left"
                    ></AgGridColumn>
                    <AgGridColumn
                      width={150}
                      field="Actions"
                      cellRenderer="childMessageRenderer"
                      colId="params"
                      sortable={false}
                      filter={false}
                      pinned="left"
                    ></AgGridColumn>
                    <AgGridColumn
                      field="businessName"
                      width={150}
                      cellRenderer="businessNameRenderer"
                      sortable={true}
                      comparator={customLowerCaseComparator}
                      pinned="left"
                    ></AgGridColumn>

                    <AgGridColumn
                      width={150}
                      field="UniqueId"
                      cellRenderer="uniqueIdRenderer"
                      colId="params"
                      sortable={true}
                      valueGetter={uniqueValueGetter}
                      filter={true}
                    ></AgGridColumn>
                    <AgGridColumn
                      field="status"
                      headerName="Permanent Activation/ Deactivation"
                      cellRenderer="statusRenderer"
                      sortable={true}
                      comparator={customLowerCaseComparator}
                      valueGetter={perActiveDeactiveValueGetter}
                      filter={true}
                    ></AgGridColumn>
                    <AgGridColumn
                      headerName="Class Status"
                      field="status"
                      cellRenderer="classStatusRenderer"
                      sortable={true}
                      valueGetter={classStatusValueGetter}
                      comparator={customLowerCaseComparator}
                      filter={true}
                    ></AgGridColumn>
                    <AgGridColumn
                      field="User Status"
                      valueGetter={DropDownValueGetter}
                      cellRenderer="dropDownRenderer"
                      sortable={true}
                      filter={true}
                    ></AgGridColumn>
                    <AgGridColumn
                      headerName="Class Trending"
                      field="trending"
                      valueGetter={classTrendingValueGetter}
                      cellRenderer="classTrendingRenderer"
                      sortable={true}
                      comparator={customLowerCaseComparator}
                      filter={true}
                    ></AgGridColumn>
                    <AgGridColumn
                      field="priority"
                      cellRenderer="priorityRenderer"
                      sortable={true}
                    ></AgGridColumn>
                    <AgGridColumn
                      width={300}
                      field="email"
                      cellRenderer="businessEmailRenderer"
                      valueGetter={emailValueGetter}
                      sortable={true}
                      comparator={customLowerCaseComparator}
                    ></AgGridColumn>
                    <AgGridColumn
                      field="mobileNo"
                      cellRenderer="mobileNoRenderer"
                      valueGetter={mobileNoValueGetter}
                      sortable={true}
                      comparator={customLowerCaseComparator}
                    ></AgGridColumn>
                    <AgGridColumn
                      width={100}
                      field="ContactPerson"
                      cellRenderer="nameRenderer"
                      valueGetter={nameValueGetter}
                      sortable={true}
                      comparator={customLowerCaseComparator}
                    ></AgGridColumn>
                    <AgGridColumn
                      field="Email Verify"
                      cellRenderer="emailVerifyRenderer"
                      sortable={true}
                      filter={true}
                      valueGetter={emailVerifyValueGetter}
                    ></AgGridColumn>
                    <AgGridColumn
                      field="Mobile Verify"
                      cellRenderer="mobileVerifyRenderer"
                      sortable={true}
                      filter={true}
                      valueGetter={mobileVerifyValueGetter}
                    ></AgGridColumn>
                    <AgGridColumn
                      width={150}
                      field="ProfileStatus"
                      cellRenderer="profilestatus"
                      colId="params"
                      sortable={true}
                      valueGetter={profileValueGetter}
                      filter={true}
                    ></AgGridColumn>
                    <AgGridColumn
                      width={100}
                      headerName="Banner Image"
                      field="image"
                      cellRenderer="imageRenderer"
                      sortable={true}
                    ></AgGridColumn>
                    <AgGridColumn
                      field="tags"
                      cellRenderer="tagsRenderer"
                      sortable={true}
                      valueGetter={tagsValueGetter}
                      comparator={customLowerCaseComparator}
                      filter={true}
                    ></AgGridColumn>
                    <AgGridColumn
                      headerName="Subject"
                      field="businesssubCategory"
                      cellRenderer="categoryRenderer"
                      sortable={true}
                      comparator={customLowerCaseComparator}
                      valueGetter={categoryValueGetter}
                      filter={true}
                    ></AgGridColumn>
                    <AgGridColumn
                      field="Category"
                      cellRenderer="levelRenderer"
                      sortable={true}
                      valueGetter={levelValueGetter}
                      comparator={customLowerCaseComparator}
                      filter={true}
                    ></AgGridColumn>
                    <AgGridColumn
                      field="groups"
                      cellRenderer="groupsRenderer"
                      sortable={true}
                      valueGetter={groupValueGetter}
                      comparator={customLowerCaseComparator}
                      filter={true}
                    ></AgGridColumn>
                    <AgGridColumn
                      field="class Type"
                      cellRenderer="siteTypeRenderer"
                      sortable={true}
                      valueGetter={siteTypeValueGetter}
                      comparator={customLowerCaseComparator}
                      filter={true}
                    ></AgGridColumn>
                    <AgGridColumn
                      field="ageto"
                      sortable={true}
                      comparator={customLowerCaseComparator}
                      filter={true}
                    ></AgGridColumn>
                    <AgGridColumn
                      field="agefrom"
                      sortable={true}
                      comparator={customLowerCaseComparator}
                      filter={true}
                    ></AgGridColumn>
                    <AgGridColumn
                      width={100}
                      headerName="About Us"
                      cellRenderer="aboutRenderer"
                      field="aboutBusiness"
                      sortable={true}
                      filter={true}
                    ></AgGridColumn>
                    <AgGridColumn
                      field="createdOn"
                      headerName="Created On"
                      cellRenderer="creationRenderer"
                      sortable={true}
                      valueGetter={createdOnValueGetter}
                      comparator={customLowerCaseComparator}
                      filter={true}
                    ></AgGridColumn>
                    <AgGridColumn
                      field="updatedOn"
                      headerName="Updated On"
                      cellRenderer="updationRenderer"
                      sortable={true}
                      valueGetter={updatedOnValueGetter}
                      comparator={customLowerCaseComparator}
                      filter={true}
                    ></AgGridColumn>
                    <AgGridColumn
                      field="CreatedBy"
                      cellRenderer="createdByRenderer"
                      sortable={true}
                      comparator={customLowerCaseComparator}
                      filter={true}
                      valueGetter={createdbyValueGetter}
                    ></AgGridColumn>
                    <AgGridColumn
                      field="UpdatedBy"
                      cellRenderer="updatedByRenderer"
                      sortable={true}
                      comparator={customLowerCaseComparator}
                      filter={true}
                      valueGetter={updatedbyValueGetter}
                    ></AgGridColumn>
                    <AgGridColumn
                      field="webAddress"
                      sortable={true}
                      comparator={customLowerCaseComparator}
                      filter={true}
                    ></AgGridColumn>
                    <AgGridColumn
                      width={100}
                      headerName="Admin ID"
                      field="adminid"
                      cellRenderer="adminidRenderer"
                      sortable={true}
                      filter={true}
                      valueGetter={adminIdValueGetter}
                    ></AgGridColumn>
                    <AgGridColumn
                      width={100}
                      headerName="Business ID"
                      field="businessid"
                      cellRenderer="businessIdRenderer"
                      valueGetter={businessIdValueGetter}
                      filter={true}
                      sortable={true}
                    ></AgGridColumn>
                    <AgGridColumn
                      width={100}
                      headerName="Role identifier"
                      field="roleIdentifier"
                      sortable={true}
                    ></AgGridColumn>
                  </AgGridReact>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {duplicatedatastatus && (
        <div className="page-wrapper">
          <div className="container-fluid ">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">
                  {updateuploadStatus == "Update"
                    ? "Updated Business Error Log"
                    : "Business Data Upload Error Log "}
                  <div className="float-end btns_head d-flex">
                    <button
                      className="btn btn-theme btn-sm"
                      onClick={() => {
                        setduplicatedatastatus(false);
                        setLoader(false);
                        setUploadIsOpenStatusModal(false);
                      }}
                    >
                      Back
                    </button>
                    <JsonDownload
                      title="Download Excel"
                      fileName="errorlog-business-excel"
                      downloadfile={duplicateData}
                      btnClassName={"btn btn-theme btn-sm"}
                      btnColor={"#f9560f"}
                    />
                  </div>
                </h4>

                <div
                  style={{ width: "100%" }}
                  className="ag-theme-alpine tableFix"
                >
                  <AgGridReact
                    style={{ width: "100%", height: "100%;" }}
                    rowHeight={rowHeight}
                    rowData={duplicateData}
                    defaultColDef={defaultColDef}
                    enableRangeSelection={true}
                    rowSelection={"multiple"}
                    enableCellTextSelection={true}
                    ensureDomOrder={true}
                    copySelectedRowsToClipboard={true}
                    copyHeadersToClipboard={true}
                    copySelectedRangeToClipboard={true}
                    sendToClipboard={sendToClipboard}
                    // domLayout="autoHeight"
                    frameworkComponents={{
                      srNoRenderer: SrNoRenderer,
                    }}
                  >
                    {updateuploadStatus == "Update" && (
                      <AgGridColumn
                        width={90}
                        field="SrNo"
                        cellRenderer="srNoRenderer"
                        sortable={false}
                        filter={false}
                      ></AgGridColumn>
                    )}
                    {updateuploadStatus == "Upload" && (
                      <AgGridColumn
                        width={90}
                        field="sr"
                        sortable={false}
                        filter={false}
                      ></AgGridColumn>
                    )}

                    {updateuploadStatus == "Update" && (
                      <AgGridColumn
                        width={90}
                        headerName="Admin Id"
                        field="Admin_id"
                        sortable={false}
                        filter={false}
                      ></AgGridColumn>
                    )}
                    {updateuploadStatus == "Update" && (
                      <AgGridColumn
                        width={90}
                        field="classid"
                        sortable={false}
                        filter={false}
                      ></AgGridColumn>
                    )}

                    {SuccessfullUpdateStatus && (
                      <AgGridColumn
                        width={150}
                        field="errorName"
                        sortable={false}
                        filter={false}
                      ></AgGridColumn>
                    )}

                    <AgGridColumn
                      width={100}
                      field="name"
                      valueGetter={duplicateNameValueGetter}
                      sortable={true}
                      comparator={customLowerCaseComparator}
                    ></AgGridColumn>

                    <AgGridColumn
                      field="businessName"
                      width={150}
                      sortable={true}
                      comparator={customLowerCaseComparator}
                    ></AgGridColumn>
                    <AgGridColumn
                      // width={100}
                      headerName="Role identifier"
                      field="roleIdentifier"
                      sortable={true}
                      filter={true}
                    ></AgGridColumn>

                    <AgGridColumn
                      field="Permanent_activation"
                      headerName="Permanent Activation/ Deactivation"
                      sortable={true}
                      filter={true}
                    ></AgGridColumn>
                    <AgGridColumn
                      headerName="Class Status"
                      field="classstatus"
                      sortable={true}
                      filter={true}
                    ></AgGridColumn>
                    <AgGridColumn
                      width={300}
                      field="email"
                      cellRenderer="businessEmailRenderer"
                      valueGetter={duplicateEmailValueGetter}
                      sortable={true}
                      comparator={customLowerCaseComparator}
                    ></AgGridColumn>

                    <AgGridColumn
                      field="mobileNo"
                      cellRenderer="mobileNoRenderer"
                      valueGetter={duplicateMobileNoValueGetter}
                      sortable={true}
                      comparator={customLowerCaseComparator}
                    ></AgGridColumn>
                    <AgGridColumn
                      field="tags"
                      sortable={true}
                      comparator={customLowerCaseComparator}
                      filter={false}
                    ></AgGridColumn>

                    <AgGridColumn
                      field="level"
                      sortable={true}
                      comparator={customLowerCaseComparator}
                      filter={false}
                    ></AgGridColumn>
                    <AgGridColumn
                      field="groupsName"
                      sortable={true}
                      comparator={customLowerCaseComparator}
                      filter={false}
                    ></AgGridColumn>

                    <AgGridColumn
                      field="ageto"
                      sortable={true}
                      comparator={customLowerCaseComparator}
                      filter={false}
                    ></AgGridColumn>
                    <AgGridColumn
                      field="agefrom"
                      sortable={true}
                      comparator={customLowerCaseComparator}
                      filter={false}
                    ></AgGridColumn>
                    <AgGridColumn
                      field="webAddress"
                      sortable={true}
                      comparator={customLowerCaseComparator}
                      filter={false}
                    ></AgGridColumn>
                    <AgGridColumn
                      headerName="class Type"
                      field="sitetype"
                      sortable={true}
                      comparator={customLowerCaseComparator}
                      filter={false}
                    ></AgGridColumn>

                    <AgGridColumn
                      headerName="Category"
                      field="businesssubCategory"
                      sortable={true}
                      comparator={customLowerCaseComparator}
                      filter={false}
                    ></AgGridColumn>

                    <AgGridColumn
                      field="gender"
                      sortable={true}
                      comparator={customLowerCaseComparator}
                      filter={false}
                    ></AgGridColumn>

                    <AgGridColumn
                      field="country"
                      sortable={true}
                      comparator={customLowerCaseComparator}
                      filter={false}
                    ></AgGridColumn>

                    {updateuploadStatus == "Update" && (
                      <AgGridColumn
                        field="DOB"
                        sortable={true}
                        comparator={customLowerCaseComparator}
                        filter={false}
                      ></AgGridColumn>
                    )}
                    {updateuploadStatus == "Upload" && (
                      <AgGridColumn
                        field="DOB"
                        sortable={true}
                        comparator={customLowerCaseComparator}
                        filter={false}
                      ></AgGridColumn>
                    )}

                    <AgGridColumn
                      headerName="address"
                      field="address2"
                      sortable={true}
                      comparator={customLowerCaseComparator}
                      filter={false}
                    ></AgGridColumn>
                    <AgGridColumn
                      field="aboutBusiness"
                      sortable={true}
                      comparator={customLowerCaseComparator}
                      filter={false}
                    ></AgGridColumn>
                  </AgGridReact>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Business;
