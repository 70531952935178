import React, { useState, useMemo, useEffect, useRef } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import axios from "axios";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "./Groups.css";
import Select from "react-select";
import Updategroup from "./Updategroups";
import JoditEditor from "jodit-react";
import { MdModeEditOutline } from "react-icons/md";
import { AiFillDelete } from "react-icons/ai";
import Footer from "../Footer/footer";
import Sidebar from "../Sidebar/sidebar";
import Header from "../Header/header";
import { AiFillEye } from "react-icons/ai";
import ToggleButton from "../../components/Toggle_Button/ToggleButton";
import Modal_Popup from "../../components/modal_renderer/Modal_Popup";
import Compressor from "compressorjs";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File

function GroupsData() {
  const defaultFonts = [
    "Arial",
    "Comic Sans MS",
    "Courier New",
    "Impact",
    "Georgia",
    "Tahoma",
    "Trebuchet MS",
    "Verdana",
  ];
  const sortedFontOptions = [
    "Logical",
    "Salesforce Sans",
    "Garamond",
    "Sans-Serif",
    "Serif",
    "Times New Roman",
    "Helvetica",
    ...defaultFonts,
  ].sort();
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const config = {
    readonly: false,
    height: 400,
  };
  const [UpdateGroupData, setUpdateGroupData] = useState("");
  const [DeleteGroupId, setDeleteGroupId] = useState("");
  const [DetailTagsId, setDetailTagsId] = useState([]);
  const [groupImage, setGroupImage] = useState("");
  const [sendGroupImage, setSendGroupImage] = useState("");
  const [groups, setGroups] = useState("");
  const [rowData, setRowData] = useState(null);
  const [options, setoptions] = useState([]);
  const [selectedOptionone, setselectedOptionone] = useState([]);
  const [selectedOptionTagLabel, setSelectedOptionTagLabel] = useState([]);
  const [group, setGroup] = useState([]); // THIS STATE IS USED FOR "GROUP" PARAMETER FOR SECTION API
  const [groupsName, setGroupsName] = useState([]); // THIS STATE IS USED FOR "GROUPSNAME" PARAMETER FOR SECTION API
  const pagination = true;
  const paginationPageSize = 10;
  const rowHeight = 55;

  useEffect(() => {
    getnewgroup();
    getnewtag();
  }, []);

  //----- GET GROUP API --------//

  function addnewgroup() {
    selectedOptionTagLabel.map((item) => {
      groupsName.push(item);
    });

    selectedOptionone.map((item) => {
      group.push(item);
    });

    let data = new FormData();
    data.append("tags", selectedOptionTagLabel);
    data.append("groupDescription", content);
    data.append("groups", groups);
    data.append("image", sendGroupImage);
    data.append("group", group);
    data.append("groupsName", groupsName);

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/addgroups`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        getnewgroup();
      })
      .catch(function (error) {
        console.log(error, "error");
      });
  }

  //----- GET GROUP API --------//
  function getnewgroup() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getgroups`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
      },
    };

    axios(config)
      .then((response) => {
        let data = JSON.stringify(response.data.data);
        setRowData(JSON.parse(data));
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  //----- GET TAG API --------//

  function getnewtag() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/gettag`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        response.data.tagData.map((tag, index) => {
          options.push({ value: tag._id, label: tag.tags });
        });
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  const handlechangetag = (event) => {
    let array = [];
    let arrayLabel = [];
    event.forEach((value) => {
      array.push(value.value);
    });
    event.forEach((option) => {
      arrayLabel.push(option.label);
    });
    setselectedOptionone(array);
    setSelectedOptionTagLabel(arrayLabel);
  };

  function deleteDataGroup(id) {
    let data = JSON.stringify({
      groupsId: id,
    });

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/deletegroups`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        getnewgroup();
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  const SrNoRenderer = (props) => {
    return <span className="profle_img_box">{props.rowIndex + 1}</span>;
  };

  const TagsRenderer = (props) => {
    return (
      <span className="profle_img_box">
        <span>{props.data.tags.join(", ")}</span>
      </span>
    );
  };

  const ChildMessageRenderer = (props) => {
    return (
      <div className="iconActionList">
        <div className="editIcon">
          <MdModeEditOutline
            className="ActionIcon"
            onClick={() => {
              setUpdateGroupData(props.data);
            }}
            data-bs-toggle="modal"
            data-bs-target="#UpdateGroupData"
          />
        </div>
        <div className="ViewIcon">
          <AiFillEye
            className="ActionIcon"
            onClick={() => {
              setDetailTagsId(props.data);
            }}
            data-bs-toggle="modal"
            data-bs-target="#DetailTagsData"
          />
        </div>
        <div className="DeleteIcon">
          <AiFillDelete
            className="ActionIcon"
            onClick={() => {
              setDeleteGroupId(props.data._id);
            }}
            data-bs-toggle="modal"
            data-bs-target="#DeleteGroupData"
          />
        </div>
      </div>
    );
  };

  const TrendingRender = (props) => {
    return (
      <ToggleButton
        handleToggle={() => {
          changeGroupTrendingStatus(props?.data?._id);
        }}
        status={props?.data?.trending}
      />
    );
  };

  function changeGroupStatus(groupId) {
    let axios = require("axios");
    let data = JSON.stringify({
      groupId: groupId,
    });

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/changegrouptatus`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        getnewgroup();
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  function changeGroupTrendingStatus(groupId) {
    let data = JSON.stringify({
      groupId: groupId,
    });
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/changgrouptrendingstatus`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        getnewgroup();
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  const StatusRenderer = (props) => {
    let status = props?.data?.status ? "deactivate" : "activate";
    let message = "Are you sure you want to " + status + " this group?";
    return (
      <Modal_Popup
        status={props?.data?.status}
        onClick={() => {
          changeGroupStatus(props?.data?._id);
        }}
        message={message}
      />
    );
  };

  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      resizable: true,
      filter: true,
      getQuickFilterText: (params) => {
        return params.value.name;
      },
      editable: true,
    };
  }, []);

  //----------------FUNCTION TO SET IMAGES THAT ARE TO BE SENT----------------------//

  const handleGroupImage = (event) => {
    if (event.target.files[0]) {
      let file = event.target.files[0];
      new Compressor(file, {
        quality: 0.2, // 0.6 can also be used, but its not recommended to go below.
        success: (compressedResult) => {
          // compressedResult has the compressed file.
          // Use the compressed file to upload the images to your server.
          // let display = URL.createObjectURL(compressedResult);
          // setAuthorImage(display);
          // setSendAuthorImage(compressedResult);
          let display = URL.createObjectURL(compressedResult);
          setGroupImage(display);
          setSendGroupImage(compressedResult);
        },
      });
    }
  };

  //----------------FUNCTION TO RENDER IMAGES----------------------//

  const renderImages = (image) => {
    return (
      <img
        alt="renderImages"
        style={{ width: "110px", height: "140px" }}
        src={image}
        key={image}
      />
    );
  };

  const customLowerCaseComparator = (valueA, valueB) => {
    if (typeof valueA === "string") {
      return valueA?.toLowerCase().localeCompare(valueB?.toLowerCase());
    }
    return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
  };

  const tagsValueGetter = (params) => {
    return params.data.tags.map((item) => {
      return item;
    });
  };

  const tagvalue =
    DetailTagsId?.tags?.length > 0
      ? DetailTagsId?.tags?.join(",")
      : "No Tags Available";
  return (
    <>
      <div className="page-wrapper">
        <div className="container-fluid ">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">
                Groups
                <div className="float-end btns_head">
                  <button
                    className="btn btn-theme btn-sm"
                    data-bs-toggle="modal"
                    data-bs-target="#createGroup"
                  >
                    Add New Group
                  </button>
                </div>
              </h4>
              <div>
                <div
                  className="modal fade"
                  id="createGroup"
                  tabIndex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                          Add Group
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-body">
                        <div className="row">
                          <div className="col-md-12 mb-3">
                            <label className="form-label">Add Group </label>
                            <div className="position-relative">
                              <input
                                type="text"
                                value={groups}
                                className="form-control"
                                onChange={(e) => {
                                  setGroups(e.target.value);
                                }}
                              />
                              <div
                                className="hint_box"
                                style={{ display: "block" }}
                              ></div>
                            </div>
                          </div>

                          <div className="col-md-12 mb-3">
                            <label className="form-label">Group Image</label>
                            <ul className="imagesUpload">
                              <li style={{ width: "100%", height: "148px" }}>
                                <input
                                  accept="image/*,capture=camera"
                                  capture="”camera"
                                  type="file"
                                  onChange={handleGroupImage}
                                />
                                {groupImage.length === 0 ? (
                                  <img
                                    alt="renderImages"
                                    style={{ width: "100%", height: "148px" }}
                                    src="../../images/defalutimg.svg"
                                  />
                                ) : (
                                  renderImages(groupImage)
                                )}
                              </li>
                            </ul>
                          </div>

                          <div className="col-md-12 mb-3">
                            <label className="form-label">
                              Group Description
                            </label>
                            <div className="App">
                              <SunEditor
                                width="100%"
                                plugin=""
                                height="100%"
                                onChange={(event) => setContent(event)}
                                setOptions={{
                                  buttonList: [
                                    ["undo", "redo"],
                                    ["font", "fontSize", "formatBlock"],
                                    ["paragraphStyle", "blockquote"],
                                    [
                                      "bold",
                                      "underline",
                                      "italic",
                                      "strike",
                                      "subscript",
                                      "superscript",
                                    ],
                                    ["fontColor", "hiliteColor"],
                                    ["align", "list", "lineHeight"],
                                    ["outdent", "indent"],
                                    ["fullScreen", "showBlocks", "codeView"],
                                    ["preview", "print"],
                                    ["removeFormat"],

                                    [
                                      ":i-More Misc-default.more_vertical",
                                      "image",
                                      "video",
                                      "audio",
                                      "link",
                                      "fullScreen",
                                      "showBlocks",
                                      "codeView",
                                      "preview",
                                      "print",
                                      "save",
                                      "template",
                                    ],
                                    [
                                      ":r-More Rich-default.more_plus",
                                      "table",
                                      "horizontalRule",
                                      "imageGallery",
                                    ],
                                  ],
                                  defaultTag: "div",
                                  minHeight: "300px",
                                  showPathLabel: false,
                                  font: sortedFontOptions,
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-md-12 mb-3">
                            <label className="form-label">Select Tags</label>
                            <Select
                              isMulti
                              options={options}
                              onChange={(e) => handlechangetag(e)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-danger CancelBtn"
                          data-bs-dismiss="modal"
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          onClick={addnewgroup}
                          className="btn submitBtn"
                          data-bs-dismiss="modal"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="modal fade"
                id="UpdateCategoriesData"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              ></div>
              <div
                className="modal fade DeletePopup"
                id="DeleteGroupData"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog  modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="">
                            <p>Are you sure you want to delete this group</p>
                            <button
                              type="button"
                              className="btn btn-danger CancelBtn"
                              data-bs-dismiss="modal"
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              onClick={() => {
                                deleteDataGroup(DeleteGroupId);
                              }}
                              className="btn submitBtn"
                              data-bs-dismiss="modal"
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="modal fade"
                id="UpdateGroupData"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-scrollable">
                  <div className="modal-content">
                    <Updategroup
                      getGroups={getnewgroup}
                      updatedData={UpdateGroupData}
                    />
                  </div>
                </div>
              </div>
              <div
                className="modal fade"
                id="DetailTagsData"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-scrollable">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-12">
                          <div className="card">
                            <div className="card-body border">
                              <div className="profile_box">
                                <div className="profile_box_body">
                                  <tr>
                                    <th>Groups :</th>
                                    <td>{DetailTagsId?.groups}</td>
                                  </tr>
                                  <tr>
                                    <th>Tags :</th>
                                    <td>{tagvalue}</td>
                                  </tr>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{ width: "100%" }}
                className="ag-theme-alpine tableFix"
              >
                <AgGridReact
                  rowHeight={rowHeight}
                  rowData={rowData}
                  defaultColDef={defaultColDef}
                  frameworkComponents={{
                    childMessageRenderer: ChildMessageRenderer,
                    tagsRenderer: TagsRenderer,
                    statusRenderer: StatusRenderer,
                    srNoRenderer: SrNoRenderer,
                    TrendingRender: TrendingRender,
                  }}
                >
                  <AgGridColumn
                    width={90}
                    field="SrNo"
                    Srno={true}
                    sortable={false}
                    filter={false}
                    cellRenderer="srNoRenderer"
                    pinned="left"
                  ></AgGridColumn>
                  <AgGridColumn
                    width={300}
                    field="Action"
                    cellRenderer="childMessageRenderer"
                    colId="params"
                    sortable={false}
                    pinned="left"
                    filter={false}
                  ></AgGridColumn>

                  <AgGridColumn
                    width={260}
                    cellRenderer="GroupRender"
                    field="groups"
                    sortable={true}
                    comparator={customLowerCaseComparator}
                    pinned="left"
                    // floatingFilter={true}
                  ></AgGridColumn>
                  <AgGridColumn
                    width={260}
                    cellRenderer="tagsRenderer"
                    valueGetter={tagsValueGetter}
                    headerName="Tags"
                    field="tags"
                    sortable={true}
                    comparator={customLowerCaseComparator}
                  ></AgGridColumn>
                  <AgGridColumn
                    width={300}
                    field="status"
                    cellRenderer="statusRenderer"
                    colId="params"
                    sortable={true}
                    comparator={customLowerCaseComparator}
                    filter={true}
                  ></AgGridColumn>
                  <AgGridColumn
                    cellRenderer="TrendingRender"
                    field="trending"
                    sortable={true}
                    comparator={customLowerCaseComparator}
                    filter={true}
                  ></AgGridColumn>
                </AgGridReact>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default GroupsData;
