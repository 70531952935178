import React, { useState, useMemo } from "react";
import Footer from "../Footer/footer";
import Header from "../Header/header";
import Sidebar from "../Sidebar/sidebar";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import JsonDownload from "../../components/downloadjson";
import { useLocation } from "react-router-dom";

function DuplicateBranch() {
  let location = useLocation();
  const rowheight = 55;
  const defaultcoldef = useMemo(() => {
    return {
      sortable: true,
      resizable: true,
      filter: true,
      getQuickFilterText: (params) => {
        return params.value.name;
      },
      editable: true,
    };
  }, []);

  const SrNoRenderer = (props) => {
    return <span>{props.node.rowIndex + 1}</span>;
  };
  const BusinessnameRenderer = (props) => {
    return <span>{props.data.admin_id?.businessName}</span>;
  };
  const BranchnameRenderer = (props) => {
    return <span>{props.data.branchName}</span>;
  };
  const AddressRenderer = (props) => {
    return <span>{props.data.address1}</span>;
  };
  const EmailRenderer = (props) => {
    return <span>{props.data.businessemail}</span>;
  };
  const PhoneRenderer = (props) => {
    return <span>{props.data.businessmobileNo}</span>;
  };
  const BranchadminnameRenderer = (props) => {
    return <span>{props.data.admin_id?.name}</span>;
  };
  const BranchadminemailRenderer = (props) => {
    return <span>{props.data.admin_id?.email}</span>;
  };
  const BranchadminphoneRenderer = (props) => {
    return <span>{props.data.admin_id?.mobileNo}</span>;
  };

  const businessnameValueGetter = (params) => {
    return params.data.admin_id?.businessName;
  };
  const branchnameValueGetter = (params) => {
    return params.data.branchName;
  };
  const addressValueGetter = (params) => {
    return params.data.address1;
  };
  const emailValueGetter = (params) => {
    return params.data.businessemail;
  };
  const phoneValueGetter = (params) => {
    return params.data.businessmobileNo;
  };
  const branchadminnameValueGetter = (params) => {
    return params.data.admin_id?.name;
  };
  const branchadminemailValueGetter = (params) => {
    return params.data.admin_id?.email;
  };
  const branchadminphoneValueGetter = (params) => {
    return params.data.admin_id?.mobileNo;
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">
                Duplicate Branch
                <div className="float-end btns_head">
                  {/* <button className="btn btn-theme btn-sm" onClick={() => { setUploadIsOpen(true) }}>Upload CSV</button> */}
                  <JsonDownload
                    title="Download Excel"
                    fileName="errorlog-branch-excel"
                    downloadfile={location.state.details}
                    btnClassName={"btn btn-theme btn-sm"}
                    btnColor={"#f9560f"}
                  />
                </div>
              </h4>
              <div
                style={{ width: "100%" }}
                className="ag-theme-alpine tableFix"
              >
                <AgGridReact
                  rowHeight={rowheight}
                  rowData={location.state.details}
                  defaultColDef={defaultcoldef}
                  frameworkComponents={{
                    srnoRenderer: SrNoRenderer,
                    businessnameRenderer: BusinessnameRenderer,
                    branchnameRenderer: BranchnameRenderer,
                    addressRenderer: AddressRenderer,
                    emailRenderer: EmailRenderer,
                    phoneRenderer: PhoneRenderer,
                    branchadminnameRenderer: BranchadminnameRenderer,
                    branchadminemailRenderer: BranchadminemailRenderer,
                    branchadminphoneRenderer: BranchadminphoneRenderer,
                  }}
                >
                  <AgGridColumn
                    field="SrNo"
                    width={100}
                    cellRenderer="srnoRenderer"
                    sortable={false}
                    filter={false}
                    pinned="left"
                  ></AgGridColumn>
                  <AgGridColumn
                    field="businessname"
                    width={100}
                    cellRenderer="businessnameRenderer"
                    sortable={true}
                    filter={true}
                    valueGetter={businessnameValueGetter}
                    pinned="left"
                  ></AgGridColumn>
                  <AgGridColumn
                    field="branchname"
                    width={100}
                    cellRenderer="branchnameRenderer"
                    sortable={true}
                    filter={true}
                    valueGetter={branchnameValueGetter}
                    pinned="left"
                  ></AgGridColumn>
                  <AgGridColumn
                    field="address"
                    width={100}
                    cellRenderer="addressRenderer"
                    sortable={true}
                    filter={true}
                    valueGetter={addressValueGetter}
                    pinned="left"
                  ></AgGridColumn>
                  <AgGridColumn
                    field="email"
                    width={200}
                    cellRenderer="emailRenderer"
                    sortable={true}
                    filter={true}
                    valueGetter={emailValueGetter}
                  ></AgGridColumn>
                  <AgGridColumn
                    field="phone"
                    width={100}
                    cellRenderer="phoneRenderer"
                    sortable={true}
                    filter={true}
                    valueGetter={phoneValueGetter}
                  ></AgGridColumn>
                  <AgGridColumn
                    field="branchadminname"
                    width={100}
                    cellRenderer="branchadminnameRenderer"
                    sortable={true}
                    filter={true}
                    valueGetter={branchadminnameValueGetter}
                  ></AgGridColumn>
                  <AgGridColumn
                    field="branchadminemail"
                    width={100}
                    cellRenderer="branchadminemailRenderer"
                    sortable={true}
                    filter={true}
                    valueGetter={branchadminemailValueGetter}
                  ></AgGridColumn>
                  <AgGridColumn
                    field="branchadminphone"
                    width={100}
                    cellRenderer="branchadminphoneRenderer"
                    sortable={true}
                    filter={true}
                    valueGetter={branchadminphoneValueGetter}
                  ></AgGridColumn>
                </AgGridReact>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default DuplicateBranch;
