import React, { useMemo, useState, useEffect, Fragment } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import axios from "axios";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import UpdateStudents from "./UpdateStudents";
import "./Students.css";
import { MdModeEditOutline } from "react-icons/md";
import { AiFillDelete } from "react-icons/ai";
import Header from "../Header/header";
import Sidebar from "../Sidebar/sidebar";
import Footer from "../Footer/footer";
import { AiFillEye } from "react-icons/ai";
import Modal_Popup from "../../components/modal_renderer/Modal_Popup";
import { useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import JsonDownload from "../../components/downloadjson";
import isdownloadUtils from "../../components/utilDownloadRoles";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import LoadingSpinner from "../../components/loader";

function Students() {
  const [UpdateStudentData, setUpdateCategoriesData] = useState({});
  const [DeleteDeleteId, setDeleteDeleteId] = useState("");
  const [Viewuser, setViewuser] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [value, setValue] = useState();
  const rowHeight = 55;
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({ mode: "onBlur" });
  const [isOpen, setIsOpen] = useState(false);
  const [isCountryCode, setcuntrycode] = useState("");
  const [isDialCode, setDialCode] = useState("");
  const [mobileNo, setmobileNo] = useState("");

  const MoodRenderer = (props) => {
    return (
      <span className="profle_img_box">
        <span>{props.data}</span>
      </span>
    );
  };

  function somefun() {
    setIsOpen(false);
    setUploadIsOpen(false);
    setUploadIsOpenStatusModal(false);
    setEmail_alreay_exist([]);
    cancelForm();
    cross();
  }
  function fun() {
    setIsOpen(false);
    setUploadIsOpen(false);
    setLoader(false);
    setEmail_alreay_exist([]);
    cross();
  }
  function cancelForm() {
    reset();
    setValue();
  }
  function cross() {
    reset();
    setLoader(false);
    setEmail_alreay_exist([]);
    setValue();
  }
  const SrNoRenderer = (props) => {
    return (
      <>
        <span>{props.node.rowIndex + 1}</span>
      </>
    );
  };
  const DatecreatedByRenderer = (params) => {
    return <span>{params?.data?.DateOfSignup?.split("T")[0]}</span>;
  };

  const ChildMessageRenderer = (props) => {
    return (
      <div className="iconActionList">
        <div
          className="editIcon"
          onClick={() => {
            setUpdateCategoriesData(props.data);
          }}
          data-bs-toggle="modal"
          data-bs-target="#UpdateStudentsData"
        >
          <MdModeEditOutline className="ActionIcon viewicon" />
        </div>

        <div
          className="ViewIcon"
          onClick={() => {
            setViewuser(props.data);
          }}
          data-bs-toggle="modal"
          data-bs-target="#UserViewId"
        >
          <AiFillEye alt="" src="../../images/view.jpg" />
        </div>
        <div
          className="DeleteIcon"
          onClick={() => {
            setDeleteDeleteId(props.data._id);
          }}
          data-bs-toggle="modal"
          data-bs-target="#DeleteStudentData"
        >
          <AiFillDelete className="ActionIcon" />
        </div>
      </div>
    );
  };

  // ------Post API-------//
  function addstudentnew(postdata) {
    setIsOpen(false);

    let data = JSON.stringify({
      name: postdata.name,
      email: postdata.email ? postdata.email : "",
      lat: 0,
      long: 0,
      mobileNo: mobileNo,
      countryCode: isCountryCode,
      // dialCode: isDialCode,
      type: "student",
    });

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/register`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        getStudents();
        reset();
        setValue();
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  useEffect(() => {
    getStudents();
  }, []);

  const [downloaddata, setDownloadData] = useState([]);

  function getStudents() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getstudent`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        let download = [];
        response.data?.data?.map((element, index) => {
          download.push({
            _id: element?._id,
            name: element?.name,
            email: element?.email,
            countryCode: element?.countryCode,
            mobileNo: element?.mobileNo,
            IP_address: element?.IP_address,
            DateOfSignup: element?.DateOfSignup?.split("T")[0],
            DeviceOfSignup: element?.DeviceOfSignup,
            LocationOfSignup: element?.LocationOfSignup,
            SingupSoure: element?.SingupSoure,
            extraone: element?.extraone,
            extratwo: element?.extratwo,
            extrathree: element?.extrathree,
            extrafour: element?.extrafour,
            extrafive: element?.extrafive,
          });
        });
        setDownloadData(download);
        setRowData(response.data.data);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  // ------ Delete API -------//

  function studentdeleteData(index) {
    let data = JSON.stringify({
      studentId: index,
    });
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/deletestudent`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        getStudents();
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  const defaultColDef = useMemo(() => {
    return {
      sortingoder: "desc",
      sortable: true,
      resizable: true,
      filter: true,
      getQuickFilterText: (params) => {
        return params.value.name;
      },
      editable: true,
    };
  }, []);

  const editdataReloadFunc = () => {
    getStudents();
  };

  const StatusRenderer = (props) => {
    let status = props?.data?.status ? "deactivate" : "activate";
    let message = "Are you sure you want to " + status + " this user?";
    return (
      <Modal_Popup
        status={props?.data?.status}
        onClick={() => {
          changeStudentStatus(props?.data?._id);
        }}
        message={message}
      />
    );
  };

  function changeStudentStatus(userId) {
    let data = JSON.stringify({
      userId: userId,
    });

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/changestudentprofilestatus`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        getStudents();
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  const customLowerCaseComparator = (valueA, valueB) => {
    if (typeof valueA === "string") {
      return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
    }
    return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
  };

  function phoneInput(value, data) {
    setmobileNo(value);
    setDialCode(`+${data.dialCode}`);
    setcuntrycode(`${data.countryCode}`);
  }

  // --------- business bulk upload ------------ //
  const {
    register: uploadRegister,
    unregister,
    handleSubmit: handlesubmitUpload,
    formState: { errors: uploaderrors },
  } = useForm({ mode: "onBlur" });
  const [duplicatedatastatus, setduplicatedatastatus] = useState(false);
  const [duplicateData, setDuplicatedata] = useState([]);
  const [uplaodStatus, setUplaodStatus] = useState({
    status: false,
    value: "",
  });
  const [uploadIsOpenStatusModal, setUploadIsOpenStatusModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [updateuploadStatus, setUpdateUploadStatus] = useState("");
  const [SuccessfullUpdateStatus, setSuccessfullUpdateStatus] = useState(true);
  const [uploadIsOpen, setUploadIsOpen] = useState(false);
  const [email_alreay_exist, setEmail_alreay_exist] = useState([]);

  function uploadFiles(postdata) {
    setLoader(true);
    let data = new FormData();
    data.append("attachment", postdata.uploadFile[0]);
    let config = {
      method: "POST",
      url: `${process.env.REACT_APP_BASEURL}/${
        updateuploadStatus == "Upload" ? "userstudentupload" : "studentupdate"
      }`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        // ---------- Upload & Update Business bulk upload response ------------ //
        setUploadIsOpen(false);
        setUploadIsOpenStatusModal(true);
        setUplaodStatus({
          status: response.data.status,
          value: response.data.message,
        });
        setTimeout(() => {
          if (response.data.status) {
            setduplicatedatastatus(true);
            reset();
            getStudents();
            let Duplicateemail = [];
            let blankStatus = [];
            let blankemail = [];
            let blankname = [];
            let blankpassword = [];
            let emailAlreadyExist = [];
            let uploaded = [];
            let wrongEmailFormat = [];
            let wrongstatus = [];
            let WrongcountryCodeformat = [];
            let WrongmobileNoformat = [];
            let WrongUserId = [];
            let updated = [];

            response.data.Duplicateemail &&
              response.data.Duplicateemail.forEach((element) => {
                Duplicateemail.push({
                  ...element,
                  errorName: "Duplicate Email",
                });
              });
            response.data.blankStatus &&
              response.data.blankStatus.forEach((element) => {
                blankStatus.push({
                  ...element,
                  errorName: "Blank Status",
                });
              });
            response.data.blankpassword &&
              response.data.blankpassword.forEach((element) => {
                blankpassword.push({
                  ...element,
                  errorName: "Blank Password",
                });
              });
            response.data.blankemail &&
              response.data.blankemail.forEach((element) => {
                blankemail.push({
                  ...element,
                  errorName: "Blank Email",
                });
              });
            response.data.blankname &&
              response.data.blankname.forEach((element) => {
                blankname.push({ ...element, errorName: "Blank Name" });
              });
            response.data.emailAlreadyExist &&
              response.data.emailAlreadyExist.forEach((element) => {
                emailAlreadyExist.push({
                  ...element,
                  errorName: "Email Already Exist",
                });
              });
            response.data.uploaded &&
              response.data.uploaded.forEach((element) => {
                uploaded.push({
                  ...element,
                  errorName: "uploaded",
                });
              });
            response.data.wrongEmailFormat &&
              response.data.wrongEmailFormat.forEach((element) => {
                wrongEmailFormat.push({
                  ...element,
                  errorName: "Wrong Email Format",
                });
              });
            response.data.wrongstatus &&
              response.data.wrongstatus.forEach((element) => {
                wrongstatus.push({ ...element, errorName: "Wrong Status" });
              });
            response.data.WrongcountryCodeformat &&
              response.data.WrongcountryCodeformat.forEach((element) => {
                WrongcountryCodeformat.push({
                  ...element,
                  errorName: "Wrong Country Code Format",
                });
              });
            response.data.WrongmobileNoformat &&
              response.data.WrongmobileNoformat.forEach((element) => {
                WrongmobileNoformat.push({
                  ...element,
                  errorName: "Wrong Mobile No. Format",
                });
              });
            response.data.WrongUserId &&
              response.data.WrongUserId.forEach((element) => {
                WrongUserId.push({
                  ...element,
                  errorName: "Wrong User Id",
                });
              });
            response.data.updated &&
              response.data.updated.forEach((element) => {
                updated.push({
                  ...element,
                  errorName: "Updated Data",
                });
              });

            const updateBusinessStatus =
              !Duplicateemail.length &&
              !blankStatus.length &&
              !blankemail.length &&
              !blankname.length &&
              !uploaded.length &&
              !wrongEmailFormat.length &&
              !WrongcountryCodeformat.length &&
              !WrongmobileNoformat.length &&
              !WrongUserId.length &&
              !updated.length &&
              !wrongstatus.length;

            if (updateBusinessStatus) {
              setSuccessfullUpdateStatus(false);
            }
            setDuplicatedata([
              ...Duplicateemail,
              ...blankStatus,
              ...blankemail,
              ...blankname,
              ...uploaded,
              ...wrongEmailFormat,
              ...wrongstatus,
              ...blankpassword,
              ...emailAlreadyExist,
              ...WrongcountryCodeformat,
              ...WrongmobileNoformat,
              ...WrongUserId,
              ...updated,
            ]);
          }
        }, 1000);
      })
      .catch(function (error) {
        fun();
        setUploadIsOpenStatusModal(true);
        setUplaodStatus({
          status: false,
          value: "Something went wrong",
        });
      })
      .finally(() => {
        setTimeout(() => {
          setUploadIsOpenStatusModal(false);
        }, 1000);
      });
  }

  const ipAddressValueGetter = (params) => params.data?.IP_address;
  const dateOfSignupValueGetter = (params) =>
    params.data?.DateOfSignup?.split("T")[0];
  const deviceOfSignupValueGetter = (params) => params.data?.DeviceOfSignup;
  const locationOfSignupValueGetter = (params) => params.data?.LocationOfSignup;
  const extrathreeValueGetter = (params) => params.data?.extrathree;

  return (
    <>
      {!duplicatedatastatus && (
        <div className="page-wrapper">
          <div className="container-fluid ">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">
                  Users
                  <div className="float-end btns_head d-flex">
                    {isdownloadUtils("addNewUser") && (
                      <button
                        className="btn btn-theme btn-sm"
                        onClick={() => setIsOpen(true)}
                      >
                        Add New User
                      </button>
                    )}
                    {isdownloadUtils("uploadUsers") && (
                      <button
                        className="btn btn-theme btn-sm"
                        onClick={() => {
                          setUploadIsOpen(true);
                          setUpdateUploadStatus("Upload");
                        }}
                      >
                        Upload Users
                      </button>
                    )}
                    {isdownloadUtils("updateUsers") && (
                      <button
                        className="btn btn-theme btn-sm"
                        onClick={() => {
                          setUploadIsOpen(true);
                          setUpdateUploadStatus("Update");
                        }}
                      >
                        Update Users
                      </button>
                    )}
                    {isdownloadUtils("downloadUsers") && (
                      <JsonDownload
                        title="Download Users"
                        fileName="student-excel"
                        downloadfile={downloaddata}
                        btnClassName={"btn btn-theme btn-sm"}
                        btnColor={"#f9560f"}
                      />
                    )}
                  </div>
                </h4>

                <div>
                  <Modal show={uploadIsOpen} onHide>
                    <Modal.Body>
                      <div
                        tabIndex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <form onSubmit={handlesubmitUpload(uploadFiles)}>
                          <div className="modal-dialog modal-dialog-scrollable">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5
                                  className="modal-title"
                                  id="exampleModalLabel"
                                >
                                  {updateuploadStatus} File
                                </h5>
                                <button
                                  type="button"
                                  className="btn-close"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                  onClick={() => {
                                    fun();
                                  }}
                                  // onClick={resetForm}
                                ></button>
                              </div>
                              <div className="modal-body">
                                <div className="row">
                                  <div className="col-md-12 mb-4">
                                    <label className="form-label">
                                      {" "}
                                      Upload File
                                    </label>
                                    <div className="position-relative">
                                      <input
                                        className="form-control"
                                        type="file"
                                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                        {...uploadRegister("uploadFile", {
                                          required: true,
                                        })}
                                        onChange={(e) => {
                                          unregister("uploadFile");
                                        }}
                                      />
                                      {uploaderrors?.uploadFile?.type ===
                                        "required" && (
                                        <p className="error">
                                          This field is required
                                        </p>
                                      )}
                                      <div
                                        className="hint_box"
                                        style={{ display: "block" }}
                                      ></div>
                                    </div>
                                  </div>
                                  {email_alreay_exist.length > 0 && (
                                    <div className="col-md-12 mb-4">
                                      <label className="form-label">
                                        Email Already Exists
                                      </label>
                                      <ul>
                                        {email_alreay_exist?.map((item) => {
                                          return <li>{item}</li>;
                                        })}
                                      </ul>
                                    </div>
                                  )}
                                </div>
                              </div>
                              {loader && <LoadingSpinner />}
                              <div className="modal-footer">
                                <button
                                  type="button"
                                  className="btn CancelBtn"
                                  data-bs-dismiss="modal"
                                  onClick={() => {
                                    somefun();
                                  }}
                                >
                                  Cancel
                                </button>
                                <button
                                  type="submit"
                                  className="btn submitBtn"
                                  disabled={loader}
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </Modal.Body>
                  </Modal>
                </div>

                <div>
                  <Modal show={isOpen} onHide>
                    <Modal.Body>
                      <div
                        tabIndex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <form onSubmit={handleSubmit(addstudentnew)}>
                          <div className="modal-dialog">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5
                                  className="modal-title"
                                  id="exampleModalLabel"
                                >
                                  Add Users
                                </h5>
                                <button
                                  type="button"
                                  className="btn-close"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                  onClick={() => fun()}
                                ></button>
                              </div>

                              <div className="modal-body">
                                <div className="row">
                                  <div className="col-md-12 mb-3">
                                    <label className="form-label">
                                      User Name
                                    </label>
                                    <div className="position-relative">
                                      <input
                                        className="form-control"
                                        {...register("name", {
                                          required: true,
                                          pattern: /^[A-Za-z ]+$/i,
                                          min: 1,
                                        })}
                                      />
                                      {errors?.name?.type === "pattern" && (
                                        <p className="error">Alphabet only</p>
                                      )}
                                      {errors?.name?.type === "required" && (
                                        <p className="error">
                                          This field is required
                                        </p>
                                      )}
                                      {errors?.name?.type === "min" && (
                                        <p className="error">
                                          This field is required
                                        </p>
                                      )}
                                      <div
                                        className="hint_box"
                                        style={{ display: "block" }}
                                      ></div>
                                    </div>
                                  </div>
                                  <div className="col-md-12 mb-3">
                                    <label className="form-label">
                                      User Email
                                    </label>
                                    <div className="position-relative">
                                      <input
                                        className="form-control"
                                        {...register("email", {
                                          required: true,
                                          pattern:
                                            /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        })}
                                      />
                                      {errors?.email?.type === "pattern" && (
                                        <p className="error">
                                          Invalid email address
                                        </p>
                                      )}
                                      {errors?.email?.type === "required" && (
                                        <p className="error">
                                          This field is required
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-md-12 mb-3">
                                    <label className="form-label">
                                      User Mobile Number
                                    </label>
                                    <div className="position-relative">
                                      <PhoneInput
                                        inputProps={{
                                          name: "phone",
                                          required: true,
                                          autoFocus: true,
                                        }}
                                        onChange={phoneInput}
                                        defaultErrorMessage="It doesn't works, why?"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="modal-footer">
                                <button
                                  onClick={() => somefun()}
                                  type="button"
                                  className="btn btn-danger CancelBtn"
                                  data-bs-dismiss="modal"
                                >
                                  Cancel
                                </button>
                                <button type="submit" className="btn submitBtn">
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </Modal.Body>
                  </Modal>
                </div>

                <div
                  className="modal fade"
                  id="UpdateStudentsData"
                  tabIndex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-scrollable">
                    <div className="modal-content">
                      <UpdateStudents
                        updatedData={UpdateStudentData}
                        onEditDataFunction={editdataReloadFunc}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="modal fade DeletePopup"
                  id="DeleteStudentData"
                  tabIndex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-body">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="">
                              <p>Are you sure you want to delete this User?</p>
                              <button
                                type="button"
                                className="btn btn-danger CancelBtn"
                                data-bs-dismiss="modal"
                              >
                                Cancel
                              </button>
                              <button
                                type="submit"
                                onClick={() => {
                                  studentdeleteData(DeleteDeleteId);
                                }}
                                className="btn submitBtn"
                                data-bs-dismiss="modal"
                              >
                                Delete
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="modal fade"
                  id="UserViewId"
                  tabIndex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-scrollable modal-lg">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                          Users Detail{" "}
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-body">
                        <div className="row">
                          <div className="col-12">
                            <div className="card">
                              <div className="card-body border">
                                <div className="profile_box">
                                  <div className="profile_box_body">
                                    <div>
                                      {" "}
                                      <h6> User Name : {Viewuser.name}</h6>
                                    </div>
                                    <div>
                                      {" "}
                                      <h6> User Email : {Viewuser.email}</h6>
                                    </div>

                                    <div>
                                      {" "}
                                      <h6>
                                        {" "}
                                        User Mobile : {Viewuser.mobileNo}
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-danger CancelBtn"
                          data-bs-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  style={{ width: "100%" }}
                  className="ag-theme-alpine tableFix"
                >
                  <AgGridReact
                    style={{ width: "100%" }}
                    rowHeight={rowHeight}
                    rowData={rowData}
                    defaultColDef={defaultColDef}
                    // paginationAutoPageSize={true}
                    // pagination={true}
                    frameworkComponents={{
                      childMessageRenderer: ChildMessageRenderer,
                      DatecreatedByRenderer: DatecreatedByRenderer,
                      moodRenderer: MoodRenderer,
                      srNoRenderer: SrNoRenderer,
                      statusRenderer: StatusRenderer,
                    }}
                  >
                    <AgGridColumn
                      width={90}
                      field="SrNo"
                      sortable={false}
                      filter={false}
                      cellRenderer="srNoRenderer"
                    ></AgGridColumn>
                    <AgGridColumn
                      width={150}
                      field="Action"
                      cellRenderer="childMessageRenderer"
                      colId="params"
                      sortable={false}
                      filter={false}
                    ></AgGridColumn>

                    <AgGridColumn
                      field="name"
                      sortable={true}
                      comparator={customLowerCaseComparator}
                      filter={true}
                    ></AgGridColumn>
                    <AgGridColumn
                      field="email"
                      sortable={true}
                      comparator={customLowerCaseComparator}
                      filter={true}
                    ></AgGridColumn>
                    <AgGridColumn
                      field="mobileNo"
                      sortable={true}
                      comparator={customLowerCaseComparator}
                      filter={true}
                    ></AgGridColumn>
                    <AgGridColumn
                      field="IP_address"
                      headerName="IP Address"
                      sortable={true}
                      // comparator={customLowerCaseComparator}
                      filter={true}
                      valueFormatter={ipAddressValueGetter}
                    ></AgGridColumn>
                    <AgGridColumn
                      field="DateOfSignup"
                      headerName="Date Created"
                      valueGetter={dateOfSignupValueGetter}
                      sortable={true}
                      cellRenderer="DatecreatedByRenderer"
                      // comparator={customLowerCaseComparator}
                      filter={true}
                    ></AgGridColumn>
                    <AgGridColumn
                      field="DeviceOfSignup"
                      headerName="Device"
                      sortable={true}
                      valueGetter={deviceOfSignupValueGetter}
                      // comparator={customLowerCaseComparator}
                      filter={true}
                    ></AgGridColumn>
                    <AgGridColumn
                      field="LocationOfSignup"
                      headerName="Location"
                      sortable={true}
                      valueGetter={locationOfSignupValueGetter}
                      // comparator={customLowerCaseComparator}
                      filter={true}
                    ></AgGridColumn>
                    <AgGridColumn
                      field="SingupSoure"
                      headerName="Signup Source"
                      sortable={true}
                      comparator={customLowerCaseComparator}
                      filter={true}
                    ></AgGridColumn>

                    <AgGridColumn
                      field="extraone"
                      sortable={true}
                      comparator={customLowerCaseComparator}
                      filter={true}
                    ></AgGridColumn>
                    <AgGridColumn
                      field="extratwo"
                      sortable={true}
                      comparator={customLowerCaseComparator}
                      filter={true}
                    ></AgGridColumn>
                    <AgGridColumn
                      field="extrathree"
                      sortable={true}
                      valueGetter={extrathreeValueGetter}
                      // comparator={customLowerCaseComparator}
                      filter={true}
                    ></AgGridColumn>
                    <AgGridColumn
                      field="extrafour"
                      sortable={true}
                      comparator={customLowerCaseComparator}
                      filter={true}
                    ></AgGridColumn>
                    <AgGridColumn
                      field="extrafive"
                      sortable={true}
                      comparator={customLowerCaseComparator}
                      filter={true}
                    ></AgGridColumn>
                    <AgGridColumn
                      cellRenderer="statusRenderer"
                      field="status"
                      sortable={true}
                    ></AgGridColumn>
                  </AgGridReact>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {duplicatedatastatus && (
        <div className="page-wrapper">
          <div className="container-fluid ">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">
                  {updateuploadStatus == "Update"
                    ? "Updated Users Error Log"
                    : "Users Data Upload Error Log "}
                  <div className="float-end btns_head">
                    <button
                      className="btn btn-theme btn-sm"
                      onClick={() => {
                        setduplicatedatastatus(false);
                        setLoader(false);
                        setUploadIsOpenStatusModal(false);
                      }}
                    >
                      Back
                    </button>
                    <JsonDownload
                      title="Download Excel"
                      fileName="errorlog-student-excel"
                      downloadfile={duplicateData}
                      btnClassName={"btn btn-theme btn-sm"}
                      btnColor={"#f9560f"}
                    />
                  </div>
                </h4>
                <div
                  style={{ width: "100%" }}
                  className="ag-theme-alpine tableFix"
                >
                  <AgGridReact
                    suppressCopyRowsToClipboard={true}
                    rowHeight={rowHeight}
                    rowData={duplicateData}
                    defaultColDef={defaultColDef}
                    frameworkComponents={{
                      moodRenderer: MoodRenderer,
                      srNoRenderer: SrNoRenderer,
                      statusRenderer: StatusRenderer,
                    }}
                  >
                    <AgGridColumn
                      width={90}
                      field="SrNo"
                      sortable={false}
                      filter={false}
                      cellRenderer="srNoRenderer"
                    ></AgGridColumn>
                    <AgGridColumn
                      width={150}
                      field="errorName"
                      sortable={false}
                      filter={false}
                    ></AgGridColumn>
                    <AgGridColumn
                      field="_id"
                      headerName="Id"
                      sortable={true}
                      comparator={customLowerCaseComparator}
                      filter={true}
                    ></AgGridColumn>
                    <AgGridColumn
                      width={200}
                      field="name"
                      sortable={true}
                      comparator={customLowerCaseComparator}
                      filter={true}
                    ></AgGridColumn>
                    <AgGridColumn
                      width={250}
                      field="email"
                      sortable={true}
                      comparator={customLowerCaseComparator}
                      filter={true}
                    ></AgGridColumn>
                    <AgGridColumn
                      width={200}
                      field="mobileNo"
                      sortable={true}
                      comparator={customLowerCaseComparator}
                      filter={true}
                    ></AgGridColumn>
                    <AgGridColumn
                      field="countryCode"
                      headerName="Country Code"
                      sortable={true}
                      comparator={customLowerCaseComparator}
                      filter={true}
                    ></AgGridColumn>
                    <AgGridColumn
                      field="extraone"
                      sortable={true}
                      comparator={customLowerCaseComparator}
                      filter={true}
                    ></AgGridColumn>
                    <AgGridColumn
                      field="extratwo"
                      sortable={true}
                      comparator={customLowerCaseComparator}
                      filter={true}
                    ></AgGridColumn>
                    <AgGridColumn
                      field="extrathree"
                      sortable={true}
                      comparator={customLowerCaseComparator}
                      filter={true}
                    ></AgGridColumn>
                    <AgGridColumn
                      field="extrafour"
                      sortable={true}
                      comparator={customLowerCaseComparator}
                      filter={true}
                    ></AgGridColumn>
                    <AgGridColumn
                      field="extrafive"
                      sortable={true}
                      comparator={customLowerCaseComparator}
                      filter={true}
                    ></AgGridColumn>
                    <AgGridColumn field="status" sortable={true}></AgGridColumn>
                  </AgGridReact>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

    </>
  );
}

export default Students;
