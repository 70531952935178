import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Select from "react-select";
import JoditEditor from "jodit-react";
import Compressor from "compressorjs";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File

const Updategroup = (props) => {
  const defaultFonts = [
    "Arial",
    "Comic Sans MS",
    "Courier New",
    "Impact",
    "Georgia",
    "Tahoma",
    "Trebuchet MS",
    "Verdana",
  ];
  const sortedFontOptions = [
    "Logical",
    "Salesforce Sans",
    "Garamond",
    "Sans-Serif",
    "Serif",
    "Times New Roman",
    "Helvetica",
    ...defaultFonts,
  ].sort();
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const [grupName, setGroups] = useState("");
  const [options, setOptions] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedOptionTagLabel, setSelectedOptionTagLabel] = useState([]);
  const [groupImage, setGroupImage] = useState("");
  const [sendGroupImage, setSendGroupImage] = useState("");
  // ----priority state---//
  const [priority, setPriority] = useState("");
  const [group, setGroup] = useState([]); // THIS STATE IS USED FOR "GROUP" PARAMETER FOR SECTION API
  const [groupsName, setGroupsName] = useState([]); // THIS STATE IS USED FOR "GROUPSNAME" PARAMETER FOR SECTION API
  let editGroupsData = props.updatedData;
  useEffect(() => {
    getnewtag();
  }, []);

  useEffect(() => {
    if (editGroupsData?.groupDescription?.length > 0) {
      setContent(editGroupsData?.groupDescription);
    }
  }, [editGroupsData]);

  let config = {
    readonly: false,
    height: 400,
  };

  function updateGroupData() {
    if (selectedOptionTagLabel.length === 0) {
      props.updatedData.tags.map((item) => {
        groupsName.push(item.tags);
      });
    } else {
      selectedOptionTagLabel.map((item) => {
        groupsName.push(item);
      });
    }

    if (selectedTags.length === 0) {
      props.updatedData.tags.map((item) => {
        group.push(item._id);
      });
    } else {
      selectedTags.map((item) => {
        group.push(item);
      });
    }

    let data = new FormData();
    data.append("group", group);
    data.append("groupsName", groupsName);
    data.append("groupsId", props.updatedData?._id);
    data.append(
      "priority",
      priority == ""
        ? priority
        : props.updateGroupData?.priority
        ? props.updateGroupData?.priority
        : 5
    );
    data.append(
      "groupDescription",
      content === "" ? props.updatedData?.groupDescription : content
    );
    data.append(
      "image",
      sendGroupImage == "" ? props.updatedData?.image : sendGroupImage
    );
    data.append(
      "groups",
      grupName === "" ? props.updatedData?.groups : grupName
    );
    data.append(
      "tags",
      selectedOptionTagLabel.toString() === "" || null
        ? props.updatedData?.tags?.map((item) => {
            return item;
          })
        : selectedOptionTagLabel
    );

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/updategroups`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        resetform();
        props.getGroups();
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  const resetform = () => {
    setGroup([]);
    setGroupsName([]);
    setPriority("");
    setSendGroupImage("");
    setContent("");
    setGroups("");
    setSelectedOptionTagLabel([]);
    setSelectedTags([]);
    setGroupImage("");
  };
  //--------GET TAGS API----------//

  function getnewtag() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/gettag`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        response.data.tagData.map((tag, index) => {
          options.push({ value: index, label: tag.tags });
        });
        setOptions(options);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  function handleTagChange(event) {
    let array = [];
    let arrayLabel = [];
    event.map((item) => {
      array.push(item.value);
    });
    event.forEach((option) => {
      arrayLabel.push(option.label);
    });
    setSelectedTags(array);
    setSelectedOptionTagLabel(arrayLabel);
  }

  //----------------FUNCTION TO SET IMAGES THAT ARE TO BE SENT----------------------//

  const handleGroupImage = (event) => {
    if (event.target.files[0]) {
      let file = event.target.files[0];
      new Compressor(file, {
        quality: 0.2, // 0.6 can also be used, but its not recommended to go below.
        success: (compressedResult) => {
          // compressedResult has the compressed file.
          // Use the compressed file to upload the images to your server.
          // let display = URL.createObjectURL(compressedResult);
          // setAuthorImage(display);
          // setSendAuthorImage(compressedResult);
          let display = URL.createObjectURL(compressedResult);
          setGroupImage(display);
          setSendGroupImage(compressedResult);
        },
      });
    }
  };

  //----------------FUNCTION TO RENDER IMAGES----------------------//

  const renderImages = (image) => {
    return (
      <img
        alt="renderImages"
        style={{ width: "110px", height: "140px" }}
        src={image}
        key={image}
      />
    );
  };

  const optionspriority = [
    { value: "0", label: "0" },
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
  ];

  function selectPriorityValue(event) {
    setPriority(event.value);
  }

  return (
    <>
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          Edit Group
        </h5>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={resetform}
        ></button>
      </div>
      <div className="modal-body">
        <div className="row">
          <div className="col-md-12 mb-3">
            <label className="form-label">Edit Group </label>
            <div className="position-relative">
              <input
                type="text"
                defaultValue={editGroupsData?.groups}
                key={editGroupsData?.groups}
                className="form-control"
                onChange={(e) => {
                  setGroups(e.target.value);
                }}
              />
              <div className="hint_box" style={{ display: "block" }}></div>
            </div>
          </div>
        </div>
        <div className="col-md-12 mb-3">
          <label className="form-label">Group Image</label>
          <ul className="imagesUpload">
            <li style={{ width: "100%", height: "128px" }}>
              <input
                type="file"
                accept="image/*,capture=camera"
                capture="”camera"
                onChange={handleGroupImage}
              />
              {!groupImage ? (
                <img
                  alt="renderImages"
                  style={{ width: "100%", height: "128px" }}
                  src={props.updatedData?.image}
                />
              ) : (
                renderImages(groupImage)
              )}
            </li>
          </ul>
        </div>

        <div className="col-md-12">
          <label className="form-label">Group Description</label>
          {/* <div className="App">
            <JoditEditor
              ref={editor}
              value={editGroupsData?.groupDescription}
              config={config}
              tabIndex={1} // tabIndex of textarea
              onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
            />
          </div> */}
          <div className="App">
            <SunEditor
              width="100%"
              plugin=""
              height="100%"
              setContents={content}
              onChange={(event) => setContent(event)}
              setOptions={{
                buttonList: [
                  ["undo", "redo"],
                  ["font", "fontSize", "formatBlock"],
                  ["paragraphStyle", "blockquote"],
                  [
                    "bold",
                    "underline",
                    "italic",
                    "strike",
                    "subscript",
                    "superscript",
                  ],
                  ["fontColor", "hiliteColor"],
                  ["align", "list", "lineHeight"],
                  ["outdent", "indent"],
                  ["fullScreen", "showBlocks", "codeView"],
                  ["preview", "print"],
                  ["removeFormat"],

                  [
                    ":i-More Misc-default.more_vertical",
                    "image",
                    "video",
                    "audio",
                    "link",
                    "fullScreen",
                    "showBlocks",
                    "codeView",
                    "preview",
                    "print",
                    "save",
                    "template",
                  ],
                  [
                    ":r-More Rich-default.more_plus",
                    "table",
                    "horizontalRule",
                    "imageGallery",
                  ],
                ],
                defaultTag: "div",
                minHeight: "300px",
                showPathLabel: false,
                font: sortedFontOptions,
              }}
            />
          </div>
        </div>

        <div className="col-md-12 mb-3">
          <label className="form-label">Select Tags</label>
          <Select
            isMulti
            defaultValue={editGroupsData?.tags?.map((item, index) => {
              return { value: index, label: item };
            })}
            key={editGroupsData?.tags?.map((item, index) => {
              return index;
            })}
            options={options}
            onChange={handleTagChange}
          />
        </div>

        <div className="col-md-12 mb-3">
          <label className="form-label">Select Priority</label>
          <Select
            defaultValue={optionspriority.map((item) => {
              if (item.label === editGroupsData?.priority?.toString()) {
                return item;
              }
            })}
            key={optionspriority.map((item) => {
              if (editGroupsData?.priority?.toString() === item.value) {
                return item.value;
              }
            })}
            options={optionspriority}
            name="priority"
            onChange={(event) => selectPriorityValue(event)}
          />
        </div>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-danger CancelBtn"
          data-bs-dismiss="modal"
          onClick={resetform}
        >
          Cancel
        </button>
        <button
          type="submit"
          onClick={updateGroupData}
          className="btn submitBtn"
          data-bs-dismiss="modal"
        >
          Submit
        </button>
      </div>
    </>
  );
};

export default Updategroup;
