import React, { useState, useMemo, useEffect, useRef } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import axios from "axios";
import UpdatesubcategoriesDetails from "./UpdatesubcategoriesDetails";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import Select from "react-select";
import { MdModeEditOutline } from "react-icons/md";
import { AiFillDelete } from "react-icons/ai";
import Footer from "../Footer/footer";
import Sidebar from "../Sidebar/sidebar";
import Header from "../Header/header";
import ToggleButton from "../../components/Toggle_Button/ToggleButton";
import Modal_Popup from "../../components/modal_renderer/Modal_Popup";
import Compressor from "compressorjs";
import JsonDownload from "../../components/downloadjson";
import isdownloadUtils from "../../components/utilDownloadRoles";

/**
 *
 * SubCategory is replaced with Subject [Category => Subject]
 */

function Subcategories(props) {
  const [UpdatecSubategoriesData, setUpdatecSubategoriesData] = useState({});
  const [DeleteSubategoriesId, setDeleteSubategoriesId] = useState();
  const [data, setdata] = useState([]);
  const [subcategory, setSubCategory] = useState("");
  const [image, setimage] = useState("");
  const [subrowData, setSubRowData] = useState([]);
  const [categoryId, setcategoryId] = useState("");
  const rowHeight = 55;
  let image_reference = useRef(null);

  const resetForm = () => {
    setcategoryId("");
    setSubCategory("");
    setimage("");
    setdata([]);
  };
  const ChildMessageRenderer = (props) => {
    return (
      <div
        className="iconActionList"
        onClick={() => {
          setUpdatecSubategoriesData(props.data.subCategoryData);
        }}
        data-bs-toggle="modal"
        data-bs-target="#UpdateCategoriesData"
      >
        <div className="editIcon">
          <MdModeEditOutline className="ActionIcon" />
        </div>
        <div
          className="DeleteIcon"
          onClick={() => {
            setDeleteSubategoriesId(props.data.subcateId);
          }}
          data-bs-toggle="modal"
          data-bs-target="#DeleteCategoriesData"
        >
          <AiFillDelete className="ActionIcon" />
        </div>
      </div>
    );
  };

  // --Add subcategory API--//

  function subaddnewdrop() {
    let data = new FormData();
    data.append("subCategory", subcategory);
    data.append("image", image);
    data.append("categoryId", categoryId);
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/addSubcategory`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        subgetnew();
        getnew();
        resetForm();
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  // --get category API--//

  useEffect(() => {
    getnew();
    subgetnew();
  }, []);

  function getnew() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getCategory`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        let array = [];
        response.data.CategoryData?.map((item) => {
          array.push({ value: item._id, label: item.category });
        });
        setdata(array);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  const handlechange = (e) => {
    setcategoryId(e.value);
  };

  // --Add subcategory API--//
  const [subcategorydownloaddata, setSubcategoryDownloadData] = useState([]);

  function subgetnew() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getSubcategory`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        let subcategoryData = [];
        response.data?.SubcategoryData?.map((subcat, index) => {
          subcategoryData.push({
            cateId: subcat.categoryId?._id,
            category: subcat.categoryId?.category,
            subCategory: subcat.subCategory,
            subCateImage: subcat.image,
            subcateId: subcat._id,
            categoryData: subcat.categoryId,
            subCategoryData: subcat,
          });
        });
        let downloaddata = [];
        response.data?.SubcategoryData?.map((subcat, index) => {
          downloaddata.push({
            cateId: subcat.categoryId?._id,
            category: subcat.categoryId?.category,
            subCategory: subcat.subCategory,
            subCateImage: subcat.image,
            subcateId: subcat._id,
          });
        });
        setSubcategoryDownloadData(downloaddata);
        setSubRowData(subcategoryData);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  // --delete subcategory API--//

  function subdeleteData(index) {
    let data = JSON.stringify({
      subCategoryId: index,
    });
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/deleteSubcategory`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        subgetnew();
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  const SrNoRenderer = (props) => {
    return <span className="profle_img_box">{props.rowIndex + 1}</span>;
  };

  const IconRenderer = (props) => {
    return (
      <span className="profle_img_box">
        <img
          className="profile_img_table"
          src={props.data.subCateImage}
          alt="renderImages"
        />
      </span>
    );
  };

  const SubCategoryRenderer = (props) => {
    return (
      <span className="profle_img_box">
        <span>{props.data.subCategory}</span>
      </span>
    );
  };
  const CategoryRenderer = (props) => {
    return (
      <span className="profle_img_box">
        <span>{props.data.category}</span>
      </span>
    );
  };

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      sortable: true,
      resizable: true,
      filter: true,
      getQuickFilterText: (params) => {
        return params.value.name;
      },
      editable: true,
    };
  }, []);

  const categoryValueGettter = (params) => {
    return params?.data?.category;
  };

  const statusValueGetter = (params) => {
    return params?.data?.subCategoryData?.status;
  };

  const homepageValueGetter = (params) => {
    return params?.data?.subCategoryData?.homepagesection;
  };

  const customLowerCaseComparator = (valueA, valueB) => {
    if (typeof valueA === "string") {
      return valueA?.toLowerCase().localeCompare(valueB?.toLowerCase());
    }
    return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
  };

  const changehomePageSectionStatus = (subcatId) => {
    let data = JSON.stringify({
      subcatId: subcatId,
    });
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/homepagesectionStatus`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        subgetnew();
      })
      .catch(function (error) {
        // console.log(error);
      });
  };

  const TrendingRender = (props) => {
    return (
      <ToggleButton
        handleToggle={() => {
          changehomePageSectionStatus(props?.data?.subcateId);
        }}
        status={props?.data?.subCategoryData?.homepagesection}
      />
    );
  };

  function changeSubCategoryStatus(subcatId) {
    let data = JSON.stringify({
      subcatId: subcatId,
    });

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/changesubcatStatus`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        subgetnew();
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  const StatusRenderer = (props) => {
    let status = props?.data?.subCategoryData?.status
      ? "deactivate"
      : "activate";
    let message = "Are you sure you want to " + status + " this SubCategory?";
    return (
      <Modal_Popup
        status={props?.data?.subCategoryData?.status}
        onClick={() => {
          changeSubCategoryStatus(props?.data?.subcateId);
        }}
        message={message}
      />
    );
  };

  const handlesubcategoryImage = (event) => {
    if (event.target.files[0]) {
      let file = event.target.files[0];
      new Compressor(file, {
        quality: 0.2, // 0.6 can also be used, but its not recommended to go below.
        success: (compressedResult) => {
          // compressedResult has the compressed file.
          // Use the compressed file to upload the images to your server.
          // let display = URL.createObjectURL(compressedResult);
          // setAuthorImage(display);
          // setSendAuthorImage(compressedResult);
          setimage(compressedResult);
        },
      });
    }
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">
                Subject
                <div className="float-end btns_head d-flex">
                  <button
                    className="btn btn-theme btn-sm"
                    data-bs-toggle="modal"
                    data-bs-target="#createGroup"
                  >
                    Add New Subject
                  </button>
                  {isdownloadUtils("downloadCategory") && (
                    <JsonDownload
                      title="Download Subject"
                      fileName="category-excel"
                      downloadfile={subcategorydownloaddata}
                      btnClassName={"btn btn-theme btn-sm"}
                      btnColor={"#f9560f"}
                    />
                  )}
                </div>
              </h4>
              <div>
                <div
                  className="modal fade"
                  id="createGroup"
                  tabIndex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-scrollable">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                          Add Subject
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          onClick={() => {
                            resetForm();
                          }}
                        ></button>
                      </div>
                      <div className="modal-body">
                        <div className="row">
                          <div className="col-md-12 mb-3">
                            <label className="form-label">
                              Select Subject Group
                            </label>
                            <Select
                              options={data}
                              key={data}
                              onChange={(e) => handlechange(e)}
                            />
                          </div>
                          <div className="col-md-12 mb-3">
                            <label className="form-label"> Subject</label>
                            <div className="position-relative">
                              <input
                                value={subcategory}
                                type="text"
                                className="form-control"
                                onChange={(e) => {
                                  setSubCategory(e.target.value);
                                }}
                              />

                              <div
                                className="hint_box"
                                style={{ display: "block" }}
                              ></div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12 mb-3">
                            <label className="form-label">Subject Icon</label>
                            <div className="position-relative">
                              <div>
                                <input
                                  ref={image_reference}
                                  type="file"
                                  accept="image/*,capture=camera"
                                  capture="”camera"
                                  defaultValue={""}
                                  onChange={handlesubcategoryImage}
                                  key={data}
                                  className="form-control"
                                />
                              </div>
                              <div
                                className="hint_box"
                                style={{ display: "block" }}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-danger CancelBtn"
                          data-bs-dismiss="modal"
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          onClick={subaddnewdrop}
                          className="btn submitBtn"
                          data-bs-dismiss="modal"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="modal fade"
                id="UpdateCategoriesData"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-scrollable">
                  <div className="modal-content">
                    <UpdatesubcategoriesDetails
                      updatedData={UpdatecSubategoriesData}
                      categoryData={data}
                      subgetnew={subgetnew}
                    />
                  </div>
                </div>
              </div>
              <div
                className="modal fade DeletePopup"
                id="DeleteCategoriesData"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="">
                            {" "}
                            <p>Are you sure you want to delete this Subject?</p>
                            <button
                              type="button"
                              className="btn btn-danger CancelBtn"
                              data-bs-dismiss="modal"
                              onClick={resetForm}
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              onClick={() => {
                                subdeleteData(DeleteSubategoriesId);
                              }}
                              className="btn submitBtn"
                              data-bs-dismiss="modal"
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ag-theme-alpine tableFix">
                <AgGridReact
                  rowHeight={rowHeight}
                  rowData={subrowData}
                  defaultColDef={defaultColDef}
                  frameworkComponents={{
                    childMessageRenderer: ChildMessageRenderer,
                    subCategoryRenderer: SubCategoryRenderer,
                    iconRenderer: IconRenderer,
                    srNoRenderer: SrNoRenderer,
                    statusRenderer: StatusRenderer,
                    categoryRenderer: CategoryRenderer,
                    trendingRender: TrendingRender,
                  }}
                >
                  <AgGridColumn
                    width={90}
                    field="SrNo"
                    Srno={true}
                    sortable={false}
                    filter={false}
                    cellRenderer="srNoRenderer"
                  ></AgGridColumn>
                  <AgGridColumn
                    width={250}
                    field="Action"
                    cellRenderer="childMessageRenderer"
                    colId="params"
                    sortable={false}
                    filter={false}
                  ></AgGridColumn>
                  <AgGridColumn
                    width={90}
                    cellRenderer="iconRenderer"
                    field="Icon"
                    sortable={false}
                    filter={false}
                  ></AgGridColumn>
                  <AgGridColumn
                    width={180}
                    cellRenderer="subjectGroup"
                    field="subjectGroup"
                    valueGetter={categoryValueGettter}
                    sortable={true}
                    comparator={customLowerCaseComparator}
                    filter={true}
                  ></AgGridColumn>
                  <AgGridColumn
                    width={180}
                    headerName="Subject"
                    field="subCategory"
                    sortable={true}
                    comparator={customLowerCaseComparator}
                    filter={true}
                  ></AgGridColumn>

                  <AgGridColumn
                    width={160}
                    field="status"
                    cellRenderer="statusRenderer"
                    sortable={true}
                    filter={true}
                    valueGetter={statusValueGetter}
                  ></AgGridColumn>

                  <AgGridColumn
                    width={160}
                    field="Active in home page"
                    cellRenderer="trendingRender"
                    sortable={true}
                    filter={true}
                    valueGetter={homepageValueGetter}
                  ></AgGridColumn>
                </AgGridReact>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Subcategories;
