import React, { useState, useMemo, useEffect, useRef } from "react";
import Footer from "../Footer/footer";
import Header from "../Header/header";
import Sidebar from "../Sidebar/sidebar";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import axios from "axios";
import { useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import LoadingSpinner from "../../components/loader";
import JsonDownload from "../../components/downloadjson";
import isdownloadUtils from "../../components/utilDownloadRoles";

function Programmes() {
  const gridRef = useRef();
  const rowheight = 55;
  const defaultcoldef = useMemo(() => {
    return {
      sortable: true,
      resizable: true,
      filter: true,
      getQuickFilterText: (params) => {
        return params.value.name;
      },
      editable: true,
    };
  }, []);
  const [programmedata, setprogrammedata] = useState([]);
  // const [programmedownloaddata, setprogrammeDownloadData] = useState([]);

  const SrNoRenderer = (props) => {
    return <span>{props.node.rowIndex + 1}</span>;
  };
  const BusinessnameRenderer = (props) => {
    return <span>{props.data.classId?.businessName}</span>;
  };
  const BusinessIdRenderer = (props) => {
    return <span>{props.data.classId?._id}</span>;
  };
  const AdminIdRenderer = (props) => {
    return <span>{props.data?.userId[0]?._id}</span>;
  };
  const branchnameRenderer = (props) => {
    return (
      <span>
        {props.data.userId
          ?.slice(1)
          .map((item) => item.branchName)
          .join(", ")}
      </span>
    );
  };

  const businessnameValueGetter = (params) => {
    return params.data.classId?.businessName;
  };
  const adminIdValueGetter = (params) => {
    return params.data.userId[0]?._id;
  };
  const businessIdValueGetter = (params) => {
    return params.data.classId?._id;
  };

  const branchnameValueGetter = (params) => {
    return params.data.userId
      ?.slice(1)
      .map((item) => item.branchName)
      .join(", ");
  };
  const [programmedownloaddata, setprogrammeDownloadData] = useState([]);

  function getprogramme() {
    gridRef?.current?.api?.showLoadingOverlay();
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getprograms`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        let programmedata = [];
        gridRef?.current?.api?.hideOverlay();
        response.data.data.forEach((element) => {
          programmedata.push({
            classId: element?.classId?._id,
            _id: element?._id,
            businessName: element?.classId?.businessName,
            description: element?.description,
            programsName: element?.programsName,
            programsName: element?.programsName,
            Branches: element?.userId
              ?.slice(1)
              .map((item) => item.branchName)
              .join(", "),
          });
        });
        setprogrammeDownloadData(programmedata);

        setprogrammedata(response.data.data);
        // setprogrammeDownloadData(programmedata)
      })
      .catch(function (error) {
        gridRef?.current?.api?.hideOverlay();
        // console.log(error);
      });
  }

  useEffect(() => {
    getprogramme();
  }, []);

  const [uploadIsOpen, setUploadIsOpen] = useState(false);
  const [duplicate, setDuplicate] = useState([]);
  const { reset } = useForm({ mode: "onBlur" });
  const {
    register: uploadRegister,
    unregister,
    handleSubmit: handlesubmitUpload,
    formState: { errors: uploaderrors },
  } = useForm({ mode: "onBlur" });

  function somefun() {
    setUploadIsOpen(false);
    setUploadIsOpenStatusModal(false);
    setDuplicate([]);
    cross();
  }

  function fun() {
    setUploadIsOpen(false);
    setLoader(false);
    setDuplicate([]);
    cross();
  }

  function cross() {
    setLoader(false);
    setDuplicate([]);
    reset();
  }

  // --------- business bulk upload ------------ //
  const [uplaodStatus, setUplaodStatus] = useState({
    status: false,
    value: "",
  });
  const [duplicateData, setDuplicatedata] = useState([]);
  const [duplicatedatastatus, setduplicatedatastatus] = useState(false);
  const [SuccessfullUpdateStatus, setSuccessfullUpdateStatus] = useState(true);
  const [uploadIsOpenStatusModal, setUploadIsOpenStatusModal] = useState(false);
  const [loader, setLoader] = useState(false);

  function uploadProgrammeFiles(postdata) {
    setLoader(true);
    let data = new FormData();
    data.append("attachment", postdata.uploadFile[0]);
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/programUpload`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setUploadIsOpen(false);
        setUploadIsOpenStatusModal(true);
        setUplaodStatus({
          status: response.data.status,
          value: response.data.message,
        });
        setTimeout(() => {
          if (response.data.status) {
            setduplicatedatastatus(true);
            reset();
            getprogramme();
            let blank_userid = [];
            // let NotmatchedData = []

            response.data.blank_userid &&
              response.data.blank_userid.forEach((element) => {
                blank_userid.push({ ...element, errorName: "blank user id" });
              });
            // response.data.NotmatchedData && response.data.NotmatchedData.forEach(element => {
            //     NotmatchedData.push({ ...element, errorName: 'Not Matched Data' });
            // });
            const updateBusinessStatus = !blank_userid.length;

            if (updateBusinessStatus) {
              setSuccessfullUpdateStatus(false);
            }
            setDuplicatedata([...blank_userid]);
            setTimeout(() => {
              if (updateBusinessStatus) {
                setUploadIsOpenStatusModal(false);
                setLoader(false);
              } else {
                setUploadIsOpenStatusModal(false);
              }
            }, 800);
          }
        }, 1000);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  return (
    <>
      {!duplicatedatastatus && (
        <div className="page-wrapper">
          <div className="container-fluid">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">
                  programme
                  <div className="float-end btns_head d-flex">
                    {isdownloadUtils("uploadProgramme") && (
                      <button
                        className="btn btn-theme btn-sm"
                        onClick={() => {
                          setUploadIsOpen(true);
                        }}
                      >
                        Upload Programme
                      </button>
                    )}
                    {isdownloadUtils("downloadProgramme") && (
                      <JsonDownload
                        title="Download Programme"
                        fileName="programme-excel"
                        downloadfile={programmedownloaddata}
                        btnClassName={"btn btn-theme btn-sm"}
                        btnColor={"#f9560f"}
                      />
                    )}
                  </div>
                </h4>
                <div>
                  <Modal show={uploadIsOpenStatusModal} onHide>
                    <Modal.Body>
                      <div
                        tabIndex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog modal-dialog-scrollable">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5
                                className="modal-title"
                                id="exampleModalLabel"
                              >
                                <p>
                                  <span
                                    style={{
                                      color: uplaodStatus.status
                                        ? "green"
                                        : "red",
                                    }}
                                  >
                                    {uplaodStatus.value}
                                  </span>
                                </p>
                              </h5>
                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={() => {
                                  somefun();
                                }}
                              ></button>
                            </div>
                            <div className="modal-body">
                              <div className="row">
                                {duplicate.length > 0 && (
                                  <div className="col-md-12 mb-4">
                                    <label className="form-label">
                                      Email Already Exists
                                    </label>
                                    <ul>
                                      {duplicate?.map((item) => {
                                        return <li>{item}</li>;
                                      })}
                                    </ul>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Modal.Body>
                  </Modal>
                </div>
                <div>
                  <Modal show={uploadIsOpen} onHide>
                    <Modal.Body>
                      <div
                        tabIndex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <form
                          onSubmit={handlesubmitUpload(uploadProgrammeFiles)}
                        >
                          <div className="modal-dialog modal-dialog-scrollable">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5
                                  className="modal-title"
                                  id="exampleModalLabel"
                                >
                                  Upload File
                                </h5>
                                <button
                                  type="button"
                                  className="btn-close"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                  onClick={() => {
                                    fun();
                                  }}
                                ></button>
                              </div>
                              <div className="modal-body">
                                <div className="row">
                                  <div className="col-md-12 mb-4">
                                    <label className="form-label">
                                      {" "}
                                      Upload File
                                    </label>
                                    <div className="position-relative">
                                      <input
                                        className="form-control"
                                        type="file"
                                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                        {...uploadRegister("uploadFile", {
                                          required: true,
                                        })}
                                        onChange={(e) => {
                                          unregister("uploadFile");
                                        }}
                                      />
                                      {uploaderrors?.uploadFile?.type ===
                                        "required" && (
                                        <p className="error">
                                          This field is required
                                        </p>
                                      )}
                                      <div
                                        className="hint_box"
                                        style={{ display: "block" }}
                                      ></div>
                                    </div>
                                  </div>
                                  {duplicate.length > 0 && (
                                    <div className="col-md-12 mb-4">
                                      <label className="form-label">
                                        Email Already Exists
                                      </label>
                                      <ul>
                                        {duplicate?.map((item) => {
                                          return <li>{item}</li>;
                                        })}
                                      </ul>
                                    </div>
                                  )}
                                </div>
                              </div>
                              {loader && <LoadingSpinner />}
                              <div className="modal-footer">
                                <button
                                  type="button"
                                  className="btn CancelBtn"
                                  data-bs-dismiss="modal"
                                  onClick={() => {
                                    somefun();
                                  }}
                                >
                                  Cancel
                                </button>
                                <button
                                  type="submit"
                                  className="btn submitBtn"
                                  disabled={loader}
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </Modal.Body>
                  </Modal>
                </div>
                <div></div>

                <div
                  style={{ width: "100%" }}
                  className="ag-theme-alpine tableFix"
                >
                  <AgGridReact
                    rowHeight={rowheight}
                    rowData={programmedata}
                    defaultColDef={defaultcoldef}
                    frameworkComponents={{
                      srnoRenderer: SrNoRenderer,
                      businessnameRenderer: BusinessnameRenderer,
                      businessIdRenderer: BusinessIdRenderer,
                      adminIdRenderer: AdminIdRenderer,
                      branchnameRenderer: branchnameRenderer,
                      branchnameRenderer: branchnameRenderer,
                    }}
                    onGridReady={getprogramme}
                    overlayLoadingTemplate={
                      '<span class="ag-overlay-loading-center" style="margin: auto">Please wait while your rows are loading</span>'
                    }
                    ref={gridRef}
                    overlayNoRowsTemplate={
                      "<span style=\"padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow\">This is a custom 'no rows' overlay</span>"
                    }
                  >
                    <AgGridColumn
                      headerName="Sr No."
                      field="SrNo"
                      width={90}
                      cellRenderer="srnoRenderer"
                      sortable={false}
                      filter={false}
                      pinned="left"
                    ></AgGridColumn>
                    <AgGridColumn
                      headerName="Business Name"
                      field="businessname"
                      width={180}
                      cellRenderer="businessnameRenderer"
                      sortable={true}
                      filter={true}
                      valueGetter={businessnameValueGetter}
                      pinned="left"
                    ></AgGridColumn>
                    <AgGridColumn
                      headerName="Admin Id"
                      field="adminId"
                      width={200}
                      cellRenderer="adminIdRenderer"
                      valueGetter={adminIdValueGetter}
                      sortable={true}
                      filter={true}
                      pinned="left"
                    ></AgGridColumn>
                    <AgGridColumn
                      headerName="Business Id"
                      field="businessId"
                      width={200}
                      cellRenderer="businessIdRenderer"
                      valueGetter={businessIdValueGetter}
                      sortable={true}
                      filter={true}
                      pinned="left"
                    ></AgGridColumn>
                    <AgGridColumn
                      headerName="Programmes Name"
                      field="programsName"
                      width={340}
                      sortable={true}
                      filter={true}
                    ></AgGridColumn>
                    <AgGridColumn
                      headerName="Assign Branch"
                      field="branchName"
                      valueGetter={branchnameValueGetter}
                      width={340}
                      sortable={true}
                      filter={true}
                    ></AgGridColumn>
                  </AgGridReact>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {duplicatedatastatus && (
        <div className="page-wrapper">
          <div className="container-fluid">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">
                  Duplicate programme
                  <div className="float-end btns_head">
                    <button
                      className="btn btn-theme btn-sm"
                      onClick={() => {
                        setduplicatedatastatus(false);
                        setLoader(false);
                        setUploadIsOpenStatusModal(false);
                      }}
                    >
                      Back
                    </button>
                    <JsonDownload
                      title="Download Excel"
                      fileName="errorlog-programme-excel"
                      downloadfile={duplicateData}
                      btnClassName={"btn btn-theme btn-sm"}
                      btnColor={"#f9560f"}
                    />
                    {/* <JsonDownload title="Download Excel" fileName="business-excel" downloadfile={businessBulkUploadData} btnClassName={"btn btn-theme btn-sm"} btnColor={"#f9560f"} /> */}
                  </div>
                </h4>
                <div
                  style={{ width: "100%" }}
                  className="ag-theme-alpine tableFix"
                >
                  <AgGridReact
                    rowHeight={rowheight}
                    rowData={duplicateData}
                    defaultColDef={defaultcoldef}
                    frameworkComponents={{
                      srnoRenderer: SrNoRenderer,
                      // businessnameRenderer: BusinessnameRenderer,
                      // branchnameRenderer: branchnameRenderer,
                    }}
                    onGridReady={getprogramme}
                    overlayLoadingTemplate={
                      '<span class="ag-overlay-loading-center" style="margin: auto">Please wait while your rows are loading</span>'
                    }
                    ref={gridRef}
                    overlayNoRowsTemplate={
                      "<span style=\"padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow\">This is a custom 'no rows' overlay</span>"
                    }
                  >
                    <AgGridColumn
                      headerName="Sr No."
                      field="SrNo"
                      width={120}
                      cellRenderer="srnoRenderer"
                      sortable={false}
                      filter={false}
                      pinned="left"
                    ></AgGridColumn>
                    {SuccessfullUpdateStatus && (
                      <AgGridColumn
                        width={150}
                        field="errorName"
                        sortable={false}
                        filter={false}
                      ></AgGridColumn>
                    )}
                    <AgGridColumn
                      headerName="Programmes Name"
                      field="programsName"
                      width={240}
                      sortable={true}
                      filter={true}
                    ></AgGridColumn>
                    <AgGridColumn
                      headerName="classId"
                      field="classId"
                      width={320}
                      sortable={true}
                      filter={true}
                      pinned="left"
                    ></AgGridColumn>
                    <AgGridColumn
                      headerName="Description"
                      field="description"
                      width={280}
                      sortable={true}
                      filter={true}
                    ></AgGridColumn>
                  </AgGridReact>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

    </>
  );
}
export default Programmes;
