import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Select from "react-select";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "./AddArticles.css";
import Footer from "../Footer/footer";
import Sidebar from "../Sidebar/sidebar";
import Header from "../Header/header";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import Compressor from "compressorjs";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File

function AddArticles() {
  const defaultFonts = [
    "Arial",
    "Comic Sans MS",
    "Courier New",
    "Impact",
    "Georgia",
    "Tahoma",
    "Trebuchet MS",
    "Verdana",
  ];

  const sortedFontOptions = [
    "Logical",
    "Salesforce Sans",
    "Garamond",
    "Sans-Serif",
    "Serif",
    "Times New Roman",
    "Helvetica",
    ...defaultFonts,
  ].sort();

  const editor = useRef(null);
  const [addButtonStatus, setAddButtonStatus] = useState(null);
  const [content, setContent] = useState("");
  const [authorImage, setAuthorImage] = useState("");
  const [sendAuthorImage, setSendAuthorImage] = useState("");
  const [sendArticleImage, setSendArticleImage] = useState("");
  const [articleImage, setArticleImage] = useState("");
  const [articleImages, setArticleImages] = useState([]);
  const [group, setGroup] = useState([]); // THIS STATE IS USED FOR "GROUP" PARAMETER FOR SECTION API
  const [groupsName, setGroupsName] = useState([]); // THIS STATE IS USED FOR "GROUPSNAME" PARAMETER FOR SECTION API
  const [serviceList, setServiceList] = useState([{ image: "", article: "" }]);
  const [optionsgroup, setoptionsgroup] = useState([]);
  const [selectedOptiongroup, setselectedOptiongroup] = useState([]);
  const [selectedMultipleGroups, setSelectedMultipleGroups] = useState([]);

  const [selectedOptionGroupLabel, setSelectedOptionGroupLabel] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  //const [displayImage, setDisplayImage] = useState([{img:""}])

  let history = useHistory();

  const [options, setoptions] = useState([]);
  const [selectedOptionone, setselectedOptionone] = useState([]);
  const [selectedOptionTagLabel, setSelectedOptionTagLabel] = useState([]);
  const [selectedOptionTagLabelTagIds, setSelectedOptionTagLabelTagIds] =
    useState([]);
  function AddArticles(postdata) {
    setAddButtonStatus(true);

    selectedOptionGroupLabel.map((item) => {
      groupsName.push(item);
    });

    selectedOptionTagLabel.map((item) => {
      groupsName.push(item);
    });

    selectedOptionone.map((item) => {
      group.push(item);
    });

    selectedOptiongroup.map((item) => {
      group.push(item);
    });

    let description = [],
      image = [];
    for (let a of serviceList) {
      description.push(a.article);
      image.push(a.image);
    }

    let data = new FormData();

    let array = [];
    for (let i = 0; i < articleImages.length; i += 2) {
      array.push(articleImages[i]);
    }

    data.append("authorimage", sendAuthorImage);
    data.append("authorName", postdata.authorname);
    data.append("authordescription", postdata.authordescription);
    data.append("title", postdata.title);
    data.append("banner", sendArticleImage);
    data.append("subject", postdata.subject);
    data.append("description", content);
    data.append("priority", priority);
    data.append("tags", selectedOptionTagLabel);
    data.append("tags_Id", JSON.stringify(selectedOptionTagLabelTagIds));
    data.append("groupId", selectedOptionGroupLabel);
    data.append("group", group);
    data.append("groupsName", groupsName);
    data.append("articledescription", postdata.articledescription);
    // data.append("groups_Id", selectedOptiongroup);
    data.append("groups_Id", JSON.stringify(selectedMultipleGroups));

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/addarticles`, //http://192.168.1.40:8900/admin
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "Application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        setAddButtonStatus(false);
        history.push("./Articles");
        resetForm();
      })
      .catch(function (error) {
        // console.log(error);
        setAddButtonStatus(false);
      });
  }
  const resetForm = () => {
    setContent("");
    setAuthorImage("");
    setselectedOptionone("");
  };
  //----get tags API--//

  useEffect(() => {
    getnewtag();
    getnewgroup();
  }, []);

  function getnewtag() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/gettag`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        response.data.tagData.forEach((tag, index) => {
          options.push({ value: tag._id, label: tag.tags });
        });
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  const handlechangetag = (event) => {
    let array = [];
    let arrayLabelids = [];
    let arrayLabel = [];
    event.forEach((option) => {
      array.push(option.value);
    });
    event.forEach((option) => {
      arrayLabel.push(option.label);
    });
    event.forEach((option) => {
      arrayLabelids.push({ id: option.value, tagName: option.label });
    });

    setselectedOptionone(array);
    setSelectedOptionTagLabel(arrayLabel);
    setSelectedOptionTagLabelTagIds(arrayLabelids);
  };

  const config = {
    readonly: false,
    height: 400,
  };

  // ----multi group selete get--//
  function getnewgroup() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getgroups`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        let optionsgroup = [];
        response.data.data.forEach((grup, index) => {
          optionsgroup.push({ label: grup.groups, value: grup._id });
        });

        setoptionsgroup(optionsgroup);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  const handlechangegroupmulti = (event) => {
    let array = [];
    let multigroupArray = [];
    let arrayLabel = [];
    event.forEach((option) => {
      array.push(option.value);
      multigroupArray.push({ id: option.value, grpName: option.label });
      arrayLabel.push(option.label);
    });
    // event.forEach((option) => {});
    setSelectedMultipleGroups(multigroupArray);
    setselectedOptiongroup(array);
    setSelectedOptionGroupLabel(arrayLabel);
  };

  //------- Priority values ------------//

  const optionspriority = [
    { value: "0", label: "0" },
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
  ];

  const [priority, setPriority] = useState("");

  function selectPriorityValue(event) {
    setPriority(event.value);
  }

  //--------Function to handle and set Author Image--------//

  const handleAuthorImage = (event) => {
    if (event.target.files[0]) {
      let file = event.target.files[0];
      new Compressor(file, {
        quality: 0.2, // 0.6 can also be used, but its not recommended to go below.
        success: (compressedResult) => {
          // compressedResult has the compressed file.
          // Use the compressed file to upload the images to your server.
          let display = URL.createObjectURL(compressedResult);
          setAuthorImage(display);
          setSendAuthorImage(compressedResult);
        },
      });
    }
  };

  //--------Function to handle and set Article Banner Image--------//

  const handleArticleImage = (event) => {
    if (event.target.files[0]) {
      let file = event.target.files[0];
      new Compressor(file, {
        quality: 0.2, // 0.6 can also be used, but its not recommended to go below.
        success: (compressedResult) => {
          // compressedResult has the compressed file.
          // Use the compressed file to upload the images to your server.
          let display = URL.createObjectURL(compressedResult);
          setArticleImage(display);
          setSendArticleImage(compressedResult);
        },
      });
    }
  };

  //--------Function to render images--------//

  const renderImages = (image) => {
    return (
      <img
        alt="renderImages"
        style={{ width: "110px", height: "140px" }}
        src={image}
        key={image}
      />
    );
  };

  return (
    <>
      <form onSubmit={handleSubmit(AddArticles)}>
        <div className="page-wrapper">
          <div className="container-fluid min_height">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Add Articles</h4>

                <div className="branchData">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row bgBox mb-3">
                        <div className="col-md-2">
                          <label className="form-label">Author Image</label>
                          <ul className="imagesUpload">
                            <li style={{ width: "100%", height: "148px" }}>
                              <input
                                type="file"
                                accept="image/*,capture=camera"
                                capture="”camera"
                                onChange={handleAuthorImage}
                              />
                              {/* <UploadCompresserFile /> */}

                              {authorImage.length === 0 ? (
                                <img
                                  alt="renderImages"
                                  style={{ width: "100%", height: "148px" }}
                                  src="../../images/defalutimg.svg"
                                />
                              ) : (
                                renderImages(authorImage)
                              )}

                              {/* {renderImages(authorImage)} */}
                            </li>
                          </ul>
                        </div>
                        <div className="col-md-10">
                          <div className="col-md-12 mb-3">
                            <label className="form-label">Author Name</label>
                            <input
                              // value={authorname}
                              type="text"
                              className="form-control"
                              {...register("authorname", { required: true })}
                              // onChange={(e) => {
                              //   setAuthorname(e.target.value);
                              // }}
                            />
                            {errors?.authorname?.type === "required" && (
                              <p className="error">This field is required</p>
                            )}
                          </div>

                          <div className="col-md-12">
                            <label className="form-label">
                              Author Description
                            </label>
                            <textarea
                              type="textarea"
                              // value={authordescription}
                              className="form-control"
                              {...register("authordescription")}
                              // onChange={(e) => {
                              //   setAuthorDescription(e.target.value);
                              // }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="row bgBox mb-3">
                        <div className="col-md-2">
                          <label className="form-label">Article Image</label>
                          <ul className="imagesUpload">
                            <li style={{ width: "100%", height: "128px" }}>
                              <input
                                type="file"
                                accept="image/*,capture=camera"
                                capture="”camera"
                                onChange={handleArticleImage}
                              />
                              {articleImage.length === 0 ? (
                                <img
                                  alt="renderImages"
                                  style={{ width: "100%", height: "128px" }}
                                  src="../../images/defalutimg.svg"
                                />
                              ) : (
                                renderImages(articleImage)
                              )}
                              {/* {renderImages(articleImage)} */}
                            </li>
                          </ul>
                        </div>
                        <div className="col-md-10">
                          <div className="col-md-12 mb-3">
                            <label className="form-label">Article Title</label>
                            <input
                              // value={title}
                              type="text"
                              className="form-control"
                              {...register("title", { required: true })}
                              // onChange={(e) => {
                              //   settitle(e.target.value);
                              // }}
                            />
                            {errors?.title?.type === "required" && (
                              <p className="error">This field is required</p>
                            )}
                          </div>

                          <div className="col-md-12">
                            <label className="form-label">
                              Article Subject
                            </label>
                            <input
                              // value={subject}
                              type="text"
                              className="form-control"
                              {...register("subject", { required: true })}
                              // onChange={(e) => {
                              //   setSubject(e.target.value);
                              // }}
                            />
                            {errors?.subject?.type === "required" && (
                              <p className="error">This field is required</p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-12 mt-3">
                          <label className="form-label">
                            Article Brief Description
                          </label>
                          <textarea
                            type="textarea"
                            //value={articledescription}
                            className="form-control"
                            {...register("articledescription", {
                              required: true,
                            })}
                            // onChange={(e) => {
                            //   setArticledescription(e.target.value);
                            // }}
                          />
                          {errors?.articledescription?.type === "required" && (
                            <p className="error">This field is required</p>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="services">
                      <div className="col-md-12">
                        <div className="row bgBox mb-3">
                          <div className="col-md-12">
                            <label className="form-label">
                              Article Description
                            </label>
                            {/* <div className="App">
                              <JoditEditor
                                ref={editor}
                                config={config}
                                tabIndex={1} // tabIndex of textarea
                                onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons

                              />
                            </div> */}
                            <div className="App">
                              <SunEditor
                                width="100%"
                                plugin=""
                                height="100%"
                                onChange={(event) => setContent(event)}
                                setOptions={{
                                  buttonList: [
                                    ["undo", "redo"],
                                    ["font", "fontSize", "formatBlock"],
                                    ["paragraphStyle", "blockquote"],
                                    [
                                      "bold",
                                      "underline",
                                      "italic",
                                      "strike",
                                      "subscript",
                                      "superscript",
                                    ],
                                    ["fontColor", "hiliteColor"],
                                    ["align", "list", "lineHeight"],
                                    ["outdent", "indent"],
                                    ["fullScreen", "showBlocks", "codeView"],
                                    ["preview", "print"],
                                    ["removeFormat"],

                                    [
                                      ":i-More Misc-default.more_vertical",
                                      "image",
                                      "video",
                                      "audio",
                                      "link",
                                      "fullScreen",
                                      "showBlocks",
                                      "codeView",
                                      "preview",
                                      "print",
                                      "save",
                                      "template",
                                    ],
                                    [
                                      ":r-More Rich-default.more_plus",
                                      "table",
                                      "horizontalRule",
                                      "imageGallery",
                                    ],
                                  ],
                                  defaultTag: "div",
                                  minHeight: "300px",
                                  showPathLabel: false,
                                  font: sortedFontOptions,
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="row bgBox mb-3">
                        <div className="col-md-12 mb-3">
                          <label className="form-label"> Select Tags </label>
                          <Select
                            isMulti
                            options={options}
                            onChange={(e) => handlechangetag(e)}
                          />

                          <span></span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="row bgBox mb-3">
                        <div className="col-md-12 mb-3">
                          <label className="form-label">Select Group</label>
                          <Select
                            isMulti
                            options={optionsgroup}
                            onChange={(e) => handlechangegroupmulti(e)}
                          />
                          <span></span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 mb-3">
                      <label className="form-label">Select Priority</label>
                      <Select
                        defaultValue={optionspriority.map((item) => {
                          return item;
                        })}
                        key={optionspriority.map((item) => {
                          return item.value;
                        })}
                        options={optionspriority}
                        name="Priority"
                        onChange={(event) => selectPriorityValue(event)}
                      />
                    </div>

                    <div className="col-md-12">
                      <button
                        type="button"
                        className="btn CancelBtn me-3"
                        onClick={() => {
                          history.push("./Articles");
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        disabled={addButtonStatus}
                        type="submit"
                        className="btn submitBtn me-3"
                        // onClick={AddArticles}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default AddArticles;
