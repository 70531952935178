import { useMemo, useState, useEffect } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import axios from "axios";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "./UserRoleManagement.css";
import { MdModeEditOutline } from "react-icons/md";
import Header from "../Header/header";
import Select from "react-select";
import Sidebar from "../Sidebar/sidebar";
import UpdateUserRoleManagement from "./UpdateUserRoleManagement";
import Footer from "../Footer/footer";
import { AiFillEye } from "react-icons/ai";
import Modal_Popup from "../../components/modal_renderer/Modal_Popup";
import { useForm } from "react-hook-form";
import { Modal, Button } from "react-bootstrap";
import { modules, subModules, isDownload } from "./roles";

function UserRoleManagement() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [refresh, doRefresh] = useState(0);
  const [addButtonStatus, setAddButtonStatus] = useState(null);
  const [UpdateStudentData, setUpdateCategoriesData] = useState({});
  const [Viewuser, setViewuser] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [data] = useState(modules);
  const [subModulesdata] = useState(subModules);
  const [isDownloaddata] = useState(isDownload);
  const [selectedOptiongroup, setselectedOptiongroup] = useState([]);
  const [selectedOptionroleIdentifier, setselectedOptionroleIdentifier] =
    useState([]);
  const [selectedIsDownloadOptiongroup, setSelectedIsDownloadOptiongroup] =
    useState([]);
  const [selectedsubModulesOptiongroup, setSelectedsubModulesOptiongroup] =
    useState([]);
  const [pvisibility, setpvisibility] = useState(false);
  const [cpvisibility, setcpvisibility] = useState(false);
  const rowHeight = 55;
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm({ mode: "onBlur" });
  const [isOpen, setIsOpen] = useState(false);
  const [showRoles, setShowRoles] = useState(false);

  const MoodRenderer = (props) => {
    return (
      <span className="profle_img_box">
        <span>{props.data}</span>
      </span>
    );
  };

  function somefun() {
    setAddButtonStatus(false);
    setRoleErrorStatus(false);
    setsubRoleErrorStatus(false);
    setIsEmailExist(false);
    setIsOpen(false);
    cancelForm();
  }

  function fun() {
    setAddButtonStatus(false);
    setRoleErrorStatus(false);
    setsubRoleErrorStatus(false);
    setIsEmailExist(false);
    setIsOpen(false);
    cross();
  }

  function cancelForm() {
    reset();
  }

  function cross() {
    reset();
  }

  const SrNoRenderer = (props) => {
    return <span>{props.node.rowIndex + 1}</span>;
  };

  const RolesRenderer = (props) => {
    return (
      <>
        {props.data?.type === "master admin" &&
          props.data?.roles?.length === 0 && (
            <span> Access of All Modules</span>
          )}
        {props.data?.roles?.length > 0 && (
          <span>
            {" "}
            {props.data?.roles
              .map((item) => {
                if (item.includes("_")) {
                  const newItem = item.split("_").join(" ");
                  return newItem;
                }
                return item;
              })
              .join(", ")}
          </span>
        )}
      </>
    );
  };

  const RoleIdentifierRenderer = (props) => {
    return (
      <>
        {props.data?.roleIdentifier === "master admin" &&
          props.data?.roleIdentifier?.length === 0 && (
            <span> Access of All Role Indentifier</span>
          )}
        {props.data?.roleIdentifier?.length > 0 && (
          <span> {props.data?.roleIdentifier.join(", ")}</span>
        )}
      </>
    );
  };

  const ChildMessageRenderer = (props) => {
    return (
      <div className="iconActionList">
        <div
          className="editIcon"
          onClick={() => {
            handleShow();
            doRefresh(0);
            setUpdateCategoriesData(props.data);
          }}
        >
          <MdModeEditOutline className="ActionIcon viewicon" />
        </div>

        <div
          className="ViewIcon"
          onClick={() => {
            setViewuser(props.data);
          }}
          data-bs-toggle="modal"
          data-bs-target="#UserViewId"
        >
          <AiFillEye alt="" src="../../images/view.jpg" />
        </div>
      </div>
    );
  };

  const editdataReloadFunc = () => {
    getSubadmins();
  };

  // ------Post API-------//
  const [roleErrorStatus, setRoleErrorStatus] = useState(null);
  const [subroleErrorStatus, setsubRoleErrorStatus] = useState(null);
  const [isDownloadErrorStatus, setIsDownloadErrorStatus] = useState(null);
  const [isEmailExist, setIsEmailExist] = useState(null);
  const [issecondaryEmailExist, setIssecondaryEmailExist] = useState(null);
  const [roleErrorMessage, setRoleErrorMessage] = useState(null);
  function addNewRole(postdata) {
    if (selectedOptiongroup.length === 0) {
      setAddButtonStatus(true);
      setRoleErrorStatus(true);
      return;
    }
    if (selectedsubModulesOptiongroup.length === 0) {
      setAddButtonStatus(true);
      setsubRoleErrorStatus(true);
      return;
    }
    if (selectedIsDownloadOptiongroup.length === 0) {
      setAddButtonStatus(true);
      setIsDownloadErrorStatus(true);
      return;
    }
    setRoleErrorStatus(false);
    setsubRoleErrorStatus(false);
    setIsDownloadErrorStatus(false);

    let data = JSON.stringify({
      username: postdata.name,
      email: postdata.email ? postdata.email : "",
      secondaryEmail: postdata.secondaryEmail ? postdata.secondaryEmail : "",
      mobile: "+65" + postdata.mobile,
      ipaddress: postdata.ipaddress,
      roles: selectedOptiongroup.join(","),
      roleIdentifier: selectedOptionroleIdentifier.join(","),
      subroles: selectedsubModulesOptiongroup.join(","),
      isDownload: selectedIsDownloadOptiongroup.join(","),
      password: postdata.businessconfirmPassword,
      isdownload: false,
    });

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/addadmin`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data.status) {
          reset();
          setIsEmailExist(false);
          setIssecondaryEmailExist(false);
          setIsOpen(false);
          getSubadmins();
        } else {
          setIsOpen(true);
          setIsEmailExist(true);
          setIssecondaryEmailExist(true);
          setRoleErrorMessage(response.data.message);
        }
      })
      .catch(function (error) {
        setAddButtonStatus(false);
        // console.log(error);
      });
    setAddButtonStatus(false);
  }

  useEffect(() => {
    getSubadmins();
  }, []);

  useEffect(() => {
    if (selectedOptiongroup.length > 0) {
      setRoleErrorStatus(false);
    }
  }, [selectedOptiongroup]);

  useEffect(() => {
    if (selectedsubModulesOptiongroup.length > 0) {
      setsubRoleErrorStatus(false);
    }
  }, [selectedsubModulesOptiongroup]);

  function getSubadmins() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getadmin`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        setRowData(response.data.adminData);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  // ------ Delete API -------//

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      sortingoder: "desc",
      sortable: true,
      resizable: true,
      filter: true,
      getQuickFilterText: (params) => {
        return params.value.name;
      },
      editable: true,
    };
  }, []);

  const StatusRenderer = (props) => {
    let status = props?.data?.status ? "deactivate" : "activate";
    let message = "Are you sure you want to " + status + " this user?";
    return (
      <Modal_Popup
        status={props?.data?.status}
        onClick={() => {
          changeStudentStatus(props?.data?._id);
        }}
        message={message}
        isDisabled={props.data.type === "master admin" ? true : false}
      />
    );
  };

  function changeStudentStatus(adminId) {
    let data = JSON.stringify({
      adminId: adminId,
    });

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/changestatus`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        getSubadmins();
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  const customLowerCaseComparator = (valueA, valueB) => {
    if (typeof valueA === "string") {
      return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
    }
    return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
  };

  const handlechange = (event) => {
    let array = [];
    event.forEach((option) => {
      array.push(option.value);
    });
    if (event.length > 0) {
      setAddButtonStatus(false);
    }
    setselectedOptiongroup(array);
  };

  const roleIdentifierHandleChange = (event) => {
    let array = [];
    event.forEach((option) => {
      array.push(option.value);
    });
    setselectedOptionroleIdentifier(array);
  };

  const subModulehandlechange = (event) => {
    let array = [];
    event.forEach((option) => {
      array.push(option.value);
    });
    if (event.length > 0) {
      setAddButtonStatus(false);
    }
    setSelectedsubModulesOptiongroup(array);
  };

  const isDownloadhandlechange = (event) => {
    let array = [];
    event.forEach((option) => {
      array.push(option.value);
    });
    if (event.length > 0) {
      setAddButtonStatus(false);
    }
    setSelectedIsDownloadOptiongroup(array);
  };

  const roles = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
    { value: "9", label: "9" },
  ];

  return (
    <>
      <div className="page-wrapper">
        <div className="container-fluid ">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">
                Role Management
                <div className="float-end btns_head">
                  <button
                    className="btn btn-theme btn-sm"
                    onClick={() => {
                      setIsOpen(true);
                      setAddButtonStatus(false);
                    }}
                  >
                    Add Role
                  </button>
                </div>
              </h4>

              <div>
                <Modal show={isOpen} onHide>
                  <Modal.Body>
                    <form onSubmit={handleSubmit(addNewRole)}>
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                              Assign Role
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                              onClick={() => fun()}
                            ></button>
                          </div>
                          <div className="modal-body">
                            <div className="row">
                              <div className="col-md-12 mb-3">
                                <label className="form-label">User Name</label>
                                <div className="position-relative">
                                  <input
                                    className="form-control"
                                    {...register("name", {
                                      required: true,
                                      pattern: /^[A-Za-z ]+$/i,
                                      min: 1,
                                    })}
                                  />
                                  {errors?.name?.type === "pattern" && (
                                    <p className="error">Alphabet only</p>
                                  )}
                                  {errors?.name?.type === "required" && (
                                    <p className="error">
                                      This field is required
                                    </p>
                                  )}
                                  {errors?.name?.type === "min" && (
                                    <p className="error">
                                      This field is required
                                    </p>
                                  )}
                                  <div
                                    className="hint_box"
                                    style={{ display: "block" }}
                                  ></div>
                                </div>
                              </div>
                              <div className="col-md-12 mb-3">
                                <label className="form-label">Email</label>
                                <div className="position-relative">
                                  <input
                                    className="form-control"
                                    {...register("email", {
                                      required: true,
                                      pattern:
                                        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    })}
                                  />
                                  {errors?.email?.type === "pattern" && (
                                    <p className="error">
                                      Invalid email address
                                    </p>
                                  )}
                                  {errors?.email?.type === "required" && (
                                    <p className="error">
                                      This field is required
                                    </p>
                                  )}
                                  {isEmailExist && (
                                    <p style={{ color: "red" }}>
                                      {roleErrorMessage}
                                    </p>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-12 mb-3">
                                <label className="form-label">
                                  Secondary Email
                                </label>
                                <div className="position-relative">
                                  <input
                                    className="form-control"
                                    {...register("secondaryEmail", {
                                      required: true,
                                      pattern:
                                        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    })}
                                  />
                                  {errors?.secondaryEmail?.type ===
                                    "pattern" && (
                                      <p className="error">
                                        Invalid Secondary Email address
                                      </p>
                                    )}
                                  {errors?.secondaryEmail?.type ===
                                    "required" && (
                                      <p className="error">
                                        This field is required
                                      </p>
                                    )}
                                  {issecondaryEmailExist && (
                                    <p style={{ color: "red" }}>
                                      {roleErrorMessage}
                                    </p>
                                  )}
                                </div>
                              </div>
                              {/* mobile Number */}
                              <div className="col-md-12 mb-3">
                                <label className="form-label">
                                  Mobile Number
                                </label>
                                <div className="position-relative">
                                  <input
                                    className="form-control"
                                    {...register("mobile", { required: true })}
                                  />
                                  {errors?.email?.type === "required" && (
                                    <p className="error">
                                      This field is required
                                    </p>
                                  )}
                                </div>
                              </div>
                              {/* ip address  */}
                              <div className="col-md-12 mb-3">
                                <label className="form-label">Ip Address</label>
                                <div className="position-relative">
                                  <input
                                    className="form-control"
                                    {...register("ipaddress", {
                                      required: true,
                                    })}
                                  />
                                  {errors?.email?.type === "required" && (
                                    <p className="error">
                                      This field is required
                                    </p>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-12 mb-3">
                                <label className="form-label">Roles</label>
                                <Select
                                  isMulti
                                  options={data}
                                  key={data}
                                  onChange={(e) => {
                                    // console.log(1);
                                    handlechange(e);
                                    if (e.length > 0) {
                                      e.some(
                                        (item) => item.value === "business"
                                      )
                                        ? setShowRoles(true)
                                        : setShowRoles(false);
                                    } else {
                                      setShowRoles(false);
                                    }
                                  }}
                                />
                                {roleErrorStatus && (
                                  <p className="error">
                                    This field is required
                                  </p>
                                )}
                              </div>
                              {showRoles && (
                                <div className="col-md-12 mb-3">
                                  <label className="form-label">
                                    Role Identifier
                                  </label>
                                  <Select
                                    isMulti
                                    options={roles}
                                    key={roles.map((item) => item)}
                                    onChange={(e) => {
                                      // console.log(e, "Roles Identifier");
                                      roleIdentifierHandleChange(e);
                                    }}
                                  />
                                </div>
                              )}

                              <div className="col-md-12 mb-3">
                                <label className="form-label">Sub Roles</label>
                                <Select
                                  isMulti
                                  defaultValue={[subModules[2]]}
                                  options={subModulesdata}
                                  key={subModulesdata}
                                  onChange={(e) => subModulehandlechange(e)}
                                />
                                {subroleErrorStatus && (
                                  <p className="error">
                                    This field is required
                                  </p>
                                )}
                              </div>

                              <div className="col-md-12 mb-3">
                                <label className="form-label">
                                  Downloaded Roles
                                </label>
                                <Select
                                  isMulti
                                  options={isDownloaddata}
                                  key={isDownloaddata}
                                  onChange={(e) => isDownloadhandlechange(e)}
                                />
                                {isDownloadErrorStatus && (
                                  <p className="error">
                                    This field is required
                                  </p>
                                )}
                              </div>
                              <div className="col-md-12 mb-4">
                                <label className="form-label">Password</label>
                                <div className="position-relative">
                                  <input
                                    className="form-control"
                                    type={pvisibility ? "text" : "password"}
                                    autoComplete="new-password"
                                    id="businessPassword"
                                    {...register("businessPassword", {
                                      required: "This field is required",
                                      minLength: {
                                        value: 6,
                                        message:
                                          "Please enter minimum 6 characters",
                                      },
                                    })}
                                  />
                                  {pvisibility ? (
                                    <div>
                                      <img
                                        alt="renderImages"
                                        style={{
                                          width: "23px",
                                          position: "absolute",
                                          top: "9px",
                                          right: "12px",
                                        }}
                                        src="images/eye.png"
                                        onClick={() => {
                                          setpvisibility(false);
                                        }}
                                      />
                                    </div>
                                  ) : (
                                    <div>
                                      <img
                                        alt="renderImages"
                                        style={{
                                          width: "23px",
                                          position: "absolute",
                                          top: "9px",
                                          right: "12px",
                                        }}
                                        src="images/invisible.png"
                                        onClick={() => {
                                          setpvisibility(true);
                                        }}
                                      />
                                    </div>
                                  )}
                                  {errors.businessPassword && (
                                    <p role="alert" className="error">
                                      {errors.businessPassword.message}
                                    </p>
                                  )}
                                  <div style={{ display: "block" }}></div>
                                </div>
                              </div>
                              <div className="col-md-12 mb-4">
                                <label className="form-label">
                                  Confirm Password
                                </label>
                                <div className="position-relative">
                                  <input
                                    className="form-control"
                                    type={cpvisibility ? "text" : "password"}
                                    name="businessconfirmpassword"
                                    autoComplete="new-password"
                                    {...register("businessconfirmPassword", {
                                      required: true,
                                      validate: (value) =>
                                        value === watch("businessPassword") ||
                                        "wrong",
                                    })}
                                  />
                                  {cpvisibility ? (
                                    <div>
                                      <img
                                        alt="renderImages"
                                        style={{
                                          width: "23px",
                                          position: "absolute",
                                          top: "9px",
                                          right: "12px",
                                        }}
                                        src="images/eye.png"
                                        onClick={() => {
                                          setcpvisibility(false);
                                        }}
                                      />
                                    </div>
                                  ) : (
                                    <div>
                                      <img
                                        alt="renderImages"
                                        style={{
                                          width: "23px",
                                          position: "absolute",
                                          top: "9px",
                                          right: "12px",
                                        }}
                                        src="images/invisible.png"
                                        onClick={() => {
                                          setcpvisibility(true);
                                        }}
                                      />
                                    </div>
                                  )}
                                  {errors?.businessconfirmPassword?.type ===
                                    "required" && (
                                      <p className="error">
                                        This field is required
                                      </p>
                                    )}
                                  {errors?.businessconfirmPassword?.type ===
                                    "validate" && (
                                      <p className="error">
                                        Password do not match
                                      </p>
                                    )}
                                  <div style={{ display: "block" }}></div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="modal-footer">
                            <button
                              onClick={() => somefun()}
                              type="button"
                              className="btn btn-danger CancelBtn"
                              data-bs-dismiss="modal"
                            >
                              Cancel
                            </button>
                            <button
                              disabled={addButtonStatus}
                              type="submit"
                              className="btn submitBtn"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                    {/* </div> */}
                  </Modal.Body>
                </Modal>
              </div>

              <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Edit Admin Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <UpdateUserRoleManagement
                    refresh={refresh}
                    handleClose={handleClose}
                    updatedData={UpdateStudentData}
                    onEditDataFunction={editdataReloadFunc}
                  />
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    className="btn btn-danger CancelBtn"
                    onClick={handleClose}
                  >
                    Close
                  </Button>
                  <Button
                    className="btn submitBtn"
                    onClick={() => {
                      doRefresh((prev) => prev + 1);
                    }}
                  >
                    Save Changes
                  </Button>
                </Modal.Footer>
              </Modal>

              <div
                className="modal fade"
                id="UserViewId"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-scrollable modal-lg">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">
                        Admin Details{" "}
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-12">
                          <div className="card">
                            <div className="card-body border">
                              <div className="profile_box">
                                <div className="profile_box_body">
                                  <div>
                                    <h6> Name : {Viewuser.username}</h6>
                                  </div>
                                  <div>
                                    <h6> Email : {Viewuser.email}</h6>
                                  </div>
                                  <div>
                                    {Viewuser?.type === "master admin" &&
                                      Viewuser?.roles?.length === 0 && (
                                        <h6> Roles : Access of All Modules</h6>
                                      )}
                                    {Viewuser?.roles?.length > 0 && (
                                      <h6>
                                        {" "}
                                        Roles :{" "}
                                        {Viewuser?.roles
                                          .map((item) => {
                                            if (item.includes("_")) {
                                              const newItem = item
                                                .split("_")
                                                .join(" ");
                                              return newItem;
                                            }
                                            return item;
                                          })
                                          .join(", ")}
                                      </h6>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-danger CancelBtn"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div
                style={{ width: "100%" }}
                className="ag-theme-alpine tableFix"
              >
                <AgGridReact
                  rowHeight={rowHeight}
                  rowData={rowData}
                  defaultColDef={defaultColDef}
                  frameworkComponents={{
                    childMessageRenderer: ChildMessageRenderer,
                    moodRenderer: MoodRenderer,
                    srNoRenderer: SrNoRenderer,
                    statusRenderer: StatusRenderer,
                    rolesRenderer: RolesRenderer,
                    roleIdentifierRenderer: RoleIdentifierRenderer,
                  }}
                >
                  <AgGridColumn
                    width={90}
                    field="SrNo"
                    //Srno={true}
                    sortable={false}
                    filter={false}
                    cellRenderer="srNoRenderer"
                  ></AgGridColumn>
                  <AgGridColumn
                    width={100}
                    field="Action"
                    cellRenderer="childMessageRenderer"
                    colId="params"
                    sortable={false}
                    filter={false}
                  ></AgGridColumn>
                  <AgGridColumn
                    width={200}
                    field="username"
                    sortable={true}
                    comparator={customLowerCaseComparator}
                    filter={true}
                  ></AgGridColumn>
                  <AgGridColumn
                    width={250}
                    field="email"
                    sortable={true}
                    comparator={customLowerCaseComparator}
                    filter={true}
                  ></AgGridColumn>
                  <AgGridColumn
                    width={200}
                    field="roles"
                    cellRenderer="rolesRenderer"
                    sortable={true}
                    // comparator={customLowerCaseComparator}
                    filter={true}
                  ></AgGridColumn>
                  <AgGridColumn
                    width={100}
                    headerName="Role Identifier"
                    field="roleIdentifier"
                    cellRenderer="roleIdentifierRenderer"
                    sortable={true}
                  ></AgGridColumn>
                  <AgGridColumn
                    cellRenderer="statusRenderer"
                    field="status"
                    sortable={true}
                  ></AgGridColumn>
                </AgGridReact>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserRoleManagement;
